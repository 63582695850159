import {adminApi} from 'api';
import {QuestsPointOptionsGoals} from '@apiSchema/admin-api';
import {yupBooleanRequired, yupObject, yupString} from 'utils/validation';
import {FieldStructure} from 'components/Form/types';

export const initialValues: QuestsPointOptionsGoals = {
  completeRequired: true,
  goalTypeId: undefined,
};

const useGoalTypeIdOptions = () => {
  const {data} = adminApi.endpoints.goalsTypesIndex.useQuery({limit: 10000});
  return (data?.data || []).filter(item => !!item.enabled).map(item => ({value: item._id, name: item.name}));
};

export const fields: FieldStructure[] = [
  {
    type: 'select',
    name: 'goalTypeId',
    label: 'Категория',
    options: useGoalTypeIdOptions,
  },
  {
    type: 'checkbox',
    name: 'completeRequired',
    label: 'Нужно закрыть цель для выполнения шага',
  },
];

export const validation = yupObject({
  goalTypeId: yupString,
  completeRequired: yupBooleanRequired,
});
