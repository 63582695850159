/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback} from 'react';
import {Dialog, DialogContent, DialogTitle} from '@mui/material';
import {BooleanParam, StringParam, useQueryParams} from 'use-query-params';

import {Form} from 'components/Form';
import {Groups} from '@apiSchema/admin-api';

import {fields, validation} from './fields';
import {useGroupCreateUpdate} from './hook';

const queryShape = {createGroup: BooleanParam, editGroupId: StringParam};

export type GroupFormProps = {
  groupId?: string;
  onReset?: () => void;
  onCreate?: (data: Groups) => void;
};

type GroupFormDialogProps = Pick<GroupFormProps, 'onCreate'>;

export const GroupForm = ({onReset, groupId, onCreate}: GroupFormProps) => {
  const {onSubmit, initials, isFetching} = useGroupCreateUpdate(groupId, onCreate);
  if (isFetching) return null;
  return <Form fields={fields} initials={initials} onReset={onReset} onSubmit={onSubmit} validation={validation} />;
};

const useGroupForm = (): [(props: GroupFormDialogProps) => JSX.Element, () => void, (groupId: string) => void] => {
  const [{createGroup, editGroupId}, setQuery] = useQueryParams(queryShape);
  const open = useCallback(() => setQuery({createGroup: true}, 'replace'), []);
  const edit = useCallback((groupId: string) => setQuery({editGroupId: groupId}, 'replace'), []);
  const close = useCallback(() => setQuery({}, 'replace'), []);

  const FormContainer = useCallback(
    (props: GroupFormDialogProps) => (
      <Dialog open={Boolean(createGroup) || Boolean(editGroupId)} maxWidth="sm" fullWidth onBackdropClick={close}>
        <DialogTitle>Добавить группу</DialogTitle>
        <DialogContent dividers>
          <GroupForm groupId={editGroupId as string | undefined} onReset={close} {...props} />
        </DialogContent>
      </Dialog>
    ),
    [createGroup, editGroupId, close]
  );

  return [FormContainer, open, edit];
};

export default useGroupForm;
