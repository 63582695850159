/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback} from 'react';
import {BooleanParam, StringParam, useQueryParams} from 'use-query-params';

import {Dialog, DialogContent, DialogTitle} from '@mui/material';

import {Form} from 'components/Form';

import {fields, validation} from './fields';
import {useUserLogin} from './hook';

const queryShape = {createUserLogin: BooleanParam, userLoginId: StringParam};

export const UserLoginForm = ({
  onReset,
  userId,
  userLoginId,
}: {
  userId: string;
  userLoginId?: string;
  onReset?: () => void;
}) => {
  const {onSubmit, initials, isFetching} = useUserLogin(userId, userLoginId);
  if (isFetching) return null;
  return (
    <Form
      fields={fields}
      initials={initials}
      validation={validation}
      onReset={onReset}
      onSubmit={onSubmit}
      resetAfterSubmit
    />
  );
};

const useUserLoginForm = (): [(props: {userId: string}) => JSX.Element, () => void, (loginId: string) => void] => {
  const [{userLoginId, createUserLogin}, setQuery] = useQueryParams(queryShape);
  const open = useCallback(() => setQuery({createUserLogin: true}, 'replace'), []);
  const edit = useCallback((loginId: string) => setQuery({userLoginId: loginId}, 'replace'), []);
  const close = useCallback(() => setQuery({}, 'replace'), []);

  const FormContainer = useCallback(
    ({userId}: {userId: string}) => (
      <Dialog open={Boolean(userLoginId) || Boolean(createUserLogin)} onClose={close} maxWidth="sm" fullWidth>
        <DialogTitle>Добавить логин</DialogTitle>
        <DialogContent dividers>
          <UserLoginForm userId={userId} userLoginId={userLoginId as string | undefined} onReset={close} />
        </DialogContent>
      </Dialog>
    ),
    [userLoginId, createUserLogin, close]
  );

  return [FormContainer, open, edit];
};

export default useUserLoginForm;
