import {useParams} from 'react-router';
import Stack from '@mui/material/Stack';
import {skipToken} from '@reduxjs/toolkit/query';

import {adminApi} from 'api';
import {useIsMobile} from 'utils';
import {Publications} from '@apiSchema/users-api';

import {Comments} from 'modules/comments';
import {ScrollToAnchor} from 'components/ScrollToAnchor';
import {IsExistsContianer} from 'components/IsExistsContainer';

import {PublicationBody, PublicationStageInfo} from '../containers/Publication';

const useGetPublication = () => {
  const {postId} = useParams<'postId'>();
  return adminApi.usePublicationIndexQuery(postId ? {postId} : skipToken);
};

export const PublicationPage = () => {
  const isMobile = useIsMobile();
  const {postId} = useParams<'postId'>();
  return (
    <IsExistsContianer<Publications> useFetch={useGetPublication} key={postId}>
      {post => (
        <div id="publication">
          <Stack spacing={2}>
            {isMobile && <ScrollToAnchor />}
            <PublicationBody postId={post._id!} />
            <PublicationStageInfo postId={post._id!} />
            <Comments referenceName="Publications" referenceAuthorId={post.userId!} referenceId={post._id!} />
          </Stack>
        </div>
      )}
    </IsExistsContianer>
  );
};
