import {lazy} from 'react';
import {Navigate, RouteObject} from 'react-router';

import {PATH_APP} from 'router/routes';
import {AccessGuard} from 'modules/auth/containers';
import useGetReward from 'modules/tasks/pages/RewardForm/hook';
import useGetReactionsBlock from 'modules/settings/pages/ReactionForm/hook';

import {BreadcrumbsProvider} from 'components/Breadcrumbs';
import IsExistsContainer from 'components/IsExistsContainer';
import {BreadcrumbsSet} from 'components/Breadcrumbs/BreadcrumbsSet';
import {useGetGoalType} from './pages/GoalType/hook';
import {GoalsTypes, ReactionsBlocks, Rewards} from '@apiSchema/admin-api';

const RewardFormPage = lazy(() => import('modules/tasks/pages/RewardForm'));
const RewardsListPage = lazy(() => import('modules/tasks/pages/RewardsList'));
const TaskCategoryList = lazy(() => import('modules/tasks/pages/CategoryList'));

const ReactionsList = lazy(() => import('modules/settings/pages/ReactionsList'));
const ReactionForm = lazy(() => import('modules/settings/pages/ReactionForm'));

const TagListPage = lazy(() => import('./pages/TagList'));
const GoalTypePage = lazy(() => import('./pages/GoalType'));
const GoalTypesListPage = lazy(() => import('./pages/GoalTypesList'));

export const routes: RouteObject = {
  path: 'catalogs',
  element: <BreadcrumbsProvider name="Справочники" href={PATH_APP.catalogs.root} />,
  children: [
    {index: true, element: <Navigate to="./tags" replace />},
    {
      path: PATH_APP.catalogs.goalTypes,
      element: <BreadcrumbsSet name="Категории целей" href={PATH_APP.catalogs.goalTypes} />,
      children: [
        {index: true, element: <GoalTypesListPage />},
        {
          path: ':goalTypeId',
          element: (
            <IsExistsContainer<GoalsTypes> useFetch={useGetGoalType}>
              {data => (
                <BreadcrumbsSet name={`${data?.name}`}>
                  <GoalTypePage />
                </BreadcrumbsSet>
              )}
            </IsExistsContainer>
          ),
        },
      ],
    },
    {
      path: PATH_APP.catalogs.taskCategories,
      element: (
        <AccessGuard endpoint="tasksCategoriesIndex">
          <BreadcrumbsSet name="Категории заданий" href={PATH_APP.catalogs.taskCategories} />
        </AccessGuard>
      ),
      children: [
        {
          index: true,
          element: <TaskCategoryList />,
        },
      ],
    },
    {
      path: PATH_APP.catalogs.reactions,
      element: (
        <AccessGuard endpoint="reactionsBlocksIndex">
          <BreadcrumbsSet name="Блоки реакций" href={PATH_APP.catalogs.reactions} />
        </AccessGuard>
      ),
      children: [
        {index: true, element: <ReactionsList />},
        {
          path: ':blockId',
          element: (
            <AccessGuard endpoint="reactionsBlockIndex">
              <IsExistsContainer<ReactionsBlocks> useFetch={useGetReactionsBlock}>
                {data => <BreadcrumbsSet name={`${data?.name}`} />}
              </IsExistsContainer>
            </AccessGuard>
          ),
          children: [{index: true, element: <ReactionForm />}],
        },
      ],
    },
    {
      path: PATH_APP.catalogs.rewards,
      element: (
        <AccessGuard endpoint="rewardsIndex">
          <BreadcrumbsSet name="Награды" href={PATH_APP.catalogs.rewards} />
        </AccessGuard>
      ),
      children: [
        {index: true, element: <RewardsListPage />},
        {
          path: ':rewardId',
          element: (
            <AccessGuard endpoint="rewardIndex">
              <IsExistsContainer<Rewards> useFetch={useGetReward}>
                {data => <BreadcrumbsSet name={`${data?.name}`} />}
              </IsExistsContainer>
            </AccessGuard>
          ),
          children: [{index: true, element: <RewardFormPage />}],
        },
      ],
    },
    // {
    //   path: PATH_APP.catalogs.contentCategories,
    //   element: (
    //     <AccessGuard endpoint="contentsCategoriesIndex">
    //       <BreadcrumbsSet name="Категории материалов" href={PATH_APP.catalogs.contentCategories}>
    //         <ContentsCategoryListPage />
    //       </BreadcrumbsSet>
    //     </AccessGuard>
    //   ),
    // },
    {
      path: PATH_APP.catalogs.tags,
      element: (
        <AccessGuard endpoint="tagsIndex">
          <BreadcrumbsSet name="Теги" href={PATH_APP.catalogs.tags}>
            <TagListPage />
          </BreadcrumbsSet>
        </AccessGuard>
      ),
    },
  ],
};

export default routes;
