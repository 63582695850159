import {QuestDesign} from '@apiSchema/admin-api';
import {yupObject, yupString} from 'utils/validation';
import {FieldStructure} from 'components/Form/types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const designFields = (questId?: string): FieldStructure[] => [
  {
    type: 'text',
    name: 'header',
    label: 'Заголовок',
  },
  {
    type: 'text',
    name: 'notionPageId',
    label: 'ID страницы Notion с материалом',
  },
  {
    direction: 'row',
    fields: [
      {
        type: 'text',
        name: 'estimatedText',
        label: 'Примерное время выполнения',
      },
      {
        type: 'text',
        name: 'hint',
        label: 'Текст в итоговой публикации',
      },
    ],
  },
  {
    direction: 'row',
    fields: [
      {
        type: 'text',
        variant: 'text',
        name: 'completedText',
        label: 'Текст на экране выполненного квеста',
        minRows: 2,
      },
      {
        type: 'text',
        variant: 'text',
        name: 'failedText',
        label: 'Текст на экране проваленного квеста',
        minRows: 2,
      },
    ],
  },
  {
    type: 'file',
    variant: 'image',
    name: 'fileId',
    label: 'Картинка',
    multiple: false,
    imageOptions: {
      ratio: '1:1',
    },
  },
];

export const designInitials: QuestDesign = {
  hint: undefined,
  header: undefined,
  rejectTitle: undefined,
  acceptTitle: undefined,
  backgroundColor: undefined,
  fileId: undefined,
  backgroundFileId: undefined,
  successImageFileId: undefined,
  notionPageId: undefined,
  completedText: undefined,
  failedText: undefined,
  estimatedText: undefined,
  deadlineText: undefined,
};

export const designFormValidation = yupObject({
  header: yupString,
  hint: yupString,
  rejectTitle: yupString,
  acceptTitle: yupString,
  confirmTitle: yupString,
  fileId: yupString,
  backgroundFileId: yupString,
  successImageFileId: yupString,
  backgroundColor: yupString,
  deadlineText: yupString,
  estimatedText: yupString,
  failedText: yupString,
  completedText: yupString,
  notionPageId: yupString,
});
