import {ComponentProps, useEffect} from 'react';
import {useFormContext} from 'react-hook-form';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {ErrorMessage} from '@hookform/error-message';

import {FieldProps} from '../types';
import {Checkbox} from '../Checkbox';
import {TextField} from '../TextField';
import {FileUpload} from '../FileUpload';
import {SelectField} from '../SelectField';
import {RenderField} from '../RenderField';
import {LexicalField} from '../LexicalField';
import {DialogSelect} from '../DialogSelect';
import {MarkdownField} from '../MarkdownField';
import {DateTimeSelect} from '../DateTimeSelect';

export const Field: React.VFC<FieldProps> = ({type, ...props}) => {
  const {clearErrors} = useFormContext();
  useEffect(() => () => clearErrors(props.name), [clearErrors, props.name]);

  let Content: JSX.Element | null = null;
  if (type === 'text') Content = <TextField {...(props as ComponentProps<typeof TextField>)} />;
  else if (type === 'lexical') Content = <LexicalField {...(props as ComponentProps<typeof LexicalField>)} />;
  else if (type === 'markdown') Content = <MarkdownField {...(props as ComponentProps<typeof MarkdownField>)} />;
  else if (type === 'file') Content = <FileUpload {...(props as ComponentProps<typeof FileUpload>)} />;
  else if (type === 'checkbox') Content = <Checkbox {...(props as ComponentProps<typeof Checkbox>)} />;
  else if (type === 'select') Content = <SelectField {...(props as ComponentProps<typeof SelectField>)} />;
  else if (type === 'dateTime') Content = <DateTimeSelect {...(props as ComponentProps<typeof DateTimeSelect>)} />;
  else if (type === 'dialogSelect') Content = <DialogSelect {...(props as ComponentProps<typeof DialogSelect>)} />;
  else if (type === 'render') Content = <RenderField {...(props as ComponentProps<typeof RenderField>)} />;
  else if (!Content) return Content;

  return (
    <Stack spacing={1} width="100%">
      {Content}
      {props.hint && (
        <Typography variant="body2" color="text.secondary">
          {props.hint}
        </Typography>
      )}
      <ErrorMessage
        name={props.name}
        render={({message}) => (
          <Typography variant="subtitle1" color="error">
            {message}
          </Typography>
        )}
      />
    </Stack>
  );
};
