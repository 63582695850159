import {memo} from 'react';
import concat from 'lodash/concat';
import without from 'lodash/without';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import {Templates} from '@apiSchema/users-api';
import {AnswerProps} from '../types';
import {useMultiFieldProps} from './hooks';

export const CheckboxGroupField = ({
  scriptActionId,
  template,
  index,
}: Omit<AnswerProps, 'templateOptionId'> & {template: Templates}) => {
  const {options, value, field, setData} = useMultiFieldProps(scriptActionId, `${template._id}`, index);

  return (
    <Stack>
      {options.map(option => {
        const optionId = `${option._id}`;
        const checked = value.includes(optionId);
        const onChange = () => setData(checked ? without(value, optionId) : concat(value, [optionId]));

        return (
          <FormControlLabel
            key={optionId}
            label={option.name}
            inputRef={field.ref}
            labelPlacement="end"
            control={
              <Checkbox
                size="small"
                value={optionId}
                checked={checked}
                onChange={onChange}
                name={`${field.name}.${optionId}`}
              />
            }
          />
        );
      })}
    </Stack>
  );
};

export default memo(CheckboxGroupField);
