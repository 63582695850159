import {Outlet} from 'react-router';
import {PropsWithChildren} from 'react';
import EditIcon from '@mui/icons-material/EditOutlined';

import LoadingScreen from '__theme/components/LoadingScreen';

import {useGetUser} from 'modules/users/pages/UserCardInfo/hook';
import {CommonPage} from 'components/ListPage';
import {CardContent, Card, Grid, Stack, IconButton} from '@mui/material';

import {UserItem} from 'components/UserItem';

import {useUsersForm} from '../UsersForm';
import {UserInfoWidget} from '../UserInfo';
import {UserMainAccess} from '../UserPermissionSettings';
import {UserGroups} from './UserGroups';
import {UserStreams} from './UserStreams';

export const UserCardLayout = ({children}: PropsWithChildren<{}>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [UsersForm, openCreateUser, editUser] = useUsersForm();
  const {isLoading, isUninitialized, data} = useGetUser();

  if (!data) return null;
  if (isUninitialized && isLoading) return <LoadingScreen />;

  const user = data.account;
  const userId = data.account._id!;
  const heading = `${user.name || ''} ${user.surname || ''}`;
  return (
    <>
      <UsersForm />
      <CommonPage title="Пользователи" heading={heading} maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item md={4} lg={3} xs={12} sm={12}>
            <Stack spacing={2} component={Stack} direction="column">
              <Card>
                <CardContent>
                  <UserItem
                    user={data.account}
                    Action={
                      <IconButton size="small" onClick={() => editUser(userId)}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                    }
                  />
                </CardContent>
              </Card>
              <Card>
                <CardContent sx={{px: 0}}>
                  <UserInfoWidget userId={userId} emptyText="Нет данных о прогрессе" />
                </CardContent>
              </Card>
              <UserStreams userId={userId} />
              <UserGroups userId={userId} />
              <UserMainAccess userId={userId} />
            </Stack>
          </Grid>
          <Grid item md={8} lg={9} xs={12} sm={12}>
            {children ? <>{children}</> : <Outlet />}
          </Grid>
        </Grid>
      </CommonPage>
    </>
  );
};
