import {GroupsUsers} from '@apiSchema/admin-api';
import {enchanceColumns} from 'utils';
import {renderUserAvatar} from './renderUserAvatar';

export const groupMembersColumns = enchanceColumns<GroupsUsers>(
  [
    {
      field: 'userId__fileId',
      headerName: ' ',
      valueGetter: ({row}) => row.user.fileId,
      renderCell: renderUserAvatar('small'),
      filterable: false,
      sortable: false,
      width: 32,
    },
    {
      field: 'userId__name',
      headerName: 'Имя',
      valueGetter: ({row}) => `${row.user.name || ''} ${row.user.surname || ''}`,
      flex: 2,
    },
  ],
  {hideDates: true, hideEnabled: true}
);
