import {useSpring, animated} from 'react-spring';
import {adminApi} from 'api';
import {alpha, styled} from '@mui/material/styles';
import {TreeView, TreeItem, TreeItemProps, treeItemClasses} from '@mui/lab';
import {TransitionProps} from '@mui/material/transitions';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import {Access, AccessPoint} from '@apiSchema/admin-api';
// import {Access, AccessPoint, RouteElement} from '@apiSchema/admin-api';

import Collapse from '@mui/material/Collapse';
import CheckBoxOutlined from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';

const TreeViewStyle = styled(TreeView)({
  height: 240,
  flexGrow: 1,
  maxWidth: 400,
});

function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: 'translate3d(20px,0,0)',
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });
  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = styled((props: TreeItemProps) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({theme}) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

const PermissionNode = ({
  node,
  depth,
  onClick,
}: {
  node: Record<string, any[]>;
  depth: number;
  onClick: (point: AccessPoint) => void;
}) => {
  return (
    <>
      {Object.keys(node).map(key => {
        const item = node[key];
        const accessPointValue = (item[0].AccessAdminControl || [])[depth - 1];
        const root = groupBy(
          item.filter(item => item.AccessAdminControl && item.AccessAdminControl.length > depth),
          item => {
            if (!item.AccessAdminControl) return null;
            return JSON.stringify(item.AccessAdminControl[depth]);
          }
        );
        const defaultItems = item.filter(item => item.AccessAdminControl && !item.AccessAdminControl[depth + 1]);

        return (
          <StyledTreeItem
            key={key}
            nodeId={JSON.stringify(accessPointValue)}
            label={Object.values(accessPointValue).join(', ')}
            onClick={() => onClick(accessPointValue)}
          >
            {defaultItems.map(i => (
              <StyledTreeItem
                disabled
                key={i.operationId}
                nodeId={i.operationId}
                label={i.operationId}
                icon={<CheckBoxOutlined />}
              />
            ))}
            {/* {controlledItems.map(i => {
              const point = i.AccessAdminControl && i.AccessAdminControl[depth + 1];
              if (!point) return null;
              return (
                <StyledTreeItem
                  key={i.operationId}
                  nodeId={JSON.stringify(point)}
                  label={i.operationId}
                  // icon={<CheckBoxOutlineBlank />}
                  // check
                  onClick={() => onClick(point)}
                />
              );
            })} */}
            {!isEmpty(root) && <PermissionNode node={root} depth={depth + 1} onClick={onClick} />}
          </StyledTreeItem>
        );
      })}
    </>
  );
};

export const Permissions = ({access}: {access: Access}) => {
  const [patch] = adminApi.endpoints.accessElemPatch.useMutation();
  const {root} = adminApi.endpoints.rootIndex.useQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      root: groupBy(
        ((result.data || []) as any[]).filter(item => item.AccessAdminControl && item.AccessAdminControl.length),
        item => {
          if (!item.AccessAdminControl) return null;
          return JSON.stringify(item.AccessAdminControl[0]);
        }
      ),
    }),
  });

  const patchPermissions = (point: AccessPoint) => {
    const newPoints = [...(access.points || [])];
    if (findIndex(newPoints, point) > -1) newPoints.splice(findIndex(newPoints, point), 1);
    else newPoints.push(point);
    return patch({accessId: `${access._id}`, accessPartialDto: {points: newPoints}});
  };

  return (
    <TreeViewStyle
      expanded={(access.points || []).map(item => JSON.stringify(item))}
      defaultExpandIcon={<CheckBoxOutlineBlank />}
      defaultCollapseIcon={<CheckBoxOutlined />}
    >
      <PermissionNode node={root} depth={1} onClick={patchPermissions} />
    </TreeViewStyle>
  );
};
