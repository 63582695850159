import {unflatten} from 'flat';
import {keyBy, max, range} from 'lodash';
import {FC, useContext, useMemo} from 'react';
import {shallowEqual, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import {usersApi} from 'api';

import {WidgetValue} from '../types';
import {WidgetQueryContext} from '../context';
import {selectWidgetValues} from '../selectors';
import {WidgetVisualComponentGroupItem} from './WidgetVisualComponentGroupItem';

export const WidgetVisualComponentGroup: FC<{visualId: string; containerName: string; designId: string[]}> = ({
  designId,
  ...props
}) => {
  const {data: design} = usersApi.endpoints.visualDesign.useQueryState(props, {
    selectFromResult: ({data}) => ({data: data?.filter(item => designId.includes(item._id!))}),
  });

  const designByAttrId = keyBy(design || [], 'widgetAttrId');
  const widgetQuery = useContext(WidgetQueryContext);
  const rawValues = useSelector<any>(state => {
    const values = design?.map(item => selectWidgetValues(state, widgetQuery, item.widgetId, item.widgetAttrId));
    return JSON.stringify(values);
  }, shallowEqual);

  const values = useMemo(() => {
    const unflattenValues = (JSON.parse(rawValues as string) || [])
      ?.map((value: any) => {
        const unflattenValue = unflatten(value);
        return unflattenValue ? (Object.values(unflattenValue as any) as WidgetValue[]) : undefined;
      })
      .filter(Boolean) as WidgetValue[][];
    if (!unflattenValues) return [];

    const maxKeys = max(unflattenValues.map(item => item.length));
    return range(0, maxKeys).map(index => unflattenValues.map(item => item[index]));
  }, [rawValues]);

  return (
    <Stack spacing={2}>
      {values.map((item, index) => (
        <Stack key={index} direction="row" spacing={1}>
          <Box height={20} bgcolor="black">
            {/* <BulletIcon width={6} height={6} /> */}
          </Box>
          <Stack spacing={1}>
            {item.map(value => {
              if (!designByAttrId[value.widgetAttrId]) return null;
              const elementType = designByAttrId[value.widgetAttrId].options.elementType;
              const elementOptions = designByAttrId[value.widgetAttrId].options[
                `${elementType}Options` as const
              ] as any;
              return (
                <WidgetVisualComponentGroupItem
                  value={value}
                  options={elementOptions}
                  elementType={elementType}
                  key={JSON.stringify(value)}
                  defaultValue={designByAttrId[value.widgetAttrId].options.value}
                  containerOptions={designByAttrId[value.widgetAttrId].options.containerOptions}
                />
              );
            })}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};
