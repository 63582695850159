import {adminApi} from 'api';
import {useAppDispatch} from 'store';
import {useCallback, useState} from 'react';

const sendInvite = adminApi.endpoints.userLoginInvite.initiate;

export const useRemoveLogin = (userId: string) => {
  const dispatch = useAppDispatch();
  const [selectedIds, setSelectedIds] = useState<(string | number)[]>([]);
  const [remove] = adminApi.useUserLoginDeleteMutation();
  const onRemove = useCallback(() => {
    if (selectedIds.length === 1) remove({userId, loginId: selectedIds[0] as string});
  }, [selectedIds, remove, userId]);
  const onInviteSend = (loginId: string) =>
    dispatch(sendInvite({userId, loginId, userInviteDto: {message: 'На ссылку!'}}));

  return {btnDisabled: selectedIds.length !== 1, setSelectedIds, onRemove, onInviteSend};
};
