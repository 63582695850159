import {pick} from 'lodash';

import {ReceiptsServices} from '@apiSchema/admin-api';
import {
  yupBooleanRequired,
  yupObject,
  yupString,
  yupStringJSON,
  yupStringOfOptionsRequired,
  yupStringRequired,
} from 'utils/validation';

import {receiptsServicesTypes} from 'modules/payments/utils';
import {FieldStructure} from 'components/Form/types';

export const fields: FieldStructure[] = [
  {
    fields: [
      {
        type: 'text',
        name: 'name',
        label: 'Название',
      },
      {
        type: 'select',
        name: 'type',
        label: 'Тип',
        options: receiptsServicesTypes,
      },
    ],
  },
  {
    type: 'text',
    name: 'description',
    label: 'Описание',
    variant: 'richText',
  },
  {
    type: 'text',
    name: 'settings',
    label: 'Настройки (JSON строка)',
    variant: 'text',
  },
  {
    type: 'checkbox',
    name: 'enabled',
    label: 'Активный',
  },
];

export const validation = yupObject({
  name: yupStringRequired,
  description: yupString,
  type: yupStringOfOptionsRequired(receiptsServicesTypes),
  settings: yupStringJSON,
  enabled: yupBooleanRequired,
});

const defaultValues: ReceiptsServices = {
  name: '',
  description: '',
  type: 'cloud_kassir',
  settings: '',
  enabled: true,
};

export const initials = (data?: ReceiptsServices) => ({
  ...defaultValues,
  ...pick(data, Object.keys(defaultValues)),
});
