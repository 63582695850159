import get from 'lodash/fp/get';
import {useSnackbar} from 'notistack';

import {useAtom} from 'jotai';
import {useAtomValue} from 'jotai/utils';

import {useEffect, useState} from 'react';
import {DropzoneProps} from 'react-dropzone';
import {skipToken} from '@reduxjs/toolkit/query';
import {useController, useFormContext} from 'react-hook-form';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {filesApi} from 'api';
import {Files} from '@apiSchema/files-api';

import {AnswerProps} from '../../types';
import {scriptActionExactValue, getOptionsAtonm} from '../../atoms';

import {FileDropzone} from './FileDropzone';

export const FileInput = (params: AnswerProps) => {
  const [defaultValue, setData] = useAtom(scriptActionExactValue(params));
  const [option] = useAtomValue(getOptionsAtonm(params.templateOptionId));
  const [uploadFile, {isLoading, isError}] = filesApi.endpoints.rootUpload.useMutation();
  const {data: fileData} = filesApi.endpoints.fileInfo.useQuery(defaultValue ? {fileId: defaultValue} : skipToken);
  const {enqueueSnackbar} = useSnackbar();
  const {control, setValue} = useFormContext();
  const [file, setFile] = useState<Files | null>(null);

  const name = `${params.scriptActionId}.${params.index}.${params.templateOptionId}`;

  const dropHandler: DropzoneProps['onDrop'] = async files => {
    if (!files.length) return;

    const body = new FormData();
    files.forEach(file => body.append('file', file));
    const result = await uploadFile({body: body as any});
    if ('data' in result && result.data[0]) {
      setValue(name, get('_id', result.data[0]) as string);
      setData(get('_id', result.data[0]) as string);
      setFile(result.data[0]);
    } else if ('error' in result) {
      enqueueSnackbar((result.error as any).data.message || 'Не удалось загрузить файл', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };
  const {
    field: {ref, ...field},
  } = useController({name, control, defaultValue});

  useEffect(() => {
    if (defaultValue && fileData && !file) {
      setValue(name, defaultValue as string);
      setFile(fileData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, fileData]);

  const removeFile = () => {
    setValue(name, '');
    setFile(null);
    setData('');
  };

  return (
    <Stack>
      {option.name && (
        <Typography variant="body2" sx={{color: 'grey.200', letterSpacing: '0.25px', lineHeight: '18px', mb: 0.5}}>
          {option.name}
        </Typography>
      )}
      <input {...field} type="text" style={{display: 'none'}} />
      <FileDropzone onDrop={dropHandler} onRemove={removeFile} meta={{isLoading, isError}} file={file} />
    </Stack>
  );
};
