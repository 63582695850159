import {QuestsPoints} from '@apiSchema/admin-api';

import {Form} from 'components/Form';
import {contentColumns} from 'modules/contents/containers';
import {yupBoolean, yupObject, yupStringRequired} from 'utils/validation';
import {usePatchQuestPoint} from 'modules/events/hooks';
import {getContentPath} from 'modules/contents/utils';

export const EventContentSelector = ({point}: {point: QuestsPoints}) => {
  const {patchPoint} = usePatchQuestPoint({pointId: point._id!});

  return (
    <Form
      initials={{
        autoAccept: point.options?.autoAccept || false,
        contents: {
          contentId: point.options?.contents?.contentId || '',
        },
      }}
      validation={yupObject({
        autoAccept: yupBoolean,
        contents: yupObject({
          contentId: yupStringRequired,
        }),
      })}
      onSubmit={patchPoint}
      fields={[
        {
          type: 'dialogSelect',
          displayVariant: 'card',
          label: 'Выбрать материал',
          name: 'contents.contentId',
          getName: item => item.name,
          getPath: getContentPath,
          queryName: 'contentsIndex',
          multiple: false,
          params: {limit: 10000},
          columns: contentColumns(),
        },
        {
          type: 'checkbox',
          name: 'autoAccept',
          label: 'Принимать атвоматически по доступности',
        },
      ]}
    />
  );
};
