import _ from 'lodash';
import {useSelector} from 'react-redux';
import {createSelector} from '@reduxjs/toolkit';

import {WidgetInfoApiArg, WidgetsAttrs, WidgetsAttrsValues} from '@apiSchema/users-api';
import {usersApi} from 'api';

export const NUMERIC_TYPES: WidgetsAttrsValues['type'][] = ['number', 'score'];

const selectWidgetValues = createSelector(
  (state: any) => state,
  (_: any, args: string) => JSON.parse(args) as WidgetInfoApiArg,
  (state: any, args: WidgetInfoApiArg) => {
    // выберем данные о виджете
    const data = usersApi.endpoints.widgetInfo.select(args)(state)?.data;
    if (!data) return;

    // пройдемся по каждому из атрибутов виджета
    // для каждого из утрибута нужно будет получить его значения и собрать их
    // по контексту заполнения (stagePointId)
    // после чего произвести вычисление значения на основе формулы атрибута
    const values = data.attrs.map(attr => {
      const rawAttrValues = _.flatten(
        _.flatten(
          data.values.map(item =>
            item.values
              // найдем для атрибута все значения
              .filter(values => values.widgetAttrId === attr._id!)
              // теперь развернем структуру так, чтобы в будущем было удобно ей оперировать
              // перегруппировывать и высчитывать финальное значение на основе формулы
              .map(({values, templateOptionId, type}) =>
                values.map(value => ({
                  ...value,
                  type,
                  templateOptionId,
                  stagePointId: item.stagePointId,
                }))
              )
          )
        )
      );

      // если у атрибута не указан templateOptionId (что значит что виджет смотрит не на
      // конкретную опцию шаблона, а на весь шаблон целиком), и формула атрибута равна list
      // (то есть вывод списка значений), то группируем данные шаблона по индексу их ввода
      if (attr.formula === 'list' && !attr.templateOptionId) {
        // сгруппируем по stagePointId
        const values = _.flatten(
          _.sortBy(Object.values(_.groupBy(rawAttrValues, 'stagePointId')), 'answerIndex').map(data =>
            Object.values(_.groupBy(data, 'answerIndex'))
          )
        );
        return {
          _id: attr._id!,
          label: attr.name,
          values,
        };
      }

      const byTemplateOptionId = _.groupBy(rawAttrValues, 'templateOptionId');
      const values = _.sortBy(Object.values(byTemplateOptionId), 'date').map(data => {
        const {templateOptionId, type} = data[0];
        const plainValues = data.map(item => item.value);
        const value = NUMERIC_TYPES.includes(type)
          ? applyNumberFormula(filterNumbers(plainValues), attr.formula)
          : applyStringFormula(plainValues, attr.formula);

        return value.map(item => ({
          templateOptionId,
          type,
          value: item,
        }));
      });

      return {
        _id: attr._id!,
        label: attr.name,
        values,
      };
    });

    return values.filter(item => item.values.length);
  }
);

export const useSliderWidgetValues = (props: WidgetInfoApiArg) => {
  const data = useSelector(state => selectWidgetValues(state, JSON.stringify(props)), _.isEqual);
  return data;
};

const applyNumberFormula = (values: number[], formula: WidgetsAttrs['formula']) => {
  switch (formula) {
    case 'sum':
      return [_.sum(values)];
    case 'avg':
      return [_.mean(values)];
    case 'max':
      return [_.max(values)];
    case 'min':
      return [_.min(values)];
    case 'first_value':
      return [values[0]];
    case 'last_value':
      return [values[values.length - 1]];
    default:
      return values;
  }
};

const applyStringFormula = (values: string[], formula: WidgetsAttrs['formula']) => {
  switch (formula) {
    case 'first_value':
      return [values[0]];
    case 'last_value':
      return [values[values.length - 1]];
    default:
      return values;
  }
};

const filterNumbers = (items: string[]) => items.map(Number).filter(item => !_.isNaN(item));
