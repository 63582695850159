import {format, parseISO} from 'date-fns';
import {ru} from 'date-fns/locale';
import * as _ from 'lodash/fp';
import {stringify} from 'query-string';

import {adminApi} from 'api';
import {FileCropApiArg} from '@apiSchema/files-api';
import {MouseEventHandler} from 'react';

export * from './redux';
export * from './columns';
export * from './hooks';

export const getRowId = (row: any) => row._id;

export const filePath = (fileId: string, method?: 'stream' | 'download' | 'preview') =>
  `${process.env.REACT_APP_FILE_API_PATH}/file_${fileId}/${method || 'download'}`;

export const croppedImagePath = (fileId: string, params: Pick<FileCropApiArg, 'ratio' | 'maxWidth'>) => {
  return `${process.env.REACT_APP_FILE_API_PATH}/file_${fileId}/crop?${stringify(params)}`;
};

export const getRatioMultiplier = (ratio: FileCropApiArg['ratio']) =>
  _.compose((item: [number, number]) => _.divide(...item), _.map(_.parseInt(10)), _.split(':'))(ratio);

export const formatDate = (date: string) => format(parseISO(date), 'PP', {locale: ru});

export const useMyId = () => {
  const {me} = adminApi.endpoints.profileIndex.useQueryState(undefined, {selectFromResult: ({data}) => ({me: data})});
  return me?._id;
};

export const isSuccessResult = <T>(result: any): result is Record<'data', T> => Boolean(result?.data?._id);

export const stopPropagation: MouseEventHandler<HTMLDivElement | HTMLButtonElement> = e => e.stopPropagation();

export const generateShortHash = (str: string) => {
  let hash = 0;
  if (str.length === 0) return hash.toString();

  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }

  return Math.abs(hash).toString().slice(-6);
};

export const filterNumbers = (items: (string | number)[]) => items.filter(item => typeof item === 'number');
