import {FC} from 'react';
import _ from 'lodash';
import numeral from 'numeral';
import {ApexOptions} from 'apexcharts';
import ReactApexChart from 'react-apexcharts';

import {adminApi} from 'api';
import {QuestsStatsProgressStatsApiArg} from '@apiSchema/admin-api';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import {BaseOptionChart} from '__theme/components/charts';

export const QuestProgressStats: FC<QuestsStatsProgressStatsApiArg> = args => {
  const {data} = adminApi.endpoints.questsStatsProgressStats.useQuery(args, {
    selectFromResult: ({data}) => ({
      data: data?.map(questData => ({
        users: questData.userId,
        name: questData.quest.name,
        data: [
          questData.localProgress.requiredPointsTotal
            ? ((questData.localProgress.requiredPointsCompleted || 0) / questData.localProgress.requiredPointsTotal) *
              100
            : 0,
        ],
      })),
    }),
  });

  const options: ApexOptions = _.merge(BaseOptionChart(), {
    chart: {
      type: 'bar',
      height: 500,
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      type: 'category',
      categories: data?.map(item => item.name) || [],
      labels: {
        show: false,
      },
    },
    yaxis: {
      max: 100,
      decimalsInFloat: 0,
      labels: {
        formatter: (val: number) => `${numeral(val.toFixed(0)).format()}%`,
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        title: '12312',
        formatter: (val: number) => `${numeral(val.toFixed(0)).format()}%`,
      },
    },
  });

  if (!data) return null;
  return (
    <Card>
      <CardHeader title="Завершаемость модулей" />
      <CardContent>
        <ReactApexChart type="bar" options={options} series={data} />
      </CardContent>
    </Card>
  );
};
