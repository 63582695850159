import {Tags} from '@apiSchema/admin-api';
import {enchanceColumns, nameColumn} from 'utils';

const tagTypes = {
  default: '',
  hashtag: 'Хэштег',
  personal_quality: 'Качество развития',
};

export const tagsColumns = enchanceColumns<Tags>(
  [
    nameColumn,
    {
      field: 'type',
      headerName: 'Тип',
      type: 'string',
      filterable: true,
      valueGetter: ({row}: any) => tagTypes[`${row.type}` as keyof typeof tagTypes] || '',
      flex: 0.2,
    },
  ],
  {hideDates: true}
);
