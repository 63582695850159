import React, {FC} from 'react';

import {visualComponents} from '../VisualComponents';
import {WidgetVisualComponentGroupItemProps} from './types';

const {container: ContainerComponent} = visualComponents;

export const WidgetVisualComponentGroupItem: FC<WidgetVisualComponentGroupItemProps> = ({
  defaultValue,
  elementType,
  value,
  options,
  containerOptions,
}) => {
  const Component = visualComponents[elementType];
  if (!Component) return null;

  let Content: JSX.Element | null = null;
  if (value) Content = <Component name={''} type={value.type} value={value.value || defaultValue} options={options} />;
  else if (defaultValue) Content = <Component name={''} value={defaultValue} options={options} />;

  if (!containerOptions) return <React.Fragment>{Content}</React.Fragment>;
  return <ContainerComponent options={containerOptions}>{Content}</ContainerComponent>;
};
