import _ from 'lodash';
import {Box} from '@mui/material';
import {BoxProps} from '@mui/system';
import {VisualComponent} from '../types';

export type ContainerComponentProps = Pick<BoxProps, 'mt' | 'mb'>;

export const ContainerComponent: VisualComponent<ContainerComponentProps> = ({options = {}, children}) => {
  return <Box {..._.pick(options, ['mt', 'mb'])}>{children}</Box>;
};
