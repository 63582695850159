import {JobScript} from 'components/__general/tasks/JobScript';
import {ScriptAnswers} from 'components/__general/tasks/Answers';
// import {useToggle} from 'react-use';

export const JobStep = ({
  scriptId,
  jobCompleted,
  // contentId,
  jobId,
  onEdit,
  onFinish,
}: {
  jobCompleted: boolean;
  jobId: string;
  scriptId: string;
  contentId?: string;
  onEdit?: () => void;
  onFinish?: () => void;
}) => {
  // const [loaded, setLoaded] = useToggle(!contentId);
  return (
    <>
      {/*{contentId && <RenderContent contentId={contentId} onLoaded={() => setLoaded(true)} />}*/}
      {!jobCompleted && <JobScript jobId={jobId} scriptId={scriptId} onAfterSave={onFinish} />}
      {jobCompleted && <ScriptAnswers jobId={jobId} scriptId={scriptId} onEdit={onEdit} />}
    </>
  );
};
