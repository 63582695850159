import {skipToken} from '@reduxjs/toolkit/dist/query';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import {adminApi} from 'api';
import {QuestsPoints} from '@apiSchema/admin-api';

import useDialog from 'components/Dialog';
import {DataList} from 'components/DataList';

import {getTaskPath} from 'modules/tasks/utils';
import {EntityPreview} from 'components/EntityPreview';
import tasksColumns from 'modules/tasks/pages/TasksList/columns';

const TaskPreview = ({taskId, onSelect}: {taskId?: string; onSelect: (taskId?: string) => void}) => {
  const [TaskSelect, open, close] = useDialog();
  const {data: task, isLoading: taskLoading} = adminApi.endpoints.taskIndex.useQuery(taskId ? {taskId} : skipToken, {
    refetchOnMountOrArgChange: true,
  });
  const selectTask = (taskId: string) => {
    onSelect(taskId);
    close();
  };

  return (
    <>
      <TaskSelect title="Выбор задания">
        <DataList
          sx={{height: '70vh'}}
          queryName="tasksIndex"
          params={{limit: 10000, enabled: 1} as any}
          onRowClick={({row}: any) => selectTask((row as any)._id)}
          columns={tasksColumns()}
        />
      </TaskSelect>
      {task && taskId && (
        <EntityPreview target="_blank" p={2} title={task.name} to={getTaskPath(taskId)} fileId={task.filesId[0]} />
      )}
      {!task && taskLoading && <CircularProgress />}
      <Stack spacing={2}>
        <Button variant="outlined" size="small" onClick={() => open()}>
          Выбрать задание
        </Button>
      </Stack>
    </>
  );
};

export const EventTaskForm = ({point}: {questId: string; point: QuestsPoints}) => {
  const [patchPoint] = adminApi.endpoints.questPointPatch.useMutation();
  const [fillWidgets] = adminApi.endpoints.questPointFillWidgets.useLazyQuery();

  const onSave = async (taskId?: string) =>
    patchPoint({
      pointId: `${point._id}`,
      questPointPartialDto: {
        options: {isRequired: point.options.isRequired, autoAccept: point.options.autoAccept, tasks: {taskId}},
      },
    });

  return (
    <Stack spacing={1}>
      <TaskPreview taskId={point?.options.tasks?.taskId} onSelect={onSave} />
      <Button onClick={() => fillWidgets({pointId: point._id!})}>Заполнить данные виджетов</Button>
    </Stack>
  );
};
