import pick from 'lodash/pick';

import {WidgetsAttrs, WidgetAttrDto} from '@apiSchema/admin-api';
import {yupNumber, yupObject, yupString, yupStringOfOptionsRequired, yupStringRequired} from 'utils/validation';

import {FieldStructure} from 'components/Form/types';
import {WidgetAttrFormDto} from 'modules/widgets/types';
import {templateListColumns} from 'modules/tasks/utils';
import {adminApi} from 'api';
import {skipToken} from '@reduxjs/toolkit/query';
import {EMPTY_VALUE} from 'components/Form/SelectField/types';

const formulaOptions: {value: WidgetAttrDto['formula']; name: string}[] = [
  {value: 'list', name: 'Список значений'},
  {value: 'first_value', name: 'Первое значение'},
  {value: 'last_value', name: 'Последнее значение'},
  {value: 'sum', name: 'Сумма значений'},
  {value: 'avg', name: 'Среднее значение'},
  {value: 'min', name: 'Минимальное значение'},
  {value: 'max', name: 'Максимальное значение'},
  // {value: 'increment', name: 'Инкремент'},
  // {value: 'delta', name: 'Дельта'},
];

export const attributeFormulaSelect: FieldStructure<Pick<WidgetAttrFormDto, 'formula'>>[] = [
  {
    type: 'select',
    name: 'formula',
    label: 'Формула',
    options: formulaOptions,
  },
];

export const fields = (attr?: WidgetsAttrs): FieldStructure<WidgetAttrFormDto>[] => [
  {
    direction: Boolean(attr) ? 'row' : 'column',
    fields: [
      {
        type: 'dialogSelect',
        name: 'templateId',
        label: 'Шаблон',
        getName: item => item.name,
        queryName: 'templatesIndex',
        params: {},
        columns: templateListColumns(),
      },
      {
        showIf: 'templateId',
        type: 'select',
        name: 'templateOptionId',
        label: 'Вариант ответа',
        options: ({templateId}: Partial<WidgetsAttrs>) => {
          const {options} = adminApi.endpoints.templateOptionsIndex.useQuery(templateId ? {templateId} : skipToken, {
            selectFromResult: ({data = []}) => ({
              options: data.map(option => ({value: option._id!, name: option.name})),
            }),
          });
          return [{name: 'Все варианты ответов', value: EMPTY_VALUE}].concat(options);
        },
      },
    ],
  },
  {
    direction: Boolean(attr) ? 'row' : 'column',
    fields: [
      {
        type: 'text',
        name: 'name',
        label: 'Название',
      },
      ...attributeFormulaSelect,
      {
        type: 'text',
        name: 'placeholder',
        label: 'Placeholder формулы',
      },
    ],
  },
];

const initialValues: WidgetAttrFormDto = {
  name: '',
  templateId: '',
  placeholder: '',
  formula: 'first_value',
  positionNumber: 0,
  templateOptionId: EMPTY_VALUE,
};

export const initials = (attr?: WidgetsAttrs): WidgetAttrFormDto =>
  attr
    ? (Object.assign({}, initialValues, pick(attr, Object.keys(initialValues))) as WidgetAttrFormDto)
    : initialValues;

export const validation = yupObject({
  name: yupStringRequired,
  formula: yupStringOfOptionsRequired(formulaOptions),
  positionNumber: yupNumber,
  templateId: yupStringRequired,
  templateOptionId: yupString,
});
