import {adminApi} from 'api';
import {ListPageProps, ListPageProviderProps} from './types';
import {JsonParam, NumberParam, useQueryParam, withDefault} from 'use-query-params';
import {useCallback, useState} from 'react';
import {GridFilterModel, GridSortModel} from '@mui/x-data-grid';
import {compose} from 'lodash/fp';

export function useList<Query, Result>({
  useFetch,
  params,
  getItems,
}: Pick<ListPageProps<Query, Result>, 'useFetch' | 'params' | 'getItems'>) {
  const {data, isFetching, isLoading} = useFetch(params || ({} as Query));
  const items = getItems ? getItems(data) : (data as any)?.data || [];

  return {data: items, isFetching, isLoading};
}

export function useIsQueryExists<T extends keyof typeof adminApi.endpoints>(
  queryName: ListPageProviderProps<T>['queryName']
) {
  const fetchFn = adminApi.endpoints[queryName];
  if (fetchFn && 'useQuery' in fetchFn) return fetchFn.useQuery;
  return null;
}

const useFilterWithQueryParams = () => {
  const [query, setQuery] = useQueryParam('filter', JsonParam);
  const [filterModel, setFilterModel] = useState<GridFilterModel | undefined>(query ? JSON.parse(query) : undefined);

  const onFilterModelChange = useCallback(
    (model: GridFilterModel) => {
      setFilterModel(model);
      if (model.items.every(i => !i.value)) setQuery(undefined, 'replaceIn');
      else setQuery(JSON.stringify(model), 'replaceIn');
    },
    [setQuery]
  );
  const reset = useCallback(() => {
    setQuery(undefined, 'replaceIn');
    setFilterModel({items: []});
    // eslint-disable-next-line
  }, []);

  return [{filterModel, onFilterModelChange}, reset];
};

const useSortWithQueryParams = (defaultModel?: GridSortModel) => {
  const [query, setQuery] = useQueryParam('sort', withDefault(JsonParam, JSON.stringify(defaultModel || [])));
  const onSortModelChange = useCallback(
    (model: GridSortModel) => {
      if (!model.length) setQuery(undefined, 'replaceIn');
      else setQuery(JSON.stringify(model), 'replaceIn');
    },
    [setQuery]
  );

  const reset = () => setQuery(undefined, 'replaceIn');

  return [{sortModel: query ? JSON.parse(query) : undefined, onSortModelChange}, reset];
};

const usePageWithQueryParams = (): [
  {page?: number; onPageChange: (page: number) => void; pageSize?: number; onPageSizeChange: (page: number) => void},
  () => void
] => {
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, undefined));
  const onPageChange = useCallback((page: number) => setPage(page || undefined, 'replaceIn'), [setPage]);

  const [pageSize, setPageSize] = useQueryParam('pageSize', withDefault(NumberParam, undefined));
  const onPageSizeChange = useCallback(
    (pageSize: number) => setPageSize(pageSize === 10 ? undefined : pageSize, 'replaceIn'),
    [setPageSize]
  );

  const reset = useCallback(() => {
    setPage(undefined);
    setPageSize(undefined);
    // eslint-disable-next-line
  }, []);

  return [{page: page || 0, onPageChange, pageSize: pageSize || 10, onPageSizeChange}, reset];
};

export const useFiltersWithQueryParams = (options?: {defaultSortModel?: GridSortModel}) => {
  const [sortProps, sortReset] = useSortWithQueryParams(options?.defaultSortModel);
  const [filterProps, filterReset] = useFilterWithQueryParams();
  const [pageProps, pageReset] = usePageWithQueryParams();

  const onResetFilters = useCallback(
    () => compose([sortReset, filterReset, pageReset] as (() => void)[])(),
    [filterReset, pageReset, sortReset]
  );

  return {
    onResetFilters,
    dataGridProps: {...sortProps, ...filterProps, ...pageProps},
  };
};

export default useList;
