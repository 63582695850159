import {FC, useCallback} from 'react';
import {skipToken} from '@reduxjs/toolkit/query';

import saveIcon from '@iconify/icons-eva/save-outline';
import trashIcon from '@iconify/icons-eva/trash-outline';
import refreshIcon from '@iconify/icons-eva/refresh-outline';
import restoreIcon from '@iconify/icons-eva/arrow-circle-up-outline';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import {adminApi} from 'api';

import {Form} from 'components/Form';
import {FormResetFnProp} from 'components/Form/types';
import {EMPTY_VALUE} from 'components/Form/SelectField/types';
import {useAttributeDeletedParam} from 'modules/widgets/utils';
import {WidgetAttrFormDto, WidgetAttrIdProp, WidgetIdProp} from 'modules/widgets/types';

import {fields, validation, initials as getInitials} from './fields';

export const WidgetAttributeForm: FC<WidgetIdProp & Partial<WidgetAttrIdProp & FormResetFnProp>> = ({
  attrId,
  onReset,
  widgetId,
}) => {
  const [showDeleted] = useAttributeDeletedParam();
  const {data: attr} = adminApi.endpoints.widgetAttrIndex.useQuery(attrId ? {attrId, widgetId} : skipToken);
  const [patch] = adminApi.endpoints.widgetAttrPatch.useMutation();
  const [add] = adminApi.endpoints.widgetAttrsAdd.useMutation();
  const [destroy] = adminApi.endpoints.widgetAttrDelete.useMutation();
  const [restore] = adminApi.endpoints.widgetAttrRestore.useMutation();
  const onRemove = () => {
    if (attrId) destroy({widgetId, attrId});
  };
  const onRestore = () => {
    if (attrId) restore({widgetId, attrId});
  };

  const onSubmit = useCallback(
    (data: WidgetAttrFormDto) => {
      if (attrId && !attr) return;
      const templateOptionId = data.templateOptionId === EMPTY_VALUE ? undefined : data.templateOptionId;
      if (attrId) return patch({widgetId, attrId, widgetAttrPartialDto: {...data, templateOptionId}});
      return add({
        widgetId,
        widgetAttrDto: {
          ...data,
          widgetId,
          templateOptionId,
        },
      });
    },
    [widgetId, add, attr, attrId, patch]
  );

  if (!attr || (!showDeleted && attr.deletedAt)) return null;
  return (
    <Card>
      <CardContent>
        <Form
          fields={fields(attr)}
          initials={getInitials(attr)}
          validation={validation}
          iconButtons={Boolean(attr)}
          onSubmit={onSubmit}
          buttonsStructure={[
            {type: 'submit', text: 'Сохранить', variant: 'text', icon: saveIcon},
            {type: 'reset', text: 'Сбросить изменения', variant: 'text', color: 'error', icon: refreshIcon},
            attr && !attr.deletedAt
              ? {
                  onClick: onRemove,
                  text: 'Удалить',
                  icon: trashIcon,
                  variant: 'text',
                  color: 'error',
                }
              : undefined,
            attr && !!attr.deletedAt
              ? {
                  onClick: onRestore,
                  text: 'Восстановить',
                  variant: 'text',
                  icon: restoreIcon,
                  color: 'success',
                }
              : undefined,
          ]}
          onReset={onReset}
          sx={{width: '100%'}}
        />
      </CardContent>
    </Card>
  );
};
