import {pick} from 'lodash';

import {ReactionsBlockDto, ReactionsBlocks} from '@apiSchema/admin-api';
import {
  yupArrayStrings,
  yupArrayStringsOfOptions,
  yupBooleanRequired,
  yupObject,
  yupStringRequired,
} from 'utils/validation';

import {FieldStructure} from 'components/Form/types';
import {useRewardValuesList} from './hook';

const allowedReferencesOptions = [
  {
    name: 'Comments',
    value: 'Comments',
  },
  {
    name: 'StagesPoints',
    value: 'StagesPoints',
  },
  {
    name: 'Publications',
    value: 'Publications',
  },
  {
    name: 'Tasks',
    value: 'Tasks',
  },
  {
    name: 'Contents',
    value: 'Contents',
  },
  {
    name: 'Goals',
    value: 'Goals',
  },
];

export const fields: FieldStructure[] = [
  {
    type: 'text',
    name: 'name',
    label: 'Название',
  },
  {
    type: 'select',
    name: 'allowedReferences',
    label: 'Разрешенные референсы',
    multiple: true,
    options: allowedReferencesOptions,
  },
  {
    type: 'select',
    variant: 'autocomplete',
    name: 'rewardValues',
    label: 'Награды',
    multiple: true,
    options: useRewardValuesList,
    autoCompleteOptions: {
      groupBy: option => option.type || '',
    },
  },
  {
    type: 'checkbox',
    name: 'enabled',
    label: 'Активный',
  },
];

const initialValues = {
  name: '',
  allowedReferences: [] as ReactionsBlockDto['allowedReferences'],
  enabled: true,
  rewardValues: [] as string[],
};

export type ReactionFormDataType = typeof initialValues;

export const initials = (data?: ReactionsBlocks): ReactionFormDataType => {
  const reactionBlockDto = data && (pick(data, Object.keys(initialValues)) as ReactionsBlockDto);
  const reactionBlockInitial = reactionBlockDto && {
    ...reactionBlockDto,
    rewardValues: reactionBlockDto.rewardValues.map(i => i.rewardId),
  };

  return reactionBlockInitial || initialValues;
};

export const validation = yupObject({
  name: yupStringRequired,
  allowedReferences: yupArrayStringsOfOptions(allowedReferencesOptions),
  rewardValues: yupArrayStrings,
  enabled: yupBooleanRequired,
});
