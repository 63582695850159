import {FC, useContext} from 'react';

import {WidgetVisual} from '../WidgetVisual';
import {useWidgetVisualsData} from '../hooks';
import {WidgetQueryContext} from '../context';
import {VisualComponent as VisualComponentProps} from '../types';

export const VisualComponentContent: FC<{stageId: string}> = ({stageId}) => {
  const {usersId} = useContext(WidgetQueryContext);
  const {visualsId} = useWidgetVisualsData({stageId, userId: usersId?.[0]});

  if (!visualsId) return null;
  return (
    <>
      {visualsId.split('.').map((visualId: string) => (
        <WidgetVisual
          key={visualId}
          visualId={visualId}
          stagesId={[stageId]}
          usersId={usersId?.[0] ? [usersId?.[0]] : []}
        />
      ))}
    </>
  );
};

export const VisualComponent: VisualComponentProps<{}> = ({value}) => {
  if (!value) return null;
  return <VisualComponentContent stageId={value} />;
};
