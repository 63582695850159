import {adminApi} from 'api';
import {QuestDeleteDataDto} from '@apiSchema/admin-api';
import {useConfirmDialog} from 'components/Dialog';
import {useSnackbarEmit} from 'utils/snackbar';

export const useDestroyStages = (questId: string, timelineId: string) => {
  const {emitError, emitSuccess} = useSnackbarEmit();
  const [deleteStages, {isLoading: deleting}] = adminApi.endpoints.questDeleteForUsers.useMutation();
  const [destructStages, {isLoading: destructing}] = adminApi.endpoints.questDestructForUsers.useMutation();

  const [DeleteStagesConfirmation, onDeleteStages] = useConfirmDialog({
    title: 'Удаление квеста без сохранения прогресса',
    text: 'Вы уверены, что хотите удалить стейджи квеста для выбранных пользователей, без сохранения прогресса по нему?\n\nБудут удалены данные о публикациях, выполненных заданий и отчетов, связанных с выполнением квеста по данному таймлайну.\n\nЭто действие необратимо.',
    btnTitle: 'Удалить',
    onConfirm: async (params?: Omit<QuestDeleteDataDto, 'timelineId'>) => {
      if (params && !(deleting || destructing)) {
        const result = await deleteStages({
          questId,
          questDeleteDataDto: {timelineId, ...params},
        });
        if ('error' in result) emitError(result.error, 'Не получилось удалить квест');
        else if ('data' in result) emitSuccess('Данные о выполнении квеста удалены');
        return result;
      }
      return null;
    },
  });

  const [DestructStagesConfirmation, onDestructStages] = useConfirmDialog({
    title: 'Удаление квеста с сохранением прогресса',
    text: 'Вы уверены, что хотите удалить стейджи квеста для выбранных пользователей?\n\nДанные о выполнении заданий в рамках квеста по данному таймлайну будут сохранены (публикации, отчеты, ответы на задания).\n\nЭто действие необратимо.',
    btnTitle: 'Удалить',
    onConfirm: async (params?: Omit<QuestDeleteDataDto, 'timelineId'>) => {
      if (params && !(deleting || destructing)) {
        const result = await destructStages({
          questId,
          questDeleteDataDto: {timelineId, ...params},
        });
        if ('error' in result) emitError(result.error, 'Не получилось удалить квест');
        else if ('data' in result) emitSuccess('Данные о выполнении квеста удалены');
        return result;
      }
      return null;
    },
  });

  return {
    DestructStagesConfirmation,
    DeleteStagesConfirmation,
    onDestructStages,
    onDeleteStages,
    destructing,
    deleting,
  };
};
