import {FC} from 'react';
import {NavLink, useParams} from 'react-router-dom';
import {Avatar, Box, Chip, Paper, Stack, Typography, TypographyProps} from '@mui/material';

import {adminApi} from 'api';
import {croppedImagePath, formatDate} from 'utils';
import {getUserName} from 'modules/users';
import {PostIdProp} from 'modules/publications/types';
import {useGetStageInfo} from 'modules/publications/utils';

import createAvatar from '__theme/utils/createAvatar';

const PublicationInfoBlock: FC<{label: string; value?: number | string; color?: TypographyProps['color']}> = ({
  label,
  value,
  color = 'grey.400',
}) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography variant="body2" color={color}>
        {label}
      </Typography>
      {typeof value !== 'undefined' && (
        <Typography variant="body2" color={color}>
          {value}
        </Typography>
      )}
    </Stack>
  );
};

const PublicationStageInfo: FC<PostIdProp> = ({postId}) => {
  const {stageId, questName, questId} = useGetStageInfo({postId});
  if (!stageId || !questId) return null;
  return (
    <Stack>
      <Typography variant="body2" color="grey.400">
        Квест
      </Typography>
      <Typography variant="body2">{questName}</Typography>
    </Stack>
  );
};

export const PublicationListItem: FC<PostIdProp> = ({postId}) => {
  const {postId: routePostId} = useParams<'postId'>();
  const isActive = postId === routePostId;
  const {data} = adminApi.endpoints.publicationIndex.useQuery({postId});

  if (!data) return null;
  const updatedDate = data.comments?.updatedDate || data.updatedAt;
  return (
    <Paper
      sx={{
        p: 2,
        width: 1,
        position: 'relative',
        border: '1px solid transparent',
        borderColor: isActive ? 'primary.light' : 'white',
        boxShadow: theme => theme.customShadows.z1,
        '&:hover': {
          boxShadow: theme => theme.customShadows.z16,
        },
      }}
      elevation={1}
    >
      <Stack
        component={NavLink}
        to={`./post/${data._id!}${window.location.search}#publication`}
        sx={{
          display: 'flex',
          color: 'grey.800',
          textDecoration: 'none',
        }}
        spacing={1}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          {!Boolean(data.isViewed) && <Box width={6} height={6} bgcolor="error.main" borderRadius={2} />}
          {data.user && (
            <Box
              width={32}
              height={32}
              border="2px solid"
              borderColor="background.default"
              component={Avatar}
              color={data.user.fileId ? 'default' : createAvatar(data.user.name || '').color}
              src={data.user.fileId ? croppedImagePath(data.user.fileId, {ratio: '1:1', maxWidth: 32}) : undefined}
            />
          )}
          <Typography variant="body2" fontWeight={400}>
            {getUserName(data.user)}
          </Typography>
        </Stack>
        {Boolean(data?.tagsSet?.length) && (
          <Stack direction="row" flexWrap="wrap" gap={1}>
            {data?.tagsSet?.map(item => (
              <Box key={item.tagId} sx={{cursor: 'pointer'}}>
                <Chip
                  key={item.tagId}
                  size="small"
                  label={`#${item.tag?.name.toLowerCase() || ''}`}
                  sx={{cursor: 'pointer'}}
                />
              </Box>
            ))}
          </Stack>
        )}
        <Box>
          <PublicationInfoBlock label="Комментариев" value={data.comments?.count || 0} />
          <PublicationInfoBlock label="Оценок" value={data.score?.count || 0} />
          <PublicationInfoBlock label="Просмотров" value={data.viewsCount || 0} />
          {updatedDate && <PublicationInfoBlock label="Обновлена" value={formatDate(updatedDate)} />}
          {data.createdAt && <PublicationInfoBlock label="Создана" value={formatDate(data.createdAt)} />}
          {data.deletedAt && (
            <PublicationInfoBlock label="Удалена" color="error.light" value={formatDate(data.deletedAt)} />
          )}
          {data.claims?.isClaimed && data.claims?.claimedDate && (
            <PublicationInfoBlock
              color="error.light"
              label="Заблокирован по жалобе"
              value={formatDate(data.claims.claimedDate)}
            />
          )}
        </Box>
        <PublicationStageInfo postId={postId} />
      </Stack>
    </Paper>
  );
};
