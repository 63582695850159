import {QuestsPointsRewards} from '@apiSchema/admin-api';
import {
  yupObject,
  yupString,
  yupStringRequired,
  yupNumberRequired,
  yupNumberOrEmptyString,
  yupStringOfOptionsNullable,
} from 'utils/validation';
import {RewardFormValuesType} from './types';

const initialValues: RewardFormValuesType = {
  rewardValue: {
    rewardId: '',
    value: '',
  },
  enabled: true,
  limits: 0,
  description: '',
  paymentCost: 0,
  stagePointStatus: null,
};

export const getInitialValues = (questPointReward?: QuestsPointsRewards): RewardFormValuesType => {
  if (!questPointReward) return initialValues;
  return {
    rewardValue: {
      rewardId: questPointReward.rewardValue.rewardId,
      value: questPointReward.rewardValue.value,
    },
    enabled: questPointReward.enabled,
    limits: questPointReward.limits,
    description: questPointReward.description,
    paymentCost: questPointReward.paymentCost,
    stagePointStatus: questPointReward.stagePointStatus,
  };
};

export const StatusOptionsType = [
  {
    name: 'new',
    value: 'Новый',
  },
  {
    name: 'in_progress',
    value: 'В процессе выполнения',
  },
  // {
  //   name: 'checking',
  //   value: 'На проверке',
  // },
  {
    name: 'ready',
    value: 'Готов (выполнен)',
  },
  {
    name: 'completed',
    value: 'Завершен',
  },
  {
    name: 'failed',
    value: 'Провален',
  },
];

export const validationSchema = yupObject({
  limits: yupNumberOrEmptyString,
  description: yupString,
  paymentCost: yupNumberOrEmptyString,
  rewardValue: yupObject({
    rewardId: yupStringRequired,
    value: yupNumberRequired,
  }),
  stagePointStatus: yupStringOfOptionsNullable(StatusOptionsType),
});
