import {FC} from 'react';
import {Pagination, PaginationItem} from '@mui/material';
import {gridPaginationSelector, useGridApiContext, useGridSelector} from '@mui/x-data-grid';

export const CustomPagination: FC = () => {
  const apiRef = useGridApiContext();
  const data = useGridSelector(apiRef, gridPaginationSelector);

  return (
    <Pagination
      size="small"
      boundaryCount={1}
      siblingCount={1}
      shape="rounded"
      color="primary"
      variant="outlined"
      page={data.page + 1}
      count={data.pageCount}
      renderItem={props2 => <PaginationItem {...props2} />}
      onChange={(event: React.ChangeEvent<unknown>, value: number) => apiRef.current.setPage(value - 1)}
    />
  );
};
