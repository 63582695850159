import {useAtom} from 'jotai';
import {useAtomValue} from 'jotai/utils';

import {memo} from 'react';
import {useUnmount} from 'react-use';
import Box from '@mui/system/Box';
import {RatingBar} from 'components/__general/components/RatingBar';

import {AnswerProps} from '../types';
import {getOptionsAtonm, scriptActionExactValue} from '../atoms';

export const ScoreInput = memo((params: AnswerProps) => {
  const [defaultValue, setData] = useAtom(scriptActionExactValue(params));
  const [option] = useAtomValue(getOptionsAtonm(params.templateOptionId));

  useUnmount(() => {
    getOptionsAtonm.remove(params.templateOptionId);
    scriptActionExactValue.remove(params);
  });

  if (!option) return null;
  return (
    <Box>
      <Box sx={{typography: 'subtitle2', color: 'grey.200', mb: 0.5}}>{option.name}</Box>
      <RatingBar max={10} value={parseInt(defaultValue, 10)} onChange={newScore => setData(newScore as any)} />
    </Box>
  );
});
