import {Icon} from '@iconify/react';
import trashIcon from '@iconify/icons-eva/trash-outline';

import _ from 'lodash';
import {shallowEqual} from 'react-redux';
import {intervalToDuration} from 'date-fns';
import {FC, MouseEventHandler, memo} from 'react';

import {Box, ButtonBase, IconButton, Stack, Typography} from '@mui/material';

import {adminApi} from 'api';
import {Form} from 'components/Form';
import {VideoPlayerProp} from 'modules/files/types';
import {yupObject, yupString} from 'utils/validation';

export const Timecode: FC<VideoPlayerProp & {timecodeId: string}> = memo(
  ({videoPlayerRef, timecodeId}) => {
    const {time, description, enabled} = adminApi.endpoints.timecodeIndex.useQueryState(
      {timecodeId},
      {
        selectFromResult: ({data}) => ({
          enabled: data?.enabled,
          time: data?.time || 0,
          description: data?.description || '',
        }),
      }
    );
    const [patchTimecode] = adminApi.endpoints.timecodePatch.useMutation();
    const deleteTimecode = () => patchTimecode({timecodeId, timecodePartialDto: {enabled: false}});
    const updateDescription = (description: string) => patchTimecode({timecodeId, timecodePartialDto: {description}});
    const jumpTo: MouseEventHandler<HTMLButtonElement> = e => {
      e.preventDefault();
      if (!time) return;
      videoPlayerRef.current?.currentTime(time / 1000);
    };

    if (!enabled) return null;
    return (
      <Stack direction="row" alignItems="flex-start">
        <Box
          bgcolor="rgba(101, 47, 209, 0.16)"
          p={1}
          py={1}
          width={85}
          height={40}
          borderRadius={1}
          component={ButtonBase}
          onClick={jumpTo}
        >
          <Typography variant="body2" color="rgba(101, 47, 209, 1)">
            {getTimecodeTime(time)}
          </Typography>
        </Box>
        <Box flexGrow={1} alignSelf="top" px={1}>
          <Form
            onSubmit={() => {}}
            initials={{description}}
            validation={yupObject({description: yupString})}
            fields={[
              {
                name: 'description',
                type: 'text',
                variant: 'text',
                minRows: 1,
                label: 'Описание',
                size: 'small',
                updateFn: _.debounce(updateDescription, 500),
              },
            ]}
            buttonsPosition="hidden"
          />
        </Box>
        <Box>
          <IconButton onClick={deleteTimecode} size="small">
            <Icon icon={trashIcon} width={16} height={16} />
          </IconButton>
        </Box>
      </Stack>
    );
  },
  (prev, next) => shallowEqual(prev, next)
);

const getTimecodeTime = (time: number) => {
  const duration = intervalToDuration({start: 0, end: time});
  const hours = String(duration.hours).padStart(2, '0') || '00';
  const minutes = String(duration.minutes).padStart(2, '0') || '00';
  const seconds = String(duration.seconds).padStart(2, '0') || '00';
  return `${hours}:${minutes}:${seconds}`;
};
