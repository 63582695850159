import {adminApi} from 'api';
import {ReceiptsServiceDto, ReceiptsServices} from '@apiSchema/admin-api';

export const useReceiptsServiceAction = (serviceId?: string) => {
  const [create] = adminApi.endpoints.receiptsServicesAdd.useMutation();
  const [update] = adminApi.endpoints.receiptsServiceUpdate.useMutation();

  return async (data: ReceiptsServiceDto): Promise<ReceiptsServices | false> => {
    let result;
    if (serviceId) result = await update({serviceId, receiptsServiceDto: data});
    else result = await create({receiptsServiceDto: data});
    if ('data' in result) {
      return result.data;
    }
    return false;
  };
};
