import {FC, useCallback} from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import {adminApi} from 'api';
import {Form} from 'components/Form';
import {useConfirmDialog} from 'components/Dialog';
import {FormResetFnProp} from 'components/Form/types';
import {WidgetIdProp, WidgetRuleFormDto, WidgetRuleProp} from 'modules/widgets/types';

import {fields, validation, initials} from './fields';

export const WidgetRuleForm: FC<WidgetIdProp & Partial<WidgetRuleProp & FormResetFnProp>> = ({
  widgetId,
  rule,
  onReset,
}) => {
  const [add] = adminApi.endpoints.widgetRulesAdd.useMutation();
  const [patch] = adminApi.endpoints.widgetRulePatch.useMutation();
  const [remove] = adminApi.endpoints.widgetRuleDelete.useMutation();
  const [Dialog, onRemove] = useConfirmDialog({
    title: 'Вы действительно хотите удалить правило?',
    onConfirm: () => {
      if (!rule) return;
      return remove({widgetId, ruleId: rule._id!});
    },
  });

  const onSubmit = useCallback(
    (data: WidgetRuleFormDto) => {
      if (rule) return patch({widgetId, ruleId: `${rule._id}`, widgetRulePartialDto: data});
      return add({widgetId, widgetRuleDto: {...data, widgetId}});
    },
    [widgetId, add, patch, rule]
  );

  return (
    <>
      <Dialog />
      <Card>
        <CardHeader title={rule ? `Правило "${rule.name}"` : 'Создать новое правило'} />
        <CardContent>
          <Form
            fields={fields(!!rule)}
            validation={validation}
            initials={initials(rule)}
            onSubmit={onSubmit}
            onReset={onReset}
            resetAfterSubmit
            buttonsStructure={
              [
                {
                  text: 'Сохранить',
                  type: 'submit',
                },
                {
                  text: 'Сбросить',
                  type: 'reset',
                  color: 'info',
                },
                rule &&
                  !rule?.deletedAt && {
                    text: 'Удалить',
                    color: 'error',
                    onClick: onRemove,
                  },
              ].filter(Boolean) as any
            }
          />
        </CardContent>
      </Card>
    </>
  );
};
