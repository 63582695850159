import Avatar from '@mui/material/Avatar';
import {GroupsUsers} from '@apiSchema/admin-api';
import {GridRenderCellParams} from '@mui/x-data-grid';

import {croppedImagePath} from 'utils';
import {getUserInitials} from 'modules/users';

const sizes = {small: 24, medium: 32};

export const renderUserAvatar =
  (size?: 'small' | 'medium') =>
  ({row}: GridRenderCellParams<any, GroupsUsers>) => {
    if (!row.user) return null;
    const sizePx = sizes[size || 'medium'];
    return (
      <Avatar
        src={
          row.user?.fileId ? croppedImagePath(row.user.fileId, {ratio: '1:1', maxWidth: sizes['medium']}) : undefined
        }
        sx={{width: sizePx, height: sizePx, bgcolor: 'background.neutral'}}
      >
        {getUserInitials(row.user)}
      </Avatar>
    );
  };
