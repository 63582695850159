import {FC} from 'react';
import {skipToken} from '@reduxjs/toolkit/query';
import EditIcon from '@mui/icons-material/EditOutlined';
import {Box, Card, CardContent, IconButton} from '@mui/material';

import {adminApi} from 'api';

import {useDialog} from 'components/Dialog';
import {GroupIdProp} from 'modules/groups/types';
import {GroupItem} from 'modules/groups/components/GroupItem';
import {GroupForm} from 'modules/groups/containers/GroupForm';

export const GroupLayoutInfo: FC<GroupIdProp> = ({groupId}) => {
  const [Dialog, openDialog] = useDialog();
  const {data} = adminApi.useGroupIndexQuery(groupId ? {groupId} : skipToken);

  if (!data) return null;
  return (
    <>
      <Dialog title="Редактировать группу">
        <Box mt={2}>
          <GroupForm groupId={groupId} />
        </Box>
      </Dialog>
      <Card>
        <CardContent>
          <GroupItem
            group={data}
            Action={
              <IconButton size="small" onClick={openDialog}>
                <EditIcon fontSize="small" />
              </IconButton>
            }
          />
        </CardContent>
      </Card>
    </>
  );
};
