import _ from 'lodash';
import {api as rawAuthApi} from './generated/auth-api';
import {api as rawFilesApi} from './generated/files-api';
import sockets from 'utils/sockets';

export {adminApi} from './adminApi';
export {usersApi} from './usersApi';

export const authApi = rawAuthApi.enhanceEndpoints({});
export const filesApi = rawFilesApi.enhanceEndpoints({
  endpoints: {
    fileInfo: {
      onCacheEntryAdded: async (args, {cacheDataLoaded, cacheEntryRemoved, dispatch}) => {
        try {
          await cacheDataLoaded;
          const onMessage = _.debounce(() => {
            dispatch(filesApi.util.prefetch('fileInfo', args, {force: true}));
          }, 100);
          const socket = await sockets.connect(`${process.env.REACT_APP_FILE_API_PATH}/file_${args.fileId}`, {
            onmessage: onMessage,
            showMessage: true,
          });
          await cacheEntryRemoved;
          socket.close();
        } catch (e) {
          console.warn('Ошибка при получении информации о файле', args.fileId, e);
        }
      },
    },
  },
});
