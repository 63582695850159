import {stringify} from 'query-string';
import {Draggable} from '@hello-pangea/dnd';
import {FC, memo, MouseEventHandler} from 'react';
import {ArrayParam, encodeQueryParams} from 'use-query-params';
import {NavLink, useMatch, useResolvedPath} from 'react-router-dom';

import {Icon} from '@iconify/react';
import trashOutline from '@iconify/icons-eva/trash-outline';

import Box from '@mui/system/Box';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import {adminApi} from 'api';
import {useConfirmDialog} from 'components/Dialog';
import {QuestPointIdProp} from 'modules/events/types';
import {questPointTypeNames} from 'modules/events/utils';

import {useQuestPointHistory} from './context';
import {QuestStructureBranch} from './QuestStructureBranch';

const QuestPointSubscription: FC<QuestPointIdProp> = ({pointId}) => {
  adminApi.endpoints.questPointIndex.useQuerySubscription({pointId});
  return null;
};

export const QuestStructurePoint: FC<QuestPointIdProp> = memo(({pointId}) => {
  const {pointsId} = useQuestPointHistory();
  const {data: point} = adminApi.endpoints.questPointIndex.useQuery({pointId});
  const [patchPoint] = adminApi.endpoints.questPointPatch.useMutation();
  const query = encodeQueryParams({opened: ArrayParam}, {opened: pointsId});

  const resolved = useResolvedPath(`./${pointId}`);
  const isActive = useMatch({path: resolved.pathname, end: true});
  const isExpanded = pointsId.includes(pointId);

  const onRemove: MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    if (point) {
      patchPoint({pointId, questPointPartialDto: {enabled: !point.enabled}});
    }
  };
  const [ConfirmDialog, onRemoveWithConfirm] = useConfirmDialog({
    text: 'Вы уверены что хотите удалить квест-поинт из квеста?',
    onConfirm: onRemove as any,
  });

  if (!point) return null;
  if (!point.enabled) return null;

  return (
    <>
      <ConfirmDialog />
      <QuestPointSubscription pointId={pointId} />
      <Draggable draggableId={`${point._id}`} index={point.positionNumber || 0}>
        {({innerRef, draggableProps, dragHandleProps}) => (
          <Box {...draggableProps} ref={innerRef}>
            <Paper
              {...dragHandleProps}
              elevation={1}
              sx={{
                p: 2,
                pr: 7,
                width: 1,
                position: 'relative',
                outlineColor: theme => theme.palette.primary.light,
                outlineWidth: isExpanded ? 1 : 0,
                outlineStyle: 'solid',
                backgroundColor: isActive ? 'primary.main' : 'white',
                boxShadow: theme => theme.customShadows.z1,
                '&:hover': {
                  boxShadow: theme => theme.customShadows.z16,
                },
              }}
            >
              <Box
                component={NavLink}
                to={`./${pointId}?${stringify(query)}`}
                sx={{
                  textDecoration: 'none',
                  color: isActive ? 'white' : 'grey.800',
                }}
              >
                {point.type in questPointTypeNames && (
                  <Typography variant="body2" color="grey.400">
                    {questPointTypeNames[point.type]}
                  </Typography>
                )}
                {point.name && (
                  <Typography variant="body2" fontWeight={400}>
                    {point.name}
                  </Typography>
                )}
              </Box>
              <Box
                px={2}
                top={0}
                right={0}
                bottom={0}
                display="flex"
                position="absolute"
                alignItems="center"
                onClick={e => e.stopPropagation()}
              >
                <IconButton size="small" onClick={onRemoveWithConfirm}>
                  <Icon icon={trashOutline} color={isActive ? 'white' : 'red'} />
                </IconButton>
              </Box>
            </Paper>

            {point.type === 'quest' && point.options.quests?.questId && (
              <QuestStructureBranch questId={point.options.quests.questId} pointId={pointId} />
            )}
          </Box>
        )}
      </Draggable>
    </>
  );
});
