import {adminApi} from 'api';
import {QuestsPointOptionsPayments} from '@apiSchema/admin-api';
import {yupArrayStringsOfOptionsRequired, yupObject, yupStringRequired} from 'utils/validation';

import {paymentServicesTypes} from 'modules/payments/utils';
import {FieldStructure} from 'components/Form/types';

const usePaymentServicesTypesOptions = () => {
  const {data} = adminApi.endpoints.paymentsServicesIndex.useQuery({});
  return (data?.data || []).map(i => ({
    name: i.name,
    type: i.type,
    value: i.type,
  }));
};

const useRewardIdOptions = () => {
  const {data} = adminApi.endpoints.rewardsIndex.useQuery({});
  return (data?.data || [])
    .filter(i => i.type === 'currency')
    .map(i => ({
      name: `${i.name} (${i.currency?.isoNumber} ${i.currency?.code})`,
      type: i.type,
      value: i._id,
    }));
};

export const validationSchema = yupObject({
  rewardId: yupStringRequired,
  paymentServicesTypes: yupArrayStringsOfOptionsRequired(paymentServicesTypes),
});

export const fields: FieldStructure[] = [
  {
    type: 'select',
    variant: 'autocomplete',
    name: 'rewardId',
    label: 'Награда',
    options: useRewardIdOptions,
  },
  {
    type: 'select',
    variant: 'autocomplete',
    name: 'paymentServicesTypes',
    multiple: true,
    label: 'Платежные сервисы',
    options: usePaymentServicesTypesOptions,
  },
  {
    type: 'text',
    name: 'successMessage',
    label: 'Сообщение после успешной оплаты',
  },
  {
    type: 'text',
    name: 'errorMessage',
    label: 'Сообщение об ошибке',
  },
  {
    type: 'checkbox',
    name: 'savePaymentData',
    label: 'Сохранять данные платежа',
  },
];

export const initialValues: QuestsPointOptionsPayments = {
  rewardId: '',
  errorMessage: '',
  successMessage: '',
  paymentServicesTypes: [],
  savePaymentData: false,
};
