import {FC} from 'react';

import Stack from '@mui/material/Stack';

import {adminApi} from 'api';
import {WidgetIdProp} from 'modules/widgets/types';
import {WidgetRuleForm} from '../WidgetRuleForm';

export const WidgetRulesList: FC<WidgetIdProp> = ({widgetId}) => {
  const {data: rules} = adminApi.endpoints.widgetRulesIndex.useQuery({widgetId});
  if (!rules) return null;

  return (
    <Stack spacing={1.5}>
      {rules.map(rule => (
        <WidgetRuleForm key={rule._id} widgetId={widgetId} rule={rule} />
      ))}
    </Stack>
  );
};
