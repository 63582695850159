import {FC} from 'react';
import {Card, CardContent, CardHeader} from '@mui/material';

import {adminApi} from 'api';

import {Form} from 'components/Form';

import {questColumns} from 'modules/events/pages/EventsList/columns';
import {yupObject, yupString, yupStringRequired} from 'utils/validation';

import {usePublicationsListQuery} from '../../utils';

const getTagsOptions = () => {
  const {options} = adminApi.endpoints.tagsIndex.useQuery(
    {type: 'publication'},
    {
      selectFromResult: ({data}) => ({
        options: data?.data?.map(item => ({name: item.name, value: item._id!})) || [],
      }),
    }
  );
  return [{name: 'Без тега', value: ''}].concat(options);
};

const useGetWidgetOptions = () => {
  const {options} = adminApi.endpoints.widgetsIndex.useQuery(
    {limit: 10000},
    {
      selectFromResult: ({data}) => ({
        options: data?.data?.map(item => ({name: item.name, value: item._id!})) || [],
      }),
    }
  );

  return [{name: 'Не фильтровать по виджету', value: ''}].concat(options);
};

export const PublicationsListFilter: FC = () => {
  const [query, setQuery] = usePublicationsListQuery();
  return (
    <Card>
      <CardHeader title="Публикации" />
      <CardContent>
        <Form
          validation={yupObject({
            sort: yupStringRequired,
            userId: yupString,
            questId: yupString,
          })}
          fields={[
            {
              direction: 'column',
              fields: [
                {
                  name: 'sort',
                  type: 'select',
                  label: 'Сортировка',
                  options: [
                    {value: 'updated_date', name: 'По дате обновления'},
                    {value: 'created_date', name: 'По дате создания'},
                    {value: 'rating', name: 'По рейтингу оценок'},
                  ],
                },
                {
                  name: 'tagId',
                  type: 'select',
                  label: 'Тег',
                  variant: 'autocomplete',
                  multiple: false,
                  options: getTagsOptions,
                },
                {
                  name: 'questId',
                  label: 'Квест',
                  type: 'dialogSelect',
                  queryName: 'questsIndex',
                  columns: questColumns(),
                  multiple: false,
                  getName: item => item.name,
                },
                {
                  name: 'widgetId',
                  type: 'select',
                  label: 'Виджет',
                  variant: 'autocomplete',
                  multiple: false,
                  options: useGetWidgetOptions,
                },
                {
                  name: 'isDeleted',
                  type: 'select',
                  label: 'Статус удаления',
                  multiple: false,
                  options: [
                    {value: 'none', name: 'Все посты'},
                    {value: 'deleted', name: 'Только удаленные'},
                    {value: 'not_deleted', name: 'Только не удаленные'},
                  ],
                },
                {
                  name: 'isViewed',
                  type: 'select',
                  label: 'Статус просмотра',
                  multiple: false,
                  options: [
                    {value: 'none', name: 'Все посты'},
                    {value: 'viewed', name: 'Только просмотренные'},
                    {value: 'not_viewed', name: 'Только не просмотренные'},
                  ],
                },
                {
                  name: 'isCommented',
                  type: 'select',
                  label: 'Статус комментирования',
                  multiple: false,
                  options: [
                    {value: 'none', name: 'Все посты'},
                    {value: 'commented', name: 'Только с комментарием'},
                    {value: 'not_commented', name: 'Только без комментария'},
                  ],
                },
              ],
            },
            {
              fields: [
                // {type: 'checkbox', name: 'notViewedByCurator', label: 'Не просмотренные куратором'},
                // {type: 'checkbox', name: 'notCommentedByCurator', label: 'Без комментария куратора'},
              ],
            },
          ]}
          initials={query}
          onSubmit={newQuery => setQuery(newQuery, 'push')}
          onReset={() => setQuery({}, 'push')}
        />
      </CardContent>
    </Card>
  );
};
