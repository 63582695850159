import pick from 'lodash/pick';

import {WidgetsVisualsProps} from '@apiSchema/admin-api';
import {yupObject, yupString, yupStringJSON} from 'utils/validation';

import {WidgetVisualDesignFormDto, WidgetVisualPropFormDto} from 'modules/widgets/types';
import {FieldStructure} from 'components/Form/types';
import {adminApi} from 'api';
import {WidgetsVisualsDesigns} from '@apiSchema/users-api';
import {SelectDesignTemplate} from './SelectDesignTemplate';

export const fields: FieldStructure<WidgetVisualPropFormDto>[] = [
  {
    type: 'text',
    variant: 'text',
    name: 'custom',
    label: 'custom',
    minRows: 1,
  },
];

export const designFields = (visualId: string): FieldStructure<WidgetVisualDesignFormDto>[] => [
  {
    fields: [
      {
        type: 'text',
        variant: 'text',
        name: 'name',
        label: 'Название',
        minRows: 1,
      },
      {
        type: 'select',
        name: 'visualPropId',
        label: 'Ассоциированный проп',
        options: () => {
          const {options} = adminApi.endpoints.widgetsVisualPropsIndex.useQuery(
            {visualId, populates: ['widget', 'widgetAttr']},
            {
              selectFromResult: ({data = []}) => ({
                options: data.map(item => ({value: item._id, name: item.widgetAttr?.name || ''})),
              }),
            }
          );
          return options || [];
        },
      },
    ],
  },
  {
    type: 'render',
    name: '_customJSONString',
    label: 'Выбор шаблона',
    render: SelectDesignTemplate,
  },
  {
    type: 'text',
    variant: 'text',
    name: 'customJSONString',
    label: 'custom',
    minRows: 10,
  },
];

const initialValues: WidgetVisualPropFormDto = {
  custom: '',
};

const designInitialValues: WidgetVisualDesignFormDto = {
  name: '',
  visualPropId: '',
  customJSONString: '',
};

export const initials = (prop?: WidgetsVisualsProps): WidgetVisualPropFormDto =>
  prop
    ? ({
        custom: prop.custom ? JSON.stringify(prop.custom) : '',
      } as WidgetVisualPropFormDto)
    : initialValues;

export const designInitials = (prop?: WidgetsVisualsDesigns): WidgetVisualDesignFormDto =>
  prop ? (pick(prop, Object.keys(designInitialValues)) as WidgetVisualDesignFormDto) : designInitialValues;

export const validation = yupObject({
  custom: yupStringJSON,
});

export const designValidation = yupObject({
  name: yupString,
  visualPropId: yupString,
  customJSONString: yupStringJSON,
});
