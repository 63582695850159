import {Groups} from '@apiSchema/admin-api';
import {enchanceColumns, nameColumn} from 'utils';
import {renderGroupAvatar} from './renderGroupAvatar';

export const groupsColumns = enchanceColumns<Groups>(
  [
    nameColumn,
    {
      field: 'stream.name',
      headerName: 'Поток',
      valueGetter: ({row}) => row.stream?.name,
      width: 150,
    },
  ],
  {hideDates: true}
);

export const subGroupsColumns = enchanceColumns<Groups>(
  [
    {
      field: 'fileId',
      headerName: ' ',
      renderCell: renderGroupAvatar,
      filterable: false,
      sortable: false,
      width: 32,
    },
    {...nameColumn, sortable: false},
  ],
  {hideDates: true, hideEnabled: true}
);
