import {adminApi} from 'api';
import {Card, CardContent} from '@mui/material';
import {QuestPointTabComponent} from 'modules/events/types';

import {EventTaskForm} from '../EventTaskSelector';
import {QuestPointOptionsForm} from '../QuestPointSettings';
import {EventContentSelector} from '../EventContentSelector';

export const QuestPointGeneralTab: QuestPointTabComponent = ({pointId, questId}) => {
  const {data: point} = adminApi.endpoints.questPointIndex.useQuery({pointId});

  if (!point) return null;
  return (
    <>
      <Card>
        <CardContent>
          <QuestPointOptionsForm pointId={pointId} />
        </CardContent>
      </Card>
      {['task', 'content'].includes(point.type) && (
        <Card>
          <CardContent>
            {point.type === 'task' && point.options.tasks && <EventTaskForm questId={questId} point={point} />}
            {point.type === 'content' && point.options.contents && <EventContentSelector point={point} />}
            {/* {point.type === 'payment' && <QuestPaymentForm point={point} />} */}
            {/* {point.type === 'goal' && point.options.goals && <EventGoalForm point={point} />} */}
            {/* {point.type === 'timeline' && point.options.timelines && <QuestTimelinePoint point={point} />} */}
            {/* {point.type === 'publication' && point.options.publications && (
              <QuestPublicationForm questId={questId} point={point} />
            )} */}
            {/* {point.type === 'quest' && point.options.quests && (
              <QuestPointQuestOptionsForm questId={questId} point={point} />
            )} */}
          </CardContent>
        </Card>
      )}
    </>
  );
};
