import {lazy} from 'react';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {Navigate, Params, RouteObject, useParams} from 'react-router';

import {Stack} from '@mui/material';

import {adminApi} from 'api';
import {Groups, Users} from '@apiSchema/admin-api';

import {PATH_APP} from 'router/routes';

import {AccessGuard} from 'modules/auth/containers';
import IsExistsContainer from 'components/IsExistsContainer';
import {BreadcrumbsSet} from 'components/Breadcrumbs/BreadcrumbsSet';

import {getUserName} from 'modules/users';
import {postRoute} from 'modules/publications';
import {WidgetContainers} from 'modules/widgets/utils';
import {WidgetContainer} from 'modules/widgets/containers';

import {getGroupPath} from './utils';
import {GroupQuestStats} from './containers';
import {GroupLayout, GroupInfoLayout, GroupUserInfoLayout} from './layouts';

const GroupsList = lazy(() => import('./pages/GroupsList'));

const UseParam = <T extends string | Record<string, string | undefined> = string>({
  children,
}: {
  children: (params: Readonly<[T] extends [string] ? Params<T> : Partial<T>>) => JSX.Element;
}) => {
  const params = useParams<T>();
  return children(params);
};

const useGetGroup = () => {
  const {groupId} = useParams<'groupId'>();
  return adminApi.useGroupIndexQuery(groupId ? {groupId} : skipToken);
};
const useGetUser = () => {
  const {userId} = useParams<'userId'>();
  return adminApi.endpoints.userIndex.useQuery(userId ? {userId} : skipToken);
};

const router: RouteObject = {
  path: 'groups',
  element: (
    <AccessGuard endpoint="groupsIndex">
      <BreadcrumbsSet name="Группы" href={PATH_APP.group.list} />
    </AccessGuard>
  ),
  children: [
    {index: true, element: <Navigate to="./list" replace />},
    {
      path: 'list',
      children: [
        {index: true, element: <GroupsList />},
        {
          path: ':groupId',
          element: (
            <AccessGuard endpoint="groupIndex">
              <IsExistsContainer<Groups> useFetch={useGetGroup}>
                {group => (
                  <BreadcrumbsSet name={`Группа – ${group.name}`} href={getGroupPath(group._id!)}>
                    <GroupLayout groupId={group._id!} />
                  </BreadcrumbsSet>
                )}
              </IsExistsContainer>
            </AccessGuard>
          ),
          children: [
            {index: true, element: <Navigate to="./info" />},
            {
              path: 'info',
              element: (
                <IsExistsContainer<Groups> useFetch={useGetGroup}>
                  {group => <GroupInfoLayout groupId={group._id!} />}
                </IsExistsContainer>
              ),
              children: [
                {
                  index: true,
                  element: (
                    <IsExistsContainer<Groups> useFetch={useGetGroup}>
                      {group => (
                        <Stack spacing={1}>
                          <GroupQuestStats groupId={group._id!} />
                          <WidgetContainer
                            type="diagram"
                            groupBy="season"
                            precision="lifetime"
                            containerName={WidgetContainers.USER_STATS}
                            groupsId={[group._id!, ...(group.subGroupsId || [])]}
                          />
                        </Stack>
                      )}
                    </IsExistsContainer>
                  ),
                },
                postRoute,
              ],
            },
            {
              path: 'user/:userId',
              element: (
                <IsExistsContainer<Groups> useFetch={useGetGroup}>
                  {group => (
                    <IsExistsContainer<Users> useFetch={useGetUser}>
                      {user => (
                        <BreadcrumbsSet
                          name={`Участник группы – ${getUserName(user)}`}
                          href={getGroupPath(group._id!, `user/${user._id!}`)}
                        >
                          <GroupUserInfoLayout userId={user._id!} />
                        </BreadcrumbsSet>
                      )}
                    </IsExistsContainer>
                  )}
                </IsExistsContainer>
              ),
              children: [
                {
                  index: true,
                  element: (
                    <UseParam<'userId' | 'groupId'>>
                      {param => (
                        <Stack spacing={1}>
                          <GroupQuestStats groupId={param.groupId!} userId={param.userId!} />
                          <WidgetContainer
                            type="diagram"
                            groupBy="season"
                            precision="lifetime"
                            containerName={WidgetContainers.USER_STATS}
                            usersId={[param.userId!]}
                          />
                        </Stack>
                      )}
                    </UseParam>
                  ),
                },
                postRoute,
              ],
            },
          ],
        },
      ],
    },
  ],
};

export default router;
