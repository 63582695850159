import sortBy from 'lodash/sortBy';
import saveIcon from '@iconify/icons-eva/save-outline';
import trashIcon from '@iconify/icons-eva/trash-outline';
import refreshIcon from '@iconify/icons-eva/refresh-outline';

import {adminApi} from 'api';
import {QuestPointRewardDto} from '@apiSchema/admin-api';

import {Form} from 'components/Form';
import {useConfirmDialog} from 'components/Dialog';

import {getInitialValues, validationSchema, StatusOptionsType} from './utils';
import {RewardFormValuesType, QuestPointRewardsFormProps} from './types';

export const QuestPointRewardForm = ({pointId, rewards, questPointReward}: QuestPointRewardsFormProps) => {
  const [addReward] = adminApi.endpoints.questPointsRewardsAdd.useMutation();
  const [patchReward] = adminApi.endpoints.questPointRewardPatch.useMutation();
  const optionsAutocomplete = rewards.map(i => ({
    type: i.type,
    name: i.name,
    value: `${i._id}`,
  }));

  const [ConfirmRemoveDialog, onRemove] = useConfirmDialog({
    title: 'Вы действительно хотите удалить награду?',
    onConfirm: async () => {
      if (!questPointReward) return;
      await patchReward({
        pointId,
        rewardId: questPointReward._id as string,
        questPointRewardPartialDto: {enabled: false},
      });
    },
  });

  const onSubmit = async (data: RewardFormValuesType) => {
    if (typeof data.rewardValue.value !== 'number') return;
    if (questPointReward && typeof questPointReward._id !== 'string') return;

    if (typeof data.limits === 'string' && !data.limits) delete data.limits;
    const questPointRewardDto: QuestPointRewardDto = {
      ...data,
      stagePointStatus: data.stagePointStatus || undefined,
    } as QuestPointRewardDto;

    if (questPointReward) {
      return patchReward({
        pointId,
        rewardId: questPointReward._id as string,
        questPointRewardPartialDto: questPointRewardDto,
      });
    } else return addReward({pointId, questPointRewardDto});
  };

  return (
    <>
      <ConfirmRemoveDialog />
      <Form
        resetAfterSubmit
        onSubmit={onSubmit}
        buttonsPosition="bottom"
        validation={validationSchema}
        initials={getInitialValues(questPointReward)}
        buttonsStructure={[
          {type: 'submit', text: 'Сохранить', display: 'icon', icon: saveIcon},
          {type: 'reset', text: 'Сбросить', color: 'error', display: 'icon', icon: refreshIcon},
          questPointReward
            ? {onClick: onRemove, text: 'Удалить', display: 'icon', icon: trashIcon, color: 'error'}
            : undefined,
        ]}
        fields={[
          {
            fields: [
              {
                type: 'select',
                label: 'Награда',
                name: 'rewardValue.rewardId',
                options: sortBy(optionsAutocomplete, 'type'),
                variant: 'autocomplete',
                autoCompleteOptions: {
                  groupBy: (item: any) => item.type,
                },
              },
              {
                type: 'text',
                name: 'rewardValue.value',
                label: 'Количество',
                variant: 'number',
              },
              {
                type: 'select',
                label: 'Статус',
                name: 'stagePointStatus',
                options: StatusOptionsType,
              },
            ],
          },
          // {
          //   fields: [
          //     {
          //       type: 'text',
          //       name: 'limits',
          //       label: 'Ограничения',
          //       variant: 'number',
          //     },
          //     {
          //       type: 'text',
          //       label: 'Цена при оплате',
          //       name: 'paymentCost',
          //       variant: 'number',
          //     },
          //   ],
          // },
          {
            type: 'text',
            label: 'Описание',
            name: 'description',
            variant: 'text',
            minRows: 1,
          },
        ]}
      />
    </>
  );
};
