/* eslint-disable no-console */
export const sockets = {
  async connect(
    url: string,
    {onmessage, showMessage = false}: {onmessage: (e: MessageEvent) => void; showMessage?: boolean}
  ) {
    const token = await localStorage.getItem('game_access_token');
    const ws = new WebSocket(url, `Bearer_${token}`);

    ws.onopen = function () {
      if (showMessage) console.log('Socket is opened.', url);
    };

    if (onmessage) ws.onmessage = onmessage;

    ws.onclose = function (e) {
      if (showMessage) console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
      setTimeout(function () {
        sockets.connect(url, {onmessage});
      }, 1000);
    };

    ws.onerror = () => {
      if (showMessage) console.error('Socket encountered error. Closing socket');
      ws.close();
    };

    return ws;
  },
};

export default sockets;
