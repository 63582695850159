import {useCallback, useEffect} from 'react';
import {useNavigate} from 'react-router';
import {pick} from 'lodash';

import {adminApi} from 'api';
import {UserDto, Users} from '@apiSchema/admin-api';

import {initialValues} from './fields';

export const useCreateUser = (data?: Users) => {
  const navigate = useNavigate();
  const [update] = adminApi.useUserUpdateMutation();
  const [create, {data: result}] = adminApi.useUsersAddMutation();
  const onSubmit = useCallback(
    (inputData: UserDto) => {
      if (data && data._id) return update({userId: data._id, userDto: inputData});
      else return create({userDto: inputData});
    },
    [data, update, create]
  );

  useEffect(() => {
    if (!data?._id && result) navigate(`./${result._id}`);
  }, [result, data?._id, navigate]);

  const initials = data ? (pick(data, Object.keys(initialValues)) as UserDto) : initialValues;

  return {initials, onSubmit};
};

export default useCreateUser;
