import MailIcon from '@mui/icons-material/MailOutline';
import LockIcon from '@mui/icons-material/LockOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import RemoveIcon from '@mui/icons-material/RemoveOutlined';

import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';

import {adminApi} from 'api';
import {UsersLogins} from '@apiSchema/admin-api';

import {DataList} from 'components/DataList';
import {useConfirmDialog} from 'components/Dialog';

import {useRemoveLogin} from './hook';
import {userLoginsColumns} from './columns';
import {useUserLoginForm, useUserLoginPasswordForm} from '../UserLoginForm';
import {CardContent} from '@mui/material';

export const UserLogins = ({userId}: {userId: string}) => {
  const [remove] = adminApi.useUserLoginDeleteMutation();
  const [UserLoginForm, openCreateForm, openEditForm] = useUserLoginForm();
  const [LoginPasswordForm, openLoginPasswordForm] = useUserLoginPasswordForm();
  const {onInviteSend} = useRemoveLogin(userId);
  const [ConfirmDialog, openConfirm] = useConfirmDialog({
    btnType: 'error',
    text: 'Действие необратимо',
    title: 'Вы уверены что хотите удалить этот логин?',
    onConfirm: (row: UsersLogins | undefined) => row && remove({loginId: `${row._id}`, userId: `${row.userId}`}),
  });

  return (
    <>
      <ConfirmDialog />
      <LoginPasswordForm />
      <UserLoginForm userId={userId} />
      <Card>
        <CardHeader title="Логины" action={<Button onClick={openCreateForm}>Добавить логин</Button>} />
        <CardContent sx={{px: 1}}>
          <DataList
            hideToolbar
            hideFooterPagination
            density="compact"
            queryName="userLoginsIndex"
            params={{userId}}
            columns={userLoginsColumns(
              ({row}) => (
                <>
                  {row.type === 'plain' && (
                    <IconButton size="small" onClick={() => openLoginPasswordForm(row)} title="Назначить пароль">
                      <LockIcon fontSize="small" />
                    </IconButton>
                  )}
                  <IconButton size="small" onClick={() => openEditForm(`${row._id}`)} title="Отредактировать">
                    <EditIcon fontSize="small" />
                  </IconButton>
                  <IconButton size="small" onClick={() => onInviteSend(`${row._id}`)} title="Отправить приглашение">
                    <MailIcon fontSize="small" />
                  </IconButton>
                  <IconButton size="small" onClick={() => openConfirm(row)} title="Удалить">
                    <RemoveIcon fontSize="small" />
                  </IconButton>
                </>
              ),
              {flex: 0.3, hideDates: true, hideEnabled: true}
            )}
          />
        </CardContent>
      </Card>
    </>
  );
};

export default UserLogins;
