import {Theme} from '@mui/material/styles';
import {QuestsPoints, Stages, StagesPoints} from '@apiSchema/admin-api';
import {PATH_APP} from 'router/routes';

export const getQuestPath = (questId?: string) => `${PATH_APP.tasks.quests.root}/${questId}`;

export const getQuestPointPath = (questId?: string, questPointId?: string) =>
  `${PATH_APP.tasks.quests.root}/${questId}/structure/${questPointId}`;

export const stageStatuses: Record<Stages['status'], string> = {
  new: 'Не начат',
  ready: 'Готов',
  failed: 'Провален',
  deadline: 'Дедлайн',
  rejected: 'Отклонен',
  completed: 'Завершен',
  checking: 'Проверяется',
  in_progress: 'В работе',
};

export const getQuestChartColors = (theme: Theme) => [
  theme.palette.chart.violet[0],
  theme.palette.warning.main,
  theme.palette.chart.blue[0],
  theme.palette.chart.green[0],
  theme.palette.success.main,
  theme.palette.chart.red[0],
  theme.palette.error.dark,
  theme.palette.grey[400],
];

export const questPointTypeNames: Record<QuestsPoints['type'], string> = {
  goal: 'Цель',
  quest: 'Квест',
  task: 'Задание',
  button: 'Кнопка',
  content: 'Материал',
  timeline: 'Таймлайн',
  payment: 'Платеж',
  shortcut: 'Ярлык',
  widget: 'Виджет',
  publication: 'Публикация',
};

export const questPointReferenceNames: Record<Exclude<StagesPoints['referenceName'], undefined>, string> = {
  Payments: 'Платеж',
  Publications: 'Публикация',
  Jobs: 'Задание',
  Contents: 'Материал',
  Users: 'Пользователь',
  WidgetsValues: 'Значение виджета',
  Goals: 'Цель',
  Timelines: 'Таймлайн',
  Stages: 'Выполнение квеста',
};
