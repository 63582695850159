import {debounce} from 'lodash';
import {ChangeEvent, FC} from 'react';
import {Button, Typography, Stack, TextField} from '@mui/material';

import {APIListHeaderProps} from './types';

export const APIListNavigation: FC<APIListHeaderProps> = ({setPage, totalPages = 0, page}) => {
  const updatePage = debounce((e: ChangeEvent<HTMLInputElement>) => {
    const newPageNumber = Number(e.target.value);
    if (!isNaN(newPageNumber) && newPageNumber >= 0 && newPageNumber < totalPages) setPage(newPageNumber - 1);
  }, 300);

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Button onClick={() => setPage(current => (current || 0) - 1)} disabled={page <= 1}>
        Назад
      </Button>
      <Stack justifyContent="center" alignItems="center">
        <TextField
          key={page}
          size="small"
          defaultValue={page}
          inputProps={{style: {textAlign: 'center'}}}
          onChange={updatePage}
          sx={{width: 50}}
        />
        <Typography
          align="center"
          variant="body2"
          fontSize={12}
          color="grey.400"
        >{`Всего страниц: ${totalPages}`}</Typography>
      </Stack>
      <Button onClick={() => setPage(current => (current || 0) + 1)} disabled={totalPages ? page >= totalPages : false}>
        Вперед
      </Button>
    </Stack>
  );
};
