import _ from 'lodash';

import {getListTags} from './utils';
import {api as rawUsersApi} from './generated/users-api';
import {VisualComponentData} from 'modules/widgets/containers/WidgetVisual/types';
import {getWidgetVisualDesignOptions} from 'modules/widgets/containers/WidgetVisual/selectors';
import {adminApi} from './adminApi';

const injectedApi = rawUsersApi.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => {
    return {
      visualDesign: builder.query<VisualComponentData[], {visualId: string; containerName: string}>({
        queryFn: () => ({data: []}),
      }),
    };
  },
});

export const usersApi = injectedApi.enhanceEndpoints({
  addTagTypes: ['Goals', 'Publications', 'Comments', 'Achivements', 'WidgetsInfo', 'Users'],
  endpoints: {
    // ACHIEVMENTS
    achievementsIndex: {
      providesTags: [{type: 'Achivements'}],
    },
    // VIEWS
    viewsSetViewed: {
      onQueryStarted: ({referenceId, referenceName}, {dispatch, queryFulfilled}) => {
        queryFulfilled.then(() => {
          if (referenceName === 'Publications')
            dispatch(adminApi.util.prefetch('publicationIndex', {postId: referenceId}, {force: true}));
        });
      },
    },
    // USERS
    userIndex: {
      providesTags: (_, __, {userId}) => [{type: 'Users', id: userId}],
    },
    // COMMENTS
    commentIndex: {
      providesTags: (_, __, {commentId}) => [{type: 'Comments', id: commentId}],
    },
    commentsAdd: {
      onQueryStarted: (args, {dispatch, queryFulfilled}) => {
        queryFulfilled.then(res => {
          dispatch(usersApi.util.upsertQueryData('commentIndex', {commentId: res.data.comment._id!}, res.data));
          dispatch(adminApi.util.upsertQueryData('commentIndex', {commentId: res.data.comment._id!}, res.data));
          dispatch(
            adminApi.util.invalidateTags([
              args.addCommentDto.commentId
                ? {type: 'Comments' as never, id: args.addCommentDto.commentId}
                : {type: 'Comments' as never, id: JSON.stringify(_.pick(args, ['referenceName', 'referenceId']))},
            ])
          );
        });
      },
      invalidatesTags: (response, error, args) => [
        {type: args.referenceName as any, id: args.referenceId},
        args.addCommentDto.commentId
          ? {type: 'Comments', id: args.addCommentDto.commentId}
          : {type: 'Comments', id: JSON.stringify(_.pick(args, ['referenceName', 'referenceId']))},
      ],
    },
    commentsIndex: {
      providesTags: (response, error, args) =>
        args.branchCommentId
          ? [{type: 'Comments', id: args.branchCommentId}]
          : [{type: 'Comments', id: JSON.stringify(_.pick(args, ['referenceName', 'referenceId']))}],
      onQueryStarted: async (args, {dispatch, queryFulfilled}) =>
        queryFulfilled.then(res => {
          res.data.data?.forEach(item => {
            dispatch(usersApi.util.upsertQueryData('commentIndex', {commentId: item._id!}, {comment: item} as any));
          });
        }),
    },
    // PUBLICATIONS
    publicationsIndex: {
      providesTags: getListTags('Publications', (item: any) => item._id) as any,
      onQueryStarted: async (args, {dispatch, queryFulfilled}) =>
        queryFulfilled.then(res => {
          res.data.data?.forEach(item => {
            dispatch(usersApi.util.upsertQueryData('publicationIndex', {postId: item._id!}, item));
          });
        }),
    },
    publicationIndex: {
      providesTags: (_, __, {postId}) => [{type: 'Publications', id: postId}],
    },
    publicationPatch: {
      invalidatesTags: (_, __, {postId}) => [{type: 'Publications', id: postId}],
    },
    publicationSafeDelete: {
      invalidatesTags: (_, __, {postId}) => [{type: 'Publications', id: postId}],
      onQueryStarted: async (_, {dispatch, queryFulfilled}) => {
        try {
          const result = await queryFulfilled;
          if (result.data.user) {
            const {_id: userId, stats} = result.data.user;
            const streamId = stats?.streamsId || [];
            dispatch(
              usersApi.util.prefetch(
                'publicationsIndex',
                {userId, streamId, sort: 'created_date', isDeleted: true},
                {force: true}
              )
            );
            dispatch(
              usersApi.util.prefetch('publicationsIndex', {userId, streamId, sort: 'created_date'}, {force: true})
            );
          }
        } catch {}
      },
    },
    publicationSafeRestore: {
      invalidatesTags: (_, __, {postId}) => [{type: 'Publications', id: postId}],
      onQueryStarted: async (_, {dispatch, queryFulfilled}) => {
        try {
          const result = await queryFulfilled;
          if (result.data.user) {
            const {_id: userId, stats} = result.data.user;
            const streamId = stats?.streamsId || [];
            dispatch(
              usersApi.util.prefetch(
                'publicationsIndex',
                {userId, streamId, sort: 'created_date', isDeleted: true},
                {force: true}
              )
            );
            dispatch(
              usersApi.util.prefetch('publicationsIndex', {userId, streamId, sort: 'created_date'}, {force: true})
            );
          }
        } catch {}
      },
    },
    //
    widgetsInfo: {
      providesTags: (data = []) => data.map(item => ({type: 'WidgetsInfo', id: `widget.${item.widget._id!}`})),
      onQueryStarted: async ({_id, ...rest}, {queryFulfilled, dispatch}) => {
        try {
          const result = await queryFulfilled;
          result.data.forEach(item => {
            dispatch(usersApi.util.upsertQueryData('widgetInfo', {widgetId: item.widget._id!, ...rest}, item));
          });
        } catch (e) {}
      },
    },
    widgetInfo: {
      providesTags: data => (data ? [{type: 'WidgetsInfo', id: `widget.${data.widget._id!}`}] : []),
    },
    widgetsVisualsIndex: {
      onQueryStarted: async ({populates, _id}, {queryFulfilled, dispatch}) => {
        try {
          const result = await queryFulfilled;
          if (!_id) {
            result.data.map(visual =>
              dispatch(usersApi.util.upsertQueryData('widgetsVisualsIndex', {_id: [visual._id!], populates}, [visual]))
            );
          }
          if (_id) {
            result.data.forEach(visual => {
              const props = visual?.props?.map(item => _.pick(item, ['widgetAttrId', 'widgetId', '_id'])) || [];
              const propsById = _.keyBy(props, '_id');
              const designs = _.groupBy(
                _.sortBy(
                  (visual?.designs || []).map(item => {
                    const options = getWidgetVisualDesignOptions(item);
                    return {
                      ...item,
                      options,
                      containerId: options.containerId || 'root',
                      widgetId: item?.visualPropId ? propsById[item?.visualPropId].widgetId : undefined,
                      widgetAttrId: item?.visualPropId ? propsById[item?.visualPropId].widgetAttrId : undefined,
                    } as any;
                  }),
                  'positionNumber'
                ),
                'containerId'
              );
              Object.keys(designs).forEach(containerName =>
                dispatch(
                  usersApi.util.upsertQueryData(
                    'visualDesign',
                    {visualId: visual._id!, containerName},
                    designs[containerName] || []
                  )
                )
              );
            });
          }
        } catch (e) {}
      },
    },
    // GOALS
    goalsIndex: {
      providesTags: getListTags('Goals', (item: any) => item._id) as any,
    },
    goalIndex: {
      providesTags: (_, __, {goalId}) => [{type: 'Goals', id: goalId}],
    },
    goalPatch: {
      invalidatesTags: (_, __, {goalId}) => [{type: 'Goals', id: goalId}],
    },
    goalSafeDelete: {
      invalidatesTags: (_, __, {goalId}) => [{type: 'Goals', id: goalId}],
      onQueryStarted: async (_, {dispatch, queryFulfilled}) => {
        try {
          const result = await queryFulfilled;
          if (result.data.userId) {
            dispatch(
              usersApi.util.prefetch(
                'goalsIndex',
                {userId: result.data.userId, sort: 'created_date', isDeleted: true},
                {force: true}
              )
            );
            dispatch(
              usersApi.util.prefetch('goalsIndex', {userId: result.data.userId, sort: 'created_date'}, {force: true})
            );
          }
        } catch {}
      },
    },
    goalSafeRestore: {
      invalidatesTags: (_, __, {goalId}) => [{type: 'Goals', id: goalId}],
      onQueryStarted: async (_, {dispatch, queryFulfilled}) => {
        try {
          const result = await queryFulfilled;
          if (result.data.userId) {
            dispatch(
              usersApi.util.prefetch(
                'goalsIndex',
                {userId: result.data.userId, sort: 'created_date', isDeleted: true},
                {force: true}
              )
            );
            dispatch(
              usersApi.util.prefetch('goalsIndex', {userId: result.data.userId, sort: 'created_date'}, {force: true})
            );
          }
        } catch {}
      },
    },
    commentSafeDelete: {
      invalidatesTags: (_, __, {commentId}) => [{type: 'Comments', id: commentId}],
    },
    commentSafeRestore: {
      invalidatesTags: (_, __, {commentId}) => [{type: 'Comments', id: commentId}],
    },
  },
});
