import {FC, ReactNode} from 'react';
import {skipToken} from '@reduxjs/toolkit/query';

import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import {adminApi, usersApi} from 'api';
import {WidgetsListApiArg, WidgetInfoApiArg} from '@apiSchema/users-api';

import {ChartWidget} from './ChartWidget';
import {RefreshOutlined} from '@mui/icons-material';

export const WidgetContainer: FC<
  WidgetsListApiArg & Omit<WidgetInfoApiArg, 'widgetId'> & {action?: (widgetId: string) => ReactNode}
> = ({containerName, type, action, ...props}) => {
  const [fillData] = adminApi.endpoints.widgetFillData.useMutation();
  const {data: widgets = [], isLoading: listLoading} = usersApi.endpoints.widgetsList.useQuery({containerName, type});
  const {isLoading} = usersApi.endpoints.widgetsInfo.useQuery(
    widgets.length ? {_id: widgets.map(item => item._id!), ...props} : skipToken
  );

  if (isLoading || listLoading) return null;
  return (
    <Grid rowSpacing={2} container>
      {widgets.map(widget => (
        <Grid item xs={12} md={12} lg={12} key={widget._id!}>
          <Card>
            <CardHeader
              title={widget.name}
              action={
                <>
                  {action ? action(widget._id!) : null}
                  <IconButton onClick={() => fillData({widgetId: widget._id!})} size="small">
                    <RefreshOutlined />
                  </IconButton>
                </>
              }
            />
            <CardContent>
              <ChartWidget key={widget._id!} widgetId={widget._id!} {...props} />
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
