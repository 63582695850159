import {ReactNode} from 'react';
import pick from 'lodash/pick';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import DestructIcon from '@mui/icons-material/FilterCenterFocus';

import {Timelines, QuestTimelinePartialDto, QuestDeleteDataDto} from '@apiSchema/admin-api';
import {yupArrayStrings, yupBoolean, yupObject, yupString} from 'utils/validation';
import {accessBaseFields, accessFieldsValidation, accessInitials} from 'utils/form/accessBaseFields';

import {Form} from 'components/Form';
import {FieldStructure} from 'components/Form/types';

import {questColumns} from 'modules/events/pages/EventsList/columns';
import {usersColumns} from 'modules/users/containers/UsersList/columns';
import {streamsColumns} from 'modules/streams/containers/StreamsList/columns';
import {groupsColumns} from 'modules/groups/pages/GroupsList/columns';
import {useDestroyStages} from './hook';

const settingsInitialRow: Omit<QuestTimelinePartialDto, 'dateStart' | 'dateFinish'> & {
  dateStart: null | string;
  dateFinish: null | string;
} = {
  isNotIn: false,
  dateStart: null,
  dateFinish: null,
  totalMatch: false,
  isAllowedAfterFinishDate: true,
  usersId: [],
  questsId: [],
  groupsId: [],
  streamsId: [],
  questsPointsId: [],
  ...accessInitials,
};

const fields = (
  questId: string,
  isQuestPoint?: boolean,
  onDelete?: (props: Omit<QuestDeleteDataDto, 'timelineId'>) => void,
  onDestruct?: (props: Omit<QuestDeleteDataDto, 'timelineId'>) => void
): FieldStructure[] => [
  {
    fields: [
      {
        label: 'Дата начала',
        name: 'dateStart',
        type: 'dateTime',
        initial: '',
      },
      {
        label: 'Дата окончания',
        name: 'dateFinish',
        type: 'dateTime',
        initial: '',
      },
    ],
  },
  {
    direction: 'column',
    fields: [
      {
        fields: [
          {
            type: 'checkbox',
            name: 'isNotIn',
            label: 'Все, кроме',
            initial: false,
          },
          {
            type: 'checkbox',
            name: 'totalMatch',
            label: 'Полное совпадение',
            initial: false,
          },
          {
            showIf: isQuestPoint,
            type: 'checkbox',
            name: 'isAllowedAfterFinishDate',
            label: 'Доступно на дедлайне',
            initial: true,
          },
        ],
      },
      {
        fields: [
          {
            showIf: !isQuestPoint,
            name: 'streamsId',
            type: 'dialogSelect',
            label: 'Потоки',
            getName: item => item.name,
            initial: [],
            multiple: true,
            queryName: 'streamsIndex',
            columns: streamsColumns(undefined, undefined, ({id}) => [
              onDelete && {
                showInMenu: true,
                icon: <DeleteIcon />,
                label: 'Удалить квест',
                onClick: () => onDelete({streamsId: [id.toString()]}),
              },
              onDestruct && {
                showInMenu: true,
                icon: <DestructIcon />,
                label: 'Деструктуировать кевест',
                onClick: () => onDestruct({streamsId: [id.toString()]}),
              },
            ]),
          },
          {
            showIf: !isQuestPoint,
            name: 'groupsId',
            type: 'dialogSelect',
            label: 'Группы',
            getName: item => item.name,
            multiple: true,
            initial: [],
            queryName: 'groupsIndex',
            params: {populates: ['stream']},
            columns: groupsColumns(undefined, undefined, ({id}) => [
              onDelete && {
                showInMenu: true,
                icon: <DeleteIcon />,
                label: 'Удалить квест',
                onClick: () => onDelete({groupsId: [id.toString()]}),
              },
              onDestruct && {
                showInMenu: true,
                icon: <DestructIcon />,
                label: 'Деструктуировать кевест',
                onClick: () => onDestruct({groupsId: [id.toString()]}),
              },
            ]),
          },
          {
            showIf: !isQuestPoint,
            name: 'usersId',
            type: 'dialogSelect',
            label: 'Пользователи',
            getName: item => item.name,
            queryName: 'usersIndex',
            params: {populates: ['logins']},
            columns: usersColumns(),
            multiple: true,
            initial: [],
          },
          {
            showIf: !isQuestPoint,
            name: 'questsId',
            type: 'dialogSelect',
            getName: item => item.name,
            label: 'Квесты',
            queryName: 'questsIndex',
            columns: questColumns(),
            multiple: true,
            initial: [],
          },
          {
            name: 'questsPointsId',
            type: 'dialogSelect',
            label: 'Точки квестов',
            queryName: 'questPointsIndex',
            getName: item => item.name,
            columns: questColumns(),
            multiple: true,
            params: {questId},
            getData: data => (data || []) as any[],
            disableLimit: true,
            initial: [],
          },
        ],
      },
    ],
  },
  ...accessBaseFields,
];

const validation = yupObject({
  dateStart: yupString.nullable(),
  dateFinish: yupString.nullable(),
  streamsId: yupArrayStrings,
  groupsId: yupArrayStrings,
  usersId: yupArrayStrings,
  questsId: yupArrayStrings,
  questsPointsId: yupArrayStrings,
  isNotIn: yupBoolean,
  access: accessFieldsValidation,
  isAllowedAfterFinishDate: yupBoolean,
  totalMatch: yupBoolean,
});

export const AccessSettings = ({
  data,
  onSave,
  onRemove,
  questId,
  Controls,
  isQuestPoint,
}: {
  questId: string;
  data: Timelines;
  Controls?: ReactNode;
  isQuestPoint?: boolean;
  onRemove?: (timelineId: string) => void;
  onSave: (data: QuestTimelinePartialDto, timelineId: string) => Promise<any>;
}) => {
  const {DeleteStagesConfirmation, onDeleteStages, onDestructStages, DestructStagesConfirmation} = useDestroyStages(
    questId,
    `${data._id}`
  );
  const initialValues = {...settingsInitialRow, ...pick(data || {}, Object.keys(settingsInitialRow))};
  const disabled = data.status && ['building', 'checking'].includes(data.status);

  return (
    <Stack direction="row" spacing={2}>
      <DeleteStagesConfirmation />
      <DestructStagesConfirmation />
      <Box flexGrow={1}>
        <Form
          fields={fields(questId, isQuestPoint, onDeleteStages, onDestructStages)}
          initials={initialValues}
          validation={validation}
          submitConfirmation={
            data.status === 'active'
              ? 'Вы уверены что хотите изменить настройки активного таймлайна? Это может привести к исчезновению квеста у ряда пользователей, либо другие последствия.'
              : undefined
          }
          onSubmit={input =>
            onSave(
              {
                ...input,
                access: {
                  ...input.access,
                  isActive: input?.access?.isActive || false,
                },
                dateStart: input?.dateStart || undefined,
                dateFinish: input?.dateFinish || undefined,
              } as any,
              data._id as string
            )
          }
          buttonsStructure={[
            {
              type: 'submit',
              text: 'Сохранить',
              disabled,
            },
            {type: 'reset', text: 'Отменить', variant: 'text', color: 'error', disabled},
            onRemove
              ? {
                  disabled,
                  color: 'error',
                  text: 'Удалить',
                  variant: 'outlined',
                  onClick: () => onRemove(`${data._id}`),
                }
              : undefined,
          ]}
        />
      </Box>
      {Controls && <Box>{Controls}</Box>}
    </Stack>
  );
};

export const Timeline = AccessSettings;
