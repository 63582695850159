import {FC} from 'react';
import {format, parseISO} from 'date-fns';
import {Alert, Button, Card, CardContent, CardHeader, FormControlLabel, Stack, Switch} from '@mui/material';

import {adminApi} from 'api';
import {UserIdProp} from 'modules/users/types';
import {WithGuard, useHasAccess} from 'modules/auth/containers';

export const UserMainAccess: FC<UserIdProp> = WithGuard('userPermissionsIndex', ({userId}) => {
  const canRestore = useHasAccess('userRestore');
  const canUpdate = useHasAccess('userPermissionsUpdate');

  const {data} = adminApi.endpoints.userIndex.useQuery({userId});
  const [restore] = adminApi.endpoints.userRestore.useMutation();
  const [updatePermission] = adminApi.endpoints.userPermissionsUpdate.useMutation();
  const {data: permissions} = adminApi.endpoints.userPermissionsIndex.useQuery({userId});

  const setIsAppUser = (value: boolean) =>
    updatePermission({userId, userPermissionDto: {...permissions, isApplicationUser: value}});
  const setIsSuperAdmin = (value: boolean) =>
    updatePermission({userId, userPermissionDto: {...permissions, isSuperAdmin: value}});

  if (!data) return null;
  return (
    <Card sx={{width: '100%'}}>
      <CardHeader title="Доступ" />
      <CardContent>
        <FormControlLabel
          label="Пользователь приложения"
          labelPlacement="end"
          control={
            <Switch
              disabled={!canUpdate}
              checked={!!permissions?.isApplicationUser}
              onChange={e => setIsAppUser(e.target.checked)}
            />
          }
        />
        <FormControlLabel
          label="Суперадмин"
          labelPlacement="end"
          control={
            <Switch
              disabled={!canUpdate}
              checked={!!permissions?.isSuperAdmin}
              onChange={e => setIsSuperAdmin(e.target.checked)}
            />
          }
        />
        {data?.deletedAt && (
          <Stack mt={1} spacing={1}>
            <Alert severity="info">{`Пользователь удален ${format(
              parseISO(data.deletedAt),
              'dd MM yyyy'
            )}. Восстановление пользователя возможно в течении 90 дней с даты удаления.`}</Alert>
            {!canRestore && <Alert severity="warning">У вас нет доступа к функции восстановления пользователя</Alert>}
            {canRestore && (
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={() => restore({userId})}
                disabled={!canRestore}
              >
                Восстановить
              </Button>
            )}
          </Stack>
        )}
      </CardContent>
    </Card>
  );
});
