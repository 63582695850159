import {FC, memo, useMemo} from 'react';
import {skipToken} from '@reduxjs/toolkit/dist/query';

import {usersApi} from 'api';

import {WidgetQueryContext} from './context';
import {WidgetVisualComponent} from './WidgetVisualComponent';
import {WidgetVisualComponentGroup} from './WidgetVisualComponentGroup';
import {VisualComponentData, WidgetVisualComponentProps} from './types';

const WidgetVisualNode: FC<{visualId: string; containerName: string}> = ({visualId, containerName = 'root'}) => {
  const {data = []} = usersApi.endpoints.visualDesign.useQueryState({visualId, containerName});
  const grouped: (VisualComponentData | string)[] = [];
  const groups: Record<string, VisualComponentData[]> = {};
  data.forEach(item => {
    if (!item.options.group) return grouped.push(item);
    if (groups[item.options.group]) return;
    grouped.push(item.options.group);
    groups[item.options.group] = data.filter(i => i.options.group === item.options.group);
  });

  return (
    <>
      {grouped.map(item => {
        if (typeof item === 'string') {
          if (!groups[item]) return null;
          return (
            <WidgetVisualComponentGroup
              key={item}
              visualId={visualId}
              containerName={containerName}
              designId={groups[item].map(i => i._id!)}
            />
          );
        }
        return (
          <WidgetVisualComponent
            key={item._id!}
            visualId={visualId}
            containerName={containerName}
            designId={item._id!}
          />
        );
      })}
    </>
  );
};

export const WidgetVisual: FC<WidgetVisualComponentProps> = memo(({visualId, stagesId, usersId}) => {
  const {widgetsId} = usersApi.endpoints.widgetsVisualsIndex.useQuery(
    {_id: [visualId], populates: ['designs', 'props']},
    {selectFromResult: ({data}) => ({widgetsId: data?.[0].widgetsId})}
  );
  const widgetInfoQuery = useMemo(() => ({_id: widgetsId, stagesId, usersId}), [widgetsId, stagesId, usersId]);
  usersApi.endpoints.widgetsInfo.useQuerySubscription(widgetsId ? {_id: widgetsId, stagesId, usersId} : skipToken);

  return (
    <WidgetQueryContext.Provider value={widgetInfoQuery}>
      <WidgetVisualNode visualId={visualId} containerName="root" />
    </WidgetQueryContext.Provider>
  );
});
