import {Quests} from '@apiSchema/admin-api';
import {enchanceColumns, nameColumn} from 'utils';

export const questColumns = enchanceColumns<Quests>(
  [
    nameColumn,
    {
      field: 'type',
      headerName: 'Тип',
      valueFormatter: ({value}) => value,
      flex: 0.4,
    },
  ],
  {hideDates: false}
);
