import {createApi} from '@reduxjs/toolkit/query/react';
import {default as customBaseQuery} from '../queries/auth';
export const api = createApi({
  reducerPath: 'auth',
  baseQuery: customBaseQuery,
  tagTypes: [],
  endpoints: build => ({
    rootLogout: build.mutation<RootLogoutApiResponse, RootLogoutApiArg>({
      query: () => ({url: `/logout`, method: 'POST'}),
    }),
    rootIndex: build.query<RootIndexApiResponse, RootIndexApiArg>({
      query: () => ({url: `/`}),
    }),
    rootJsonDocs: build.query<RootJsonDocsApiResponse, RootJsonDocsApiArg>({
      query: () => ({url: `/docs.json`}),
    }),
    rootDocs: build.query<RootDocsApiResponse, RootDocsApiArg>({
      query: () => ({url: `/docs.html`}),
    }),
    rootSwagger: build.query<RootSwaggerApiResponse, RootSwaggerApiArg>({
      query: () => ({url: `/swagger.html`}),
    }),
    appTokensIndex: build.query<AppTokensIndexApiResponse, AppTokensIndexApiArg>({
      query: () => ({url: `/app_tokens`}),
    }),
    appTokensAdd: build.mutation<AppTokensAddApiResponse, AppTokensAddApiArg>({
      query: queryArg => ({
        url: `/app_tokens`,
        method: 'POST',
        body: queryArg.usersAppTokens,
      }),
    }),
    loginRouteLogin: build.mutation<LoginRouteLoginApiResponse, LoginRouteLoginApiArg>({
      query: queryArg => ({
        url: `/login`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    loginRouteRequestCode: build.mutation<LoginRouteRequestCodeApiResponse, LoginRouteRequestCodeApiArg>({
      query: queryArg => ({
        url: `/request`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    loginRouteConfirmCode: build.mutation<LoginRouteConfirmCodeApiResponse, LoginRouteConfirmCodeApiArg>({
      query: queryArg => ({
        url: `/confirm`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    loginRouteInvite: build.query<LoginRouteInviteApiResponse, LoginRouteInviteApiArg>({
      query: queryArg => ({
        url: `/invite`,
        params: {code: queryArg.code},
      }),
    }),
  }),
});
export type RootLogoutApiResponse = /** status 200  */ MessageResponse;
export type RootLogoutApiArg = void;
export type RootIndexApiResponse = /** status 200 Список маршрутов */ RouteElement[];
export type RootIndexApiArg = void;
export type RootJsonDocsApiResponse = /** status 200 Документация OpenAPI */ string;
export type RootJsonDocsApiArg = void;
export type RootDocsApiResponse = /** status 200 Интерфейс для работы с документацией */ string;
export type RootDocsApiArg = void;
export type RootSwaggerApiResponse = /** status 200 Интерфейс для работы с документацией */ string;
export type RootSwaggerApiArg = void;
export type AppTokensIndexApiResponse = /** status 200 Список токенов */ UsersAppTokens[];
export type AppTokensIndexApiArg = void;
export type AppTokensAddApiResponse = /** status 200 Новый токен */ UsersAppTokens;
export type AppTokensAddApiArg = {
  usersAppTokens: UsersAppTokens;
};
export type LoginRouteLoginApiResponse =
  /** status 200 Успешная авторизация. Возвращает bearer токен. */ UsersLoginsTokens;
export type LoginRouteLoginApiArg = {
  /** Авторизационные данные: логин, пароль */
  body: {
    login: string;
    password: string;
  };
};
export type LoginRouteRequestCodeApiResponse = /** status 200 Код отправлен */ {
  message: string;
  policyConfirmationRequired: boolean;
  privacyConfirmationRequired: boolean;
  loginType: 'plain' | 'phone' | 'email';
};
export type LoginRouteRequestCodeApiArg = {
  body: {
    login: string;
  };
};
export type LoginRouteConfirmCodeApiResponse =
  /** status 200 Успешная авторизация. Возвращает bearer токен. */ UsersLoginsTokens;
export type LoginRouteConfirmCodeApiArg = {
  body: {
    code: string;
    policyConfirmed?: boolean;
    privacyConfirmed?: boolean;
    login: string;
  };
};
export type LoginRouteInviteApiResponse = /** status 200 Информация о пользователе */ InviteDataResponse;
export type LoginRouteInviteApiArg = {
  /** Код приглашения */
  code: string;
};
export type MessageResponse = {
  message?: string;
};
export type ErrorMessage = {
  status: number;
  message: string;
  data?: object;
};
export type AccessPoint = {
  method?: string;
  path?: string;
  prefix?: string;
};
export type RouteElement = {
  AccessAdminControl?: AccessPoint[];
  method: string;
  path: string;
  operationId: string;
};
export type UsersAppTokens = {
  applicationId?: string;
  token: string;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type UsersLoginsTokens = {
  userLoginId: string;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
  accessToken: string;
  refreshToken: string;
  type: 'Bearer';
  expiredAt: string | string;
};
export type ValidationErrorSchema = {
  target?: object;
  property: string;
  value?: any;
  children?: object[];
  constraints: object;
};
export type UsersMetaInfo = {
  onboardingCompleted?: boolean;
  onboardingStepsCompleted?: number | null;
  metaRequest?: string;
  metaInsight?: string;
};
export type UsersLogins = {
  userId?: string;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
  type: 'plain' | 'phone' | 'email';
  value: string;
};
export type Files = {
  name: string;
  path: string;
  type: string;
  size: number;
  location?: string;
  s3?: boolean;
  blurhash?: string;
  s3Status?: 'falied' | 'received' | 'converting' | 'converted';
  height?: number;
  width?: number;
  relationType?: 'preview' | 'jpg' | 'crop';
  relationParams?: string;
  relationId?: string;
  userId?: string;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type Streams = {
  name: string;
  description?: string;
  defaultQuestId?: string;
  defaultAdventureId?: string;
  hidden?: boolean;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type GroupsStats = {
  subscribersCount: number;
  teammatesCount: number;
  goalsCount: number;
  goalsCompletedCount: number;
  publicationsCount: number;
  scoresCount: number;
  scoresPercent: number;
  likesRecievedCount: number;
  likesPercent: number;
  NPS: number;
  NPSRating: number;
  totalRating: number;
  updatedDate: string | string;
};
export type GroupsRatingPosition = {
  byNPS: number;
  totalPlaces: number;
  byTotal: number;
  bySummary: number;
  byMoney: number;
  byActivity: number;
  byGoals: number;
  updatedDate: string | string;
};
export type Groups = {
  ident?: number;
  name: string;
  short_description?: string;
  description?: string;
  tgUsername?: string;
  externalLink?: string;
  fileId?: string;
  adventureQuestId?: string;
  file?: Files;
  streamId?: string;
  subGroupsId?: string[];
  subGroups?: Groups[];
  stream?: Streams;
  requestsIsActive: boolean;
  membersLimit?: number;
  isDismissed?: boolean;
  dismissReason?: string;
  membersCount?: number;
  stats?: GroupsStats;
  ratingPosition?: GroupsRatingPosition;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type GroupsUsers = {
  title?: string;
  userId: string;
  user?: Users;
  groupId: string;
  group?: Groups;
  isLeader?: boolean;
  isCurator?: boolean;
  joinDate: string | string;
  exitDate: string | string;
  exitReason?: string;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type Tags = {
  name: string;
  type: 'default' | 'personal_quality' | 'hashtag' | 'quest' | 'user' | 'publication';
  taggedCount?: number;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type TagsSets = {
  tagId: string;
  tag?: Tags;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type UsersStatus = {
  isOnline: number;
  lastSeen: number;
  updatedDate: string | string;
};
export type UsersStats = {
  subscribersCount: number;
  subscribesCount: number;
  teammatesCount: number;
  goalsCount: number;
  goalsCompletedCount: number;
  commentsSentCount: number;
  commentsRecievedCount: number;
  tasksCount: number;
  jobsCount: number;
  jobsCompletedCount: number;
  publicationsCount: number;
  groupsId: string[];
  groups: Groups[];
  streamsId: string[];
  streams: Groups[];
  scoresCount: number;
  scoresPercent: number;
  likesRecievedCount: number;
  likesSentCount: number;
  reactionsSentCount: number;
  reactionsRecievedCount: number;
  likesPercent: number;
  NPS: number;
  NPSRating: number;
  totalRating: number;
  experience: number;
  experiencePower: number;
  updatedDate: string | string;
};
export type NotificationRestrict = {
  restrict: (
    | 'like'
    | 'score'
    | 'comment'
    | 'reaction'
    | 'score_reply'
    | 'comment_reply'
    | 'new_subscription'
    | 'new_task'
    | 'new_content'
    | 'new_quest'
    | 'new_achievement'
    | 'group_new_leader'
    | 'group_new_member'
    | 'group_leave_member'
    | 'group_new_request'
    | 'group_new_invite'
    | 'active_module'
    | 'active_module_last_call'
    | 'active_module_deadline'
    | 'active_task'
    | 'active_task_last_call'
    | 'active_task_deadline'
  )[];
};
export type UsersRatingPosition = {
  byNPS: number;
  byExperience: number;
  totalPlaces: number;
  byTotal: number;
  byMoney: number;
  byMoneyMonth?: number;
  byMoneyQuarter?: number;
  financialResult?: number;
  financialResultMonth?: number;
  financialResultQuarter?: number;
  financialGoal?: number;
  financialGoalMonth?: number;
  financialGoalQuarter?: number;
  byActivity?: number;
  byActivityMonth?: number;
  byActivityQuarter?: number;
  byGoals?: number;
  byGoalsMonth?: number;
  byGoalsQuarter?: number;
  totalGoals?: number;
  totalGoalsMonth?: number;
  totalGoalsQuarter?: number;
  updatedDate: string | string;
};
export type ClaimsStats = {
  count?: number;
  updatedDate?: string | string;
  isClaimed?: boolean;
  claimedDate?: string | string;
};
export type Users = {
  ident?: number;
  name: string;
  surname?: string;
  middlename?: string;
  disclaimer?: string;
  birthDate?: string | string;
  description?: string;
  gender?: 'male' | 'female' | 'not_sure';
  relationStatus?:
    | 'single'
    | 'in_relation'
    | 'engaged'
    | 'married'
    | 'civil_union'
    | 'in_love'
    | 'complicated'
    | 'in_search';
  city?: string;
  externalLink?: string;
  tgUsername?: string;
  fileId?: string;
  backgroundFileId?: string | null;
  metaInfo?: UsersMetaInfo | null;
  userLogin?: UsersLogins;
  logins?: UsersLogins[];
  groups?: GroupsUsers[];
  meIsSubscriber?: number;
  isMySubscriber?: number;
  file?: Files;
  tagsSet?: TagsSets[];
  status?: UsersStatus;
  stats?: UsersStats;
  notificationsSettings?: NotificationRestrict;
  ratingPosition?: UsersRatingPosition;
  claims?: ClaimsStats;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type InviteDataResponse = {
  user: Users;
  login: UsersLogins;
  isActiveUser: boolean;
};
export const {
  useRootLogoutMutation,
  useRootIndexQuery,
  useRootJsonDocsQuery,
  useRootDocsQuery,
  useRootSwaggerQuery,
  useAppTokensIndexQuery,
  useAppTokensAddMutation,
  useLoginRouteLoginMutation,
  useLoginRouteRequestCodeMutation,
  useLoginRouteConfirmCodeMutation,
  useLoginRouteInviteQuery,
} = api;
