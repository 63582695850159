import uniq from 'lodash/uniq';
import {Box, SxProps} from '@mui/system';

import {DataList} from 'components/DataList';
import {UsersIndexApiArg} from '@apiSchema/admin-api';

import {usersColumns} from './columns';

type UsersListProps = {
  sx?: SxProps;
  loading?: boolean;
  params: UsersIndexApiArg;
  onSelect?: (idArray: (string | number)[]) => void;
};

export const UsersList = ({params, sx, onSelect}: UsersListProps) => {
  const query: typeof params = {
    ...params,
    _id: params._id && !params._id.length ? undefined : params._id,
    skipId: params.skipId && !params.skipId.length ? undefined : params.skipId,
    populates: params.populates ? uniq([...params.populates, 'logins']) : ['logins'],
  };

  return (
    <Box sx={sx}>
      <DataList columns={usersColumns()} queryName="usersIndex" params={query} onSelect={onSelect} />
    </Box>
  );
};

export default UsersList;
