import {useCallback} from 'react';
import {pick} from 'lodash';

import {adminApi} from 'api';
import {TagDto, Tags} from '@apiSchema/admin-api';

import {initialValues} from './fields';

export const useCreateTag = (data?: Tags) => {
  const [update] = adminApi.endpoints.tagUpdate.useMutation();
  const [create] = adminApi.endpoints.tagsAdd.useMutation();
  const onSubmit = useCallback(
    (inputData: TagDto) => {
      if (data && data._id) return update({tagId: data._id, tagDto: inputData});
      else return create({tagDto: inputData});
    },
    [data, update, create]
  );

  const initials = data ? (pick(data, Object.keys(initialValues)) as TagDto) : initialValues;

  return {initials, onSubmit};
};

export default useCreateTag;
