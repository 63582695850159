import {useCallback} from 'react';
import {useAppDispatch} from 'store';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {pick} from 'lodash';

import {adminApi} from 'api';
import {GroupDto, Groups} from '@apiSchema/admin-api';

import {initialValues} from './fields';

const update = adminApi.endpoints.groupUpdate.initiate;
const create = adminApi.endpoints.groupsAdd.initiate;

export const useGroupCreateUpdate = (groupId?: string, onCreate?: (data: Groups) => void) => {
  const dispatch = useAppDispatch();
  const {data, isFetching} = adminApi.useGroupIndexQuery(groupId ? {groupId} : skipToken);
  const onSubmit = useCallback(
    async (inputData: GroupDto) => {
      let result;
      if (!inputData.fileId) inputData.fileId = undefined;
      if (!(inputData as any).adventureQuestId) (inputData as any).adventureQuestId = undefined;
      if (data) result = await dispatch(update({groupId: data._id as string, groupDto: inputData}));
      else {
        result = await dispatch(create({groupDto: inputData}));
        if (onCreate && !(result as any).error) onCreate((result as any).data as Groups);
      }
      return result;
    },
    [data, onCreate, dispatch]
  );

  const initials = data ? (pick(data, Object.keys(initialValues)) as GroupDto) : initialValues;

  return {initials, onSubmit, isFetching};
};

export default useGroupCreateUpdate;
