import {usersApi} from 'api';
import {format, parseISO} from 'date-fns';
import {skipToken} from '@reduxjs/toolkit/query';

import {Button, Card, CardContent, CardHeader, Stack, Typography} from '@mui/material';

import {getTaskPath} from 'modules/tasks/utils';
import {StatusLabel} from 'components/StatusLabel';
import {EntityPreview} from 'components/EntityPreview';

export const UserCardJobInfo = ({jobId}: {jobId: string}) => {
  const {data: job} = usersApi.endpoints.jobIndex.useQuery(jobId ? {jobId} : skipToken);
  if (!job) return null;
  const isDeleted = Boolean(job.deletedAt);
  const task = job?.task;

  const items = [
    {
      label: 'Дата создания',
      content: job.createdAt && format(parseISO(job.createdAt), 'd MMMM y'),
    },
    {
      label: 'Дата обновления',
      content: job.updatedAt && format(parseISO(job.updatedAt), 'd MMMM y'),
    },
    isDeleted
      ? {
          label: 'Удалено',
          content: job.deletedAt && format(parseISO(job.deletedAt), 'd MMMM y'),
        }
      : null,
    {
      label: 'Статус',
      content: <StatusLabel status={job.status} />,
    },
  ].filter(i => Boolean(i));
  return (
    <Card>
      <CardHeader title="Ответ на задание" />
      <CardContent>
        {task && (
          <EntityPreview p={1} noBorder title={task.name} to={getTaskPath(task._id)} fileId={task.filesId?.[0]} />
        )}
        <Stack spacing={2} mt={2}>
          {items.map(i => (
            <Stack key={i?.label} direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="body2" color="text.secondary">
                {i?.label}
              </Typography>
              <Typography variant="body2">{i?.content}</Typography>
            </Stack>
          ))}
        </Stack>
        {isDeleted && (
          <Stack alignItems="end" pt={3} mt={1.75} sx={{borderTop: '1px solid', borderColor: 'background.neutral'}}>
            <Button variant="contained" sx={{width: 'fit-content'}}>
              Восстановить
            </Button>
          </Stack>
        )}
      </CardContent>
    </Card>
  );
};
