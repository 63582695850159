import {useCallback} from 'react';
import {useFormContext, FieldValues} from 'react-hook-form';
import {keys, isNil} from 'lodash';
import {useAtomCallback} from 'jotai/utils';

import {Tooltip, Box, Stack} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {Icon} from '@iconify/react';
import saveIcon from '@iconify/icons-eva/save-outline';
import refreshIcon from '@iconify/icons-eva/refresh-outline';

import {FormButtonsProps, FormSettings} from './types';
import {defaultValuesAtom} from './utils';

const FormButton = <T extends FieldValues>({
  text,
  onClick,
  type,
  icon,
  color,
  disabled,
  size = 'medium',
  display = 'standard',
  variant = 'contained',
}: FormButtonsProps<T>) => {
  const {getValues, reset, formState: state} = useFormContext();
  let isDisabled;
  const loading = type === 'submit' && state.isSubmitting;

  if (!isNil(disabled)) {
    if (typeof disabled === 'boolean') isDisabled = disabled;
    else isDisabled = disabled(state as any);
  } else {
    const submitDisabled = type === 'submit' && !!keys(state.errors).length;
    const resetDisabled = type === 'reset' && !state.isDirty && !onClick;
    isDisabled = state.isSubmitting || submitDisabled || resetDisabled;
  }

  const clickHandler = useAtomCallback(
    useCallback(
      get => {
        if (type === 'reset') reset(get(defaultValuesAtom));
        if (onClick) onClick(getValues() as T, state as any);
      },
      [type, onClick, state, getValues, reset]
    )
  );

  if (display === 'icon' && icon)
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Tooltip title={text}>
          <span>
            <LoadingButton
              size={size}
              type={type}
              disabled={isDisabled}
              onClick={clickHandler}
              color={color}
              loading={loading}
              sx={{p: 1, minWidth: 0, width: 40, height: 40, borderRadius: '50%'}}
            >
              <Box component={Icon} icon={icon} width={24} height={24} />
            </LoadingButton>
          </span>
        </Tooltip>
      </Box>
    );

  return (
    <LoadingButton
      size={size}
      type={type}
      color={color}
      variant={variant}
      startIcon={icon ? <Box component={Icon} icon={icon} width={24} height={24} /> : undefined}
      disabled={isDisabled}
      onClick={clickHandler}
      loading={loading}
    >
      {text}
    </LoadingButton>
  );
};

export const Buttons = <T extends FieldValues>({
  onReset,
  iconButtons,
  buttonsStructure = [
    {
      display: iconButtons ? 'icon' : 'standard',
      text: 'Сохранить',
      icon: saveIcon,
      type: 'submit',
    },
    {
      display: iconButtons ? 'icon' : 'standard',
      text: 'Отменить',
      icon: refreshIcon,
      type: 'reset',
      variant: 'text',
      color: 'error',
      onClick: onReset,
    },
  ],
}: Pick<FormSettings<T>, 'buttonsStructure' | 'onReset' | 'iconButtons'>) => {
  const buttonsOnly = buttonsStructure.filter(Boolean) as FormButtonsProps<T>[];
  return (
    <Stack direction="row" spacing={1} flexShrink={1}>
      {buttonsOnly.map(item => (
        <FormButton key={JSON.stringify(item)} {...item} />
      ))}
    </Stack>
  );
};
