import {Stack, Box} from '@mui/material';

export const ListPosition = ({index}: {index: number}) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      color="text.secondary"
      fontSize={12}
      lineHeight="12px"
      height={20}
      width={20}
      borderRadius="10px"
      border="1px solid"
      borderColor="rgba(145, 158, 171, 0.24)"
    >
      <Box>{index + 1}</Box>
    </Stack>
  );
};
