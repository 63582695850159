import {ru} from 'date-fns/locale';
import {format, parseISO} from 'date-fns';

import {FC} from 'react';
import {Avatar, Box, Button, Card, CardContent, Stack, Typography} from '@mui/material';

import {adminApi} from 'api';
import {croppedImagePath} from 'utils';
import {getUserName} from 'modules/users';
import Markdown from '__theme/components/Markdown';
import createAvatar from '__theme/utils/createAvatar';

import {CommentIdProp} from './types';
import {scoreOptions, useEditCommentId} from './utils';
import {CommentDeleteRestore} from './CommentDeleteRestore';

export const Comment: FC<CommentIdProp> = ({commentId}) => {
  const {data} = adminApi.endpoints.commentIndex.useQuery({commentId});
  const [, setEditCommentId] = useEditCommentId();

  if (!data) return null;
  const user = data.comment.user!;
  if (!user) return null;
  const scoreValue = typeof data.comment.scoreInfo?.value === 'number' ? data.comment.scoreInfo.value : undefined;

  return (
    <>
      <Card>
        <CardContent>
          <Stack spacing={2}>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
              <Stack direction="row" spacing={1} alignItems="center">
                <Box
                  width={40}
                  height={40}
                  component={Avatar}
                  color={user.fileId ? 'default' : createAvatar(user.name || '').color}
                  src={user.fileId ? croppedImagePath(user.fileId, {ratio: '1:1', maxWidth: 32}) : undefined}
                  border="2px solid"
                  borderColor="background.default"
                />
                <Box>
                  <Typography variant="body2" fontWeight={400}>
                    {getUserName(user)}
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    {data.comment.deletedAt && (
                      <Typography variant="body2" color="error.light">
                        Комментарий удален
                      </Typography>
                    )}
                    {data.comment.createdAt && (
                      <Typography variant="body2" color="grey.400">
                        {format(parseISO(data.comment.createdAt), 'PP', {locale: ru})}
                      </Typography>
                    )}
                    {scoreValue && (
                      <Typography variant="body2" color="grey.400">
                        {`Оценка: ${scoreOptions.find(item => item.value === scoreValue)?.name || scoreValue}`}
                      </Typography>
                    )}
                  </Stack>
                </Box>
              </Stack>
              <Stack direction="row" spacing={0.5}></Stack>
            </Stack>
            <Markdown children={data.comment.content} />
            <Stack direction="row" spacing={1} alignSelf="flex-end">
              <Button onClick={() => setEditCommentId(commentId)} size="small" variant="contained" color="info">
                Ответить
              </Button>
              <CommentDeleteRestore commentId={commentId} />
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};
