import {lazy, Suspense} from 'react';
import {Navigate, Outlet, RouteObject, useParams} from 'react-router';
import {skipToken} from '@reduxjs/toolkit/dist/query';

import Grid from '@mui/material/Grid';
import {PATH_APP} from 'router/routes';
import {adminApi} from 'api';
import {Mailings, Users} from '@apiSchema/admin-api';

import groupRoute from 'modules/groups';
import streamsRoute from 'modules/streams';
import {AccessGuard} from 'modules/auth/containers';
import {UserCardLayout} from 'modules/users/containers';

import IsExistsContainer from 'components/IsExistsContainer';
import {BreadcrumbsProvider} from 'components/Breadcrumbs';
import {BreadcrumbsSet} from 'components/Breadcrumbs/BreadcrumbsSet';
import {useGetMailing} from './pages/MailingPage/hook';
import {getUserPath} from './utils';
import {postRoute, PublicationsList} from 'modules/publications';

import StickyBox from 'components/StickyBox/StickyBox';
import {APPBAR_DESKTOP} from '__theme/layouts/dashboard/DashboardNavbar';

const UserCardInfo = lazy(() => import('./pages/UserCardInfo'));

const UsersList = lazy(() => import('./pages/UsersList'));
const StagePage = lazy(() => import('./pages/StagePage'));
const MailingPage = lazy(() => import('./pages/MailingPage'));
const MailingsList = lazy(() => import('./pages/MailingsList'));

const useGetUser = () => {
  const {userId} = useParams<'userId'>();
  return adminApi.useUserIndexQuery(userId ? {userId} : skipToken);
};

const usersRouter: RouteObject = {
  path: 'list',
  element: (
    <AccessGuard endpoint="usersIndex">
      <BreadcrumbsSet name="Список пользователей" href={PATH_APP.user.list.root} />
    </AccessGuard>
  ),
  children: [
    {index: true, element: <UsersList />},
    {
      path: ':userId',
      element: (
        <AccessGuard endpoint="userIndex">
          <IsExistsContainer<Users> useFetch={useGetUser}>
            {user => (
              <BreadcrumbsSet name={`${user.name || ''} ${user.surname || ''}`} href={getUserPath(user._id!)}>
                <UserCardLayout />
              </BreadcrumbsSet>
            )}
          </IsExistsContainer>
        </AccessGuard>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={<></>}>
              <UserCardInfo />
            </Suspense>
          ),
        },
        {
          path: 'posts',
          element: (
            <BreadcrumbsSet name="Публикации пользователя">
              <IsExistsContainer<Users> useFetch={useGetUser}>
                {user => (
                  <Grid container columnSpacing={2}>
                    <Grid item md={4}>
                      <PublicationsList externalParams={{userId: user._id!}} />
                    </Grid>
                    <Grid item md={8}>
                      <StickyBox offsetTop={APPBAR_DESKTOP} offsetBottom={20}>
                        <Outlet />
                      </StickyBox>
                    </Grid>
                  </Grid>
                )}
              </IsExistsContainer>
            </BreadcrumbsSet>
          ),
          children: [postRoute],
        },
        {
          path: 'stages/:stageId',
          element: (
            <BreadcrumbsSet name="Выполнение квестов">
              <Suspense fallback={<></>}>
                <StagePage />
              </Suspense>
            </BreadcrumbsSet>
          ),
        },
      ],
    },
  ],
};

const router: RouteObject = {
  path: 'users',
  element: <BreadcrumbsProvider name="Пользователи" href={PATH_APP.user.root} />,
  children: [
    {index: true, element: <Navigate to="./list" replace />},
    streamsRoute,
    groupRoute,
    usersRouter,
    {
      path: 'mailings',
      element: (
        <AccessGuard endpoint="mailingsIndex">
          <BreadcrumbsSet name="Рассылки" href={PATH_APP.user.mailings.root} />
        </AccessGuard>
      ),
      children: [
        {
          index: true,
          element: <MailingsList />,
        },
        {
          path: 'create',
          element: (
            <AccessGuard endpoint="mailingsAdd">
              <BreadcrumbsSet name="Новая рассылка">
                <MailingPage />
              </BreadcrumbsSet>
            </AccessGuard>
          ),
        },
        {
          path: ':mailingId',
          element: (
            <AccessGuard endpoint="mailingIndex">
              <IsExistsContainer<Mailings> useFetch={useGetMailing}>
                {mailing => <BreadcrumbsSet name={mailing.name} />}
              </IsExistsContainer>
            </AccessGuard>
          ),
          children: [{index: true, element: <MailingPage />}],
        },
      ],
    },
  ],
};

export default router;
