import {Groups} from '@apiSchema/admin-api';
import {Avatar} from '@mui/material';
import {GridRenderCellParams} from '@mui/x-data-grid';
import {croppedImagePath} from 'utils';

export const renderGroupAvatar = ({row}: GridRenderCellParams<any, Groups>) => (
  <Avatar
    src={row.fileId ? croppedImagePath(row.fileId, {ratio: '1:1', maxWidth: 32}) : undefined}
    sx={{width: 24, height: 24, bgcolor: 'background.neutral'}}
  >
    {row.name[0]}
  </Avatar>
);
