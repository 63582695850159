import {UsersLogins} from '@apiSchema/admin-api';
import {enchanceColumns} from 'utils';

export const userLoginsColumns = enchanceColumns<UsersLogins>([
  {
    field: 'type',
    headerName: 'Тип',
    type: 'string',
    valueFormatter: ({value}) => {
      switch (value) {
        case 'plain':
          return 'Логин';
        case 'email':
          return 'E-mail';
        case 'phone':
          return 'Телефон';
      }
    },
    width: 150,
  },
  {
    field: 'value',
    headerName: 'Значение',
    type: 'string',
    flex: 0.75,
  },
  {
    field: '_id',
    headerName: 'ID',
    type: 'string',
    width: 200,
    hide: true,
  },
]);
