import {forwardRef, useMemo} from 'react';
import {formatISO, parseISO} from 'date-fns';
import {useController} from 'react-hook-form';

import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import TextField, {TextFieldProps} from '@mui/material/TextField';

import {CommonFieldProps} from '../types';
import {DateTimeSelectProps} from './types';

export const DateTimeSelect = ({label, name, onlyDate, onlyTime, disabled}: DateTimeSelectProps & CommonFieldProps) => {
  const {
    field: {onChange, value, ...field},
    fieldState,
  } = useController({name});
  const DateTextField = useMemo(
    () =>
      forwardRef<any>((props: TextFieldProps, ref) => (
        <TextField {...props} error={Boolean(fieldState.error)} ref={ref} />
      )),
    [fieldState.error]
  );

  const commonProps = {
    label,
    disabled,
    ...field,
    onChange: (date: Date | null) => onChange(date ? formatISO(date) : ''),
    defaultValue: value ? parseISO(value) : undefined,
    slots: {
      textField: DateTextField,
    },
  };

  if (onlyTime) return <TimePicker {...commonProps} />;
  if (onlyDate) return <DatePicker {...commonProps} />;
  return <DateTimePicker {...commonProps} />;
};
