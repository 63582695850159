import _ from 'lodash';
import {FC, useContext} from 'react';
import {Card, CardContent} from '@mui/material';

import {adminApi, usersApi} from 'api';
import {yupNumber, yupObject, yupStringRequired} from 'utils/validation';

import {Form} from 'components/Form';
import {CommentIdProp} from './types';
import {CommentsContext, scoreOptions, useEditCommentId} from './utils';
import {skipToken} from '@reduxjs/toolkit/query';

export const CommentReply: FC<Partial<CommentIdProp & {label: string}>> = ({
  commentId,
  label = 'Ваш ответ на комментарий',
}) => {
  const [, setEditCommentId] = useEditCommentId();
  const [commentsAdd] = usersApi.endpoints.commentsAdd.useMutation();
  const {reference, canScoreReference} = useContext(CommentsContext);
  const {canScore} = adminApi.endpoints.commentIndex.useQuery(commentId ? {commentId} : skipToken, {
    selectFromResult: ({data}) => ({
      canScore: !data?.comment.score?.scoresId?.length,
    }),
  });
  const closeCommentForm = () => setEditCommentId(null);

  if (!reference) return null;
  return (
    <Card>
      <CardContent>
        <Form
          fields={[
            {
              name: 'comment',
              type: 'text',
              variant: 'text',
              label,
              minRows: 2,
            },
            {
              showIf: _.isUndefined(canScore) ? canScoreReference : canScore,
              name: 'score',
              type: 'select',
              variant: 'chips',
              options: scoreOptions,
              label: 'Ваша оценка',
            },
          ]}
          initials={{comment: '', score: undefined}}
          onReset={closeCommentForm}
          onSubmit={data =>
            commentsAdd({
              ...reference,
              addCommentDto: {
                commentId,
                score: data.score,
                comment: data.comment,
              },
            }).then(closeCommentForm)
          }
          validation={yupObject({
            comment: yupStringRequired,
            scofre: yupNumber,
          })}
        />
      </CardContent>
    </Card>
  );
};
