import {pick} from 'lodash';
import {useCallback} from 'react';
import {useNavigate} from 'react-router';

import {adminApi} from 'api';
import {Widgets, WidgetDto} from '@apiSchema/admin-api';

import {initialValues} from './fields';

export const useCreateWidget = (data?: Widgets, onSuccess?: (widgetId: string) => void) => {
  const navigate = useNavigate();
  const [update] = adminApi.endpoints.widgetUpdate.useMutation();
  const [create] = adminApi.endpoints.widgetsAdd.useMutation();

  const onSubmit = useCallback(
    async (widgetDto: WidgetDto) => {
      let result;
      if (data && data._id) result = await update({widgetId: `${data._id}`, widgetDto});
      else result = await create({widgetDto});
      if ('data' in result && result.data._id) {
        if (onSuccess) onSuccess(result.data._id);
        else if (!data) navigate(`./${result.data._id}`);
      }
      return result;
    },
    [data, update, create, navigate, onSuccess]
  );

  const initials = data ? (pick(data, Object.keys(initialValues)) as WidgetDto) : initialValues;

  return {initials, onSubmit};
};
