import {PATH_APP} from 'router/routes';
import {BooleanParam, useQueryParam} from 'use-query-params';

export enum WidgetContainers {
  USER_INFO = 'userInfo',
  USER_STATS = 'userStats',
  PUBLICATION = 'publication',
  STREAM_STATS = 'streamStats',
  USER_MONEY_WIDGET = 'userMoneyWidget',
}

const widgetContainersNames: Record<WidgetContainers, string> = {
  streamStats: 'Статистика потока',
  publication: 'Публикация (приложение)',
  userMoneyWidget: 'График денег пользователя (приложение)',
  userStats: 'Статистика пользователя (дашборд)',
  userInfo: 'Информация о пользователе (дашборд)',
};

export const widgetContainersOptions = Object.values(WidgetContainers).map(value => ({
  value,
  name: widgetContainersNames[value],
}));

export const getVisualPath = (entryId?: string) => `${PATH_APP.tasks.widgetsVisuals.list}/${entryId}`;
export const getWidgetPath = (entryId?: string) => `${PATH_APP.tasks.widgets.list}/${entryId}`;

//

export const useAttributeDeletedParam = () => useQueryParam('showDeleted', BooleanParam);
