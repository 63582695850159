import {Alert} from '@mui/material';
import {skipToken} from '@reduxjs/toolkit/query';
import {adminApi} from 'api';
import {QuestProgressStats} from 'modules/events/containers';
import {GroupIdProp} from 'modules/groups/types';
import {getGroupsIdForGroup} from 'modules/groups/utils';
import {getStreamPath} from 'modules/streams/utils';
import {UserIdProp} from 'modules/users';
import {FC} from 'react';
import {Link} from 'react-router-dom';

export const GroupQuestStats: FC<GroupIdProp & Partial<UserIdProp>> = ({groupId, userId}) => {
  const {data: group} = adminApi.endpoints.groupIndex.useQuery({groupId});
  const {data: stream} = adminApi.endpoints.streamIndex.useQuery(
    group?.streamId ? {streamId: group.streamId} : skipToken
  );

  if (!group) return null;
  if (stream?.defaultAdventureId)
    return (
      <QuestProgressStats
        questId={[stream.defaultAdventureId]}
        groupId={getGroupsIdForGroup(group)}
        userId={userId ? [userId] : undefined}
      />
    );
  if (!stream?.defaultAdventureId)
    return (
      <Alert>
        Для отображения статистики прохождения программы группой в{' '}
        <Link to={getStreamPath(group.streamId)}>настройках потока</Link> необходимо указать основной квест.
      </Alert>
    );
  if (!group?.streamId)
    return (
      <Alert>
        Для отображения статистики прохождения программы группой в настройках группы необходимо указать поток, к
        которому принадлежит группа.
      </Alert>
    );
  return null;
};
