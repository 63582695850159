import {$insertNodeToNearestRoot, mergeRegister} from '@lexical/utils';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {$getNodeByKey, COMMAND_PRIORITY_LOW, NodeKey, createCommand} from 'lexical';
import {useEffect} from 'react';
import {$createVideoNode, $isVideoNode, VideoNode, VideoPayload} from './VideoNode';

export const INSERT_VIDEO_COMMAND = createCommand('INSERT_VIDEO_COMMAND');
export const REMOVE_VIDEO_COMMAND = createCommand<NodeKey>('REMOVE_VIDEO_COMMAND');
export const UPDATE_VIMEO_VIDEO_COMMAND = createCommand<Required<VideoPayload>>('UPDATE_VIMEO_VIDEO_COMMAND');

export const VideoPlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([VideoNode])) {
      throw new Error('VideoPlugin: VideoNode not registered on editor');
    }

    return mergeRegister(
      editor.registerCommand(
        INSERT_VIDEO_COMMAND,
        () => {
          const node = $createVideoNode({});
          $insertNodeToNearestRoot(node);
          return true;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        UPDATE_VIMEO_VIDEO_COMMAND,
        ({url, fileId, title, key}) => {
          const node = $getNodeByKey(key);
          if (!$isVideoNode(node)) return false;
          if (title) node.setTitle(title);
          if (fileId) node.setFileId(fileId);
          else if (url) node.setUrl(url);
          return true;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        REMOVE_VIDEO_COMMAND,
        key => {
          const node = $getNodeByKey(key);
          if (!$isVideoNode(node)) return false;
          node.remove();
          return true;
        },
        COMMAND_PRIORITY_LOW
      )
    );
  }, [editor]);

  return null;
};
