/* eslint-disable react-hooks/exhaustive-deps */
import {skipToken} from '@reduxjs/toolkit/dist/query';

import {adminApi} from 'api';
import {Form} from 'components/Form';

import {useCreateQuest} from './hook';
import {QuestFormProps} from './types';
import {fields, validation} from './fields';

export const QuestForm = ({questId, parentQuestId, onReset, onSuccess}: QuestFormProps) => {
  const {data} = adminApi.endpoints.questIndex.useQuery(questId ? {questId} : skipToken);
  const {onSubmit, initials} = useCreateQuest(data, parentQuestId, onSuccess);
  return (
    <Form fields={fields(questId)} onSubmit={onSubmit} validation={validation} onReset={onReset} initials={initials} />
  );
};
