import {lazy} from 'react';
import {Navigate, RouteObject} from 'react-router';

import {BreadcrumbsProvider} from 'components/Breadcrumbs';
import {BreadcrumbsSet} from 'components/Breadcrumbs/BreadcrumbsSet';
import {PATH_APP} from 'router/routes';

const ClaimsList = lazy(() => import('./pages/ClaimsList'));
const ClaimDetailsPage = lazy(() => import('./pages/ClaimDetails'));

export const routes: RouteObject = {
  path: 'control',
  element: <BreadcrumbsProvider name="Модерация" href={PATH_APP.control.root} />,
  children: [
    {index: true, element: <Navigate to="./claims" replace />},
    {
      path: 'claims',
      element: <BreadcrumbsSet name="Жалобы" href={PATH_APP.control.claims} />,
      children: [
        {index: true, element: <ClaimsList />},
        {
          path: ':referenceName',
          children: [
            {
              index: true,
              element: <Navigate to="../" replace />,
            },
            {
              path: ':referenceId',
              element: (
                <BreadcrumbsSet name="Жалоба">
                  <ClaimDetailsPage />
                </BreadcrumbsSet>
              ),
            },
          ],
        },
      ],
    },
  ],
};

export default routes;
