import {useCallback} from 'react';
import {useAppDispatch} from 'store';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import CardContent from '@mui/material/CardContent';

import {adminApi} from 'api';
import {QuestTimelinePartialDto} from '@apiSchema/admin-api';

import {Timeline} from './Timeline';
import {QuestPointTimelineProps} from './types';

const updateSetting = adminApi.endpoints.questPointTimelineElemPatch.initiate;

export const QuestPointTimeline = ({pointId, questId}: QuestPointTimelineProps) => {
  const {data: settings} = adminApi.endpoints.questPointsTimelinesIndex.useQuery({pointId});
  const dispatch = useAppDispatch();
  const onSave = useCallback(
    async (data: QuestTimelinePartialDto, timelineId: string) =>
      dispatch(
        updateSetting({
          pointId,
          questPointTimelinePartialDto: {
            ...data,
            dateStart: data.dateStart || null,
            dateFinish: data.dateFinish || null,
          },
          timelineId,
        })
      ),
    [dispatch, pointId]
  );

  return (
    <Card>
      <CardContent>
        <Stack spacing={3}>
          {(settings || []).map(item => (
            <Timeline key={`${item._id}`} onSave={onSave} data={item} questId={questId} isQuestPoint />
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default QuestPointTimeline;
