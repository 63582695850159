import _ from 'lodash';
import numeral from 'numeral';

import {FC} from 'react';
import ReactApexChart from 'react-apexcharts';

import {usersApi} from 'api';
import {WidgetInfoApiArg} from '@apiSchema/users-api';
import {BaseOptionChart} from '__theme/components/charts';
import {parseISO} from 'date-fns';

export const ChartWidget: FC<WidgetInfoApiArg> = props => {
  const {data} = usersApi.endpoints.widgetInfo.useQuery(props, {
    selectFromResult: ({data}) => ({
      data: data?.attrs.map(attr => {
        if (['delta', 'increment'].includes(attr.formula)) return undefined;
        const values = data.values.map(({values, ...widgetValue}) => {
          const rawValues = _.flatten(
            values
              .filter(
                attrValue =>
                  ['score', 'number', 'select'].includes(attrValue.type) && attrValue.widgetAttrId === attr._id!
              )
              .map(({type, values}) => {
                if (type === 'number') return values.map(item => Number(item.value));
                if (type === 'score')
                  return _.flatten(values.map(item => item.value.split(';').map(Number).filter(_.isNumber)));
                if (type === 'select')
                  return _.flatten(values.map(item => item.value.split(';').map(item => (item ? 1 : 0))));
                return undefined;
              })
              .filter(item => typeof item !== 'undefined')
          ) as number[];

          let value: number = 0;
          switch (attr.formula) {
            case 'sum':
            case 'list':
              value = _.sum(rawValues);
              break;
            case 'avg':
              value = _.mean(rawValues);
              break;
            case 'max':
              value = _.max(rawValues) || 0;
              break;
            case 'min':
              value = _.min(rawValues) || 0;
              break;
            case 'first_value':
              value = rawValues[0] || 0;
              break;
            case 'last_value':
              value = rawValues[rawValues.length - 1] || 0;
              break;
            default:
              value = 0;
              break;
          }

          const season = data.seasons.find(item => item._id === widgetValue.seasonQuestId);
          return {
            value,
            date: season && season.createdAt ? parseISO(season.createdAt) : undefined,
            key: season?.name || 'Вне модуля',
          };
        });

        return {
          name: attr.name,
          values: _.sortBy(values, 'date'),
        };
      }),
    }),
  });

  const options: ApexCharts.ApexOptions = _.merge(BaseOptionChart(), {
    chart: {
      type: 'bar',
      height: 500,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: data?.[0]?.values.map(item => item.key) || [],
    },
    yaxis: {
      decimalsInFloat: 0,
      labels: {
        formatter: (val: number) => numeral(val.toFixed(0)).format(),
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: (val: number) => numeral(val.toFixed(0)).format(),
      },
    },
  });

  if (!data) return null;

  const series = (data || []).filter(Boolean).map(item => {
    if (typeof item === 'undefined') throw new Error('error');
    return {name: item.name, data: item.values.map(value => value.value)};
  });

  return <ReactApexChart type="bar" height={400} options={options} series={series} />;
};
