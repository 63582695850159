import {FC} from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import {Icon} from '@iconify/react';
import openInNew from '@iconify/icons-material-symbols/open-in-new';

import {croppedImagePath} from 'utils';
import {UserIdProp} from 'modules/users';
import {Groups} from '@apiSchema/admin-api';
import {getGroupPath} from 'modules/groups/utils';

const DefaultAction: FC<UserIdProp> = ({userId}) => (
  <IconButton size="small" href={getGroupPath(userId)} target="_blank">
    <Icon icon={openInNew} />
  </IconButton>
);

export const GroupItem = ({group, Action}: {group: Groups; Action?: JSX.Element}) => (
  <Stack direction="row" alignItems="flex-start" justifyContent="space-between" spacing={2}>
    <Stack direction="row" alignItems="flex-start" spacing={2}>
      <Avatar
        sx={{width: 44, height: 44}}
        src={group.fileId && croppedImagePath(group.fileId, {ratio: '1:1', maxWidth: 44})}
      >
        {group.name[0]}
      </Avatar>
      <Stack alignSelf="center">
        <Typography variant="subtitle2">{group.name}</Typography>
        {group.description && (
          <Typography variant="body2" color="grey.600">
            {group.description}
          </Typography>
        )}
      </Stack>
    </Stack>
    <Box height={44} display="flex" alignItems="center">
      {Action ? Action : <DefaultAction userId={group._id!} />}
    </Box>
  </Stack>
);
