import {lazy} from 'react';
import {Navigate, RouteObject, useParams} from 'react-router';
import {skipToken} from '@reduxjs/toolkit/dist/query';

import {adminApi} from 'api';
import {PATH_APP} from 'router/routes';

import {AccessGuard} from 'modules/auth/containers';
import IsExistsContainer from 'components/IsExistsContainer';
import {BreadcrumbsSet} from 'components/Breadcrumbs/BreadcrumbsSet';
import {Streams} from '@apiSchema/admin-api';

const StreamsListPage = lazy(() => import('./pages/StreamsList'));
const StreamFormPage = lazy(() => import('./pages/StreamForm'));
const useGetStream = () => {
  const {streamId} = useParams<'streamId'>();
  return adminApi.useStreamIndexQuery(streamId ? {streamId} : skipToken);
};

const router: RouteObject = {
  path: 'streams',
  element: (
    <AccessGuard endpoint="streamsIndex">
      <BreadcrumbsSet name="Потоки" href={PATH_APP.streams.list} />
    </AccessGuard>
  ),
  children: [
    {index: true, element: <Navigate to="./list" replace />},
    {path: 'list', element: <StreamsListPage />},
    {
      path: 'create',
      element: (
        <AccessGuard endpoint="streamsAdd">
          <BreadcrumbsSet name="Новый поток">
            <StreamFormPage />
          </BreadcrumbsSet>
        </AccessGuard>
      ),
    },
    {
      path: ':streamId',
      element: (
        <AccessGuard endpoint="streamIndex">
          <IsExistsContainer<Streams> useFetch={useGetStream}>
            {stream => <BreadcrumbsSet name={stream.name} />}
          </IsExistsContainer>
        </AccessGuard>
      ),
      children: [{index: true, element: <StreamFormPage />}],
    },
  ],
};

export default router;
