/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback} from 'react';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {BooleanParam, StringParam, useQueryParams} from 'use-query-params';
import {LinearProgress, Dialog, DialogTitle, DialogContent} from '@mui/material';

import {adminApi} from 'api';
import {Users} from '@apiSchema/admin-api';
import {Form} from 'components/Form';

import {fields, validation} from './fields';
import {useCreateTag} from './hook';

const queryShape = {createTag: BooleanParam, editTagId: StringParam};

export type TagFormProps = {
  tagId?: string;
  onReset?: () => void;
  onCreate?: (data: Users) => void;
};
type TagDialogProps = Pick<TagFormProps, 'onCreate'>;

export const TagForm = ({tagId, onReset}: TagFormProps) => {
  const {data, isFetching} = adminApi.endpoints.tagIndex.useQuery(tagId ? {tagId} : skipToken);
  const {onSubmit, initials} = useCreateTag(data);

  if (isFetching && !data) return <LinearProgress />;

  return <Form fields={fields} initials={initials} validation={validation} onSubmit={onSubmit} onReset={onReset} />;
};

export const useTagForm = (): [(props: TagDialogProps) => JSX.Element, () => void, (userId: string) => void] => {
  const [{editTagId, createTag}, setQuery] = useQueryParams(queryShape);
  const open = useCallback(() => setQuery({createTag: true}, 'replace'), []);
  const edit = useCallback((userId: string) => setQuery({editTagId: userId}, 'replace'), []);
  const close = useCallback(() => setQuery({}, 'replace'), []);

  const FormContainer = useCallback(
    (props: TagDialogProps) => (
      <Dialog open={Boolean(createTag) || Boolean(editTagId)} maxWidth="sm" fullWidth onClose={close}>
        <DialogTitle>{editTagId ? 'Изменить' : 'Добавить'} категорию цели</DialogTitle>
        <DialogContent dividers>
          <TagForm tagId={editTagId as string | undefined} onReset={close} {...props} />
        </DialogContent>
      </Dialog>
    ),
    [createTag, editTagId, close]
  );

  return [FormContainer, open, edit];
};

export default TagForm;
