import {memo} from 'react';
import {Stack, Radio, FormControlLabel} from '@mui/material';

import {Templates} from '@apiSchema/users-api';
import {AnswerProps} from '../types';
import {useMultiFieldProps} from './hooks';

export const RadioInput = ({
  scriptActionId,
  template,
  index,
}: Omit<AnswerProps, 'templateOptionId'> & {template: Templates}) => {
  const {options, value, field, setData} = useMultiFieldProps(scriptActionId, `${template._id}`, index);

  return (
    <Stack>
      {options.map(option => {
        const optionId = `${option._id}`;
        const checked = value.includes(optionId);
        return (
          <FormControlLabel
            key={optionId}
            label={option.name}
            labelPlacement="end"
            control={
              <Radio
                size="small"
                value={optionId}
                name={`${field.name}.${optionId}`}
                checked={checked}
                onChange={() => setData([optionId])}
              />
            }
            inputRef={field.ref}
          />
        );
      })}
    </Stack>
  );
};

export default memo(RadioInput);
