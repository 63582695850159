import {adminApi} from 'api';
import {QuestPointDto} from '@apiSchema/admin-api';

import {Form} from 'components/Form';
import {fields, validationSchema} from './fields';
import {FC, useMemo} from 'react';
import {QuestPointIdProp} from 'modules/events/types';

export const QuestPointOptionsForm: FC<QuestPointIdProp> = ({pointId}) => {
  const {initials, type} = adminApi.endpoints.questPointIndex.useQuery(
    {pointId},
    {
      selectFromResult: ({data: point}) => ({
        type: point?.type,
        initials: point
          ? {
              type: point?.type,
              options: {
                ...(point.options || {}),
                isRequired: typeof point?.options.isRequired === 'undefined' ? true : point.options.isRequired,
                autoAccept: point.options.autoAccept || false,
                notInProgress: point.options.notInProgress || false,
                design: point.options.design,
                quests: point.options.quests,
                buttons: point.options.buttons,
                publications: point.options.publications,
              },
              name: point.name || '',
            }
          : undefined,
      }),
    }
  );

  const realFields = useMemo(() => (type ? fields(type) : undefined), [type]);
  const realValidation = useMemo(() => (type ? validationSchema(type) : undefined), [type]);

  const [patchPoint] = adminApi.endpoints.questPointPatch.useMutation();
  const onSubmit = (data: typeof initials & Pick<QuestPointDto['options'], 'triggers'>) =>
    patchPoint({
      pointId,
      questPointPartialDto: {name: data.name, options: data.options},
    });
  if (!initials || !type || !realFields || !realValidation) return null;
  return <Form initials={initials} onSubmit={onSubmit} fields={realFields} validation={realValidation} />;
};
