import {FC} from 'react';
import Button from '@mui/material/Button';

import {adminApi} from 'api';
import {useConfirmDialog} from 'components/Dialog';

import {CommentIdProp} from './types';

export const CommentDeleteRestore: FC<CommentIdProp> = ({commentId}) => {
  const [safeDelete] = adminApi.endpoints.commentSafeDelete.useMutation();
  const [safeRestore] = adminApi.endpoints.commentSafeRestore.useMutation();
  const {isDeleted} = adminApi.endpoints.commentIndex.useQuery(
    {commentId},
    {
      selectFromResult: ({data}) => ({
        isDeleted: data ? Boolean(data.comment.deletedAt) : undefined,
      }),
    }
  );

  const [ConfirmDialog, onRemoveWithConfirm] = useConfirmDialog({
    text: isDeleted
      ? 'Вы уверены что хотите восстановить публикацию?'
      : 'Вы уверены что хотите удалить публикацию? Она будет скрыта из приложения.',
    onConfirm: () => {
      if (typeof isDeleted !== 'boolean') return;
      if (isDeleted) return safeRestore({commentId});
      return safeDelete({commentId});
    },
  });

  if (typeof isDeleted === 'undefined') return null;
  return (
    <>
      <ConfirmDialog />
      <Button size="small" onClick={onRemoveWithConfirm} variant="contained" color={isDeleted ? 'info' : 'error'}>
        {isDeleted ? 'Восстановить' : 'Удалить'}
      </Button>
    </>
  );
};
