import {adminApi} from 'api';
import {
  yupBooleanRequired,
  yupNumberOrEmptyStringPositive,
  yupObject,
  yupString,
  yupStringRequired,
} from 'utils/validation';
import {FieldStructure} from 'components/Form/types';

const getStreamsOptionsHook = () => {
  const {data} = adminApi.useStreamsIndexQuery({limit: 10000});
  return (data?.data || []).map(item => ({value: item._id!, name: item.name}));
};

export const fields: FieldStructure[] = [
  {
    type: 'text',
    name: 'name',
    label: 'Название',
  },
  {
    type: 'text',
    variant: 'text',
    name: 'description',
    label: 'Описание',
  },
  {
    type: 'text',
    name: 'tgUsename',
    label: 'Ссылка на телеграм',
  },
  {
    type: 'select',
    name: 'streamId',
    label: 'Поток',
    options: getStreamsOptionsHook,
  },
  {
    type: 'text',
    variant: 'number',
    name: 'membersLimit',
    label: 'Максимальное количество участников',
  },
  {
    type: 'checkbox',
    name: 'enabled',
    label: 'Активный',
  },
];

export const initialValues = {
  name: '',
  streamId: '',
  description: '',
  enabled: true,
  fileId: '',
  tgUsername: '',
  adventureQuestId: '',
  membersLimit: undefined,
  requestsIsActive: true,
};

export const validation = yupObject({
  name: yupStringRequired,
  fileId: yupString,
  tgUsername: yupString,
  description: yupString,
  streamId: yupString,
  requestsIsActive: yupBooleanRequired,
  enabled: yupBooleanRequired,
  membersLimit: yupNumberOrEmptyStringPositive,
});
