import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import CardContent from '@mui/material/CardContent';

import {adminApi} from 'api';

import {QuestPointRewardsProps} from './types';
import {QuestPointRewardForm} from './QuestPointRewardForm';
import {Button} from '@mui/material';
import {useDialog} from 'components/Dialog';
import {RewardForm} from 'modules/tasks/containers/RewardForm';

export const QuestPointRewards = ({pointId}: QuestPointRewardsProps) => {
  const {data} = adminApi.endpoints.rewardsIndex.useQuery({limit: 10000});
  const [addReward] = adminApi.endpoints.questPointsRewardsAdd.useMutation();
  const {data: questPointRewards = []} = adminApi.endpoints.questPointsRewardsIndex.useQuery({pointId, enabled: true});

  const [Dialog, openDialog] = useDialog();
  if (!data) return null;
  const rewards = data.data || [];

  return (
    <>
      <Dialog title="Создать награду">
        <RewardForm
          onCreate={reward => {
            addReward({pointId, questPointRewardDto: {rewardValue: {rewardId: reward._id!, value: 1}, enabled: true}});
          }}
        />
      </Dialog>
      <Stack>
        <Button onClick={openDialog}>Создать новую награду</Button>
      </Stack>
      <Stack spacing={2} divider={<Divider />}>
        {questPointRewards.map(i => (
          <Card key={i._id}>
            <CardContent>
              <QuestPointRewardForm pointId={pointId} rewards={rewards} questPointReward={i} />
            </CardContent>
          </Card>
        ))}
        <Card>
          <CardContent>
            <QuestPointRewardForm pointId={pointId} rewards={rewards} />
          </CardContent>
        </Card>
      </Stack>
    </>
  );
  // return (
  //   <Stack spacing={2} divider={<Divider />}>
  //     {questPointRewards.map(i => (
  //       <Card key={i._id}>
  //         <CardContent>
  //           <QuestPointRewardForm pointId={pointId} rewards={rewards} questPointReward={i} />
  //         </CardContent>
  //       </Card>
  //     ))}
  //     <Card>
  //       <CardContent>
  //         <QuestPointRewardForm pointId={pointId} rewards={rewards} />
  //       </CardContent>
  //     </Card>
  //   </Stack>
  // );
};
