import {Navigate, RouteObject, useParams} from 'react-router';
import {Link} from 'react-router-dom';
import {skipToken} from '@reduxjs/toolkit/dist/query';

import {Icon} from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import Button from '@mui/material/Button';

import {adminApi} from 'api';
import {PaymentsServices, ReceiptsServices} from '@apiSchema/admin-api';
import {PATH_APP} from 'router/routes';

import {BreadcrumbsProvider} from 'components/Breadcrumbs';
import {BreadcrumbsSet} from 'components/Breadcrumbs/BreadcrumbsSet';
import {IsExistsContianer} from 'components/IsExistsContainer';
import {CommonPage, ListPage} from 'components/ListPage';

import {PaymentServiceForm, PaymentsStats, ReceiptsServiceForm} from './containers';
import {paymentsServicesColumns, receiptsServicesColumns} from './utils';

const useGetReceiptsServices = () => {
  const {serviceId} = useParams<'serviceId'>();
  return adminApi.endpoints.receiptsServiceIndex.useQuery(serviceId ? {serviceId} : skipToken);
};

const router: RouteObject = {
  path: 'payments',
  element: <BreadcrumbsProvider name="Платежи" href={PATH_APP.payments.root} />,
  children: [
    {index: true, element: <Navigate to="./services" replace />},
    {
      path: 'receiptsServices',
      element: <BreadcrumbsSet name="Чековые сервисы" href={PATH_APP.payments.receiptsServices.root} />,
      children: [
        {
          index: true,
          element: (
            <ListPage
              key="receiptsServicesList"
              columns={receiptsServicesColumns()}
              queryName="receiptsServicesIndex"
              heading="Чековые сервисы"
              params={{}}
              action={
                <Button variant="contained" startIcon={<Icon icon={plusFill} />} component={Link} to="./create">
                  Добавить чековый сервис
                </Button>
              }
            />
          ),
        },
        {
          path: 'create',
          element: (
            <BreadcrumbsSet name="Новый чековый сервис">
              <CommonPage title="Чековые сервисы" heading="Новый чековый сервис">
                <ReceiptsServiceForm
                  onSuccess={(data, navigate) => navigate(`${PATH_APP.payments.receiptsServices.root}/${data._id}`)}
                />
              </CommonPage>
            </BreadcrumbsSet>
          ),
        },
        {
          path: ':serviceId',
          element: (
            <IsExistsContianer<ReceiptsServices> useFetch={useGetReceiptsServices}>
              {service => (
                <BreadcrumbsSet name={service.name}>
                  <CommonPage title="Чековые сервисы" heading={service.name}>
                    <ReceiptsServiceForm serviceId={service._id} />
                  </CommonPage>
                </BreadcrumbsSet>
              )}
            </IsExistsContianer>
          ),
        },
      ],
    },
    {
      path: 'stats',
      element: <BreadcrumbsSet name="Статистика платежей" href={PATH_APP.payments.stats.root} />,
      children: [
        {
          index: true,
          element: (
            <CommonPage heading="Статистика платежей">
              <PaymentsStats />
            </CommonPage>
          ),
        },
      ],
    },
    {
      path: 'services',
      element: <BreadcrumbsSet name="Платежные системы" href={PATH_APP.payments.services.root} />,
      children: [
        {
          index: true,
          element: (
            <ListPage
              key="paymentsServicesList"
              columns={paymentsServicesColumns()}
              queryName="paymentsServicesIndex"
              heading="Платежные системы"
              params={{}}
              action={
                <Button variant="contained" startIcon={<Icon icon={plusFill} />} component={Link} to={'./create'}>
                  Добавить платежную систему
                </Button>
              }
            />
          ),
        },
        {
          path: 'create',
          element: (
            <BreadcrumbsSet name="Новая платежная система">
              <CommonPage title="Платежные системы" heading="Новая платежная система">
                <PaymentServiceForm
                  onSuccess={(data, navigate) => navigate(`${PATH_APP.payments.services.root}/${data._id}`)}
                />
              </CommonPage>
            </BreadcrumbsSet>
          ),
        },
        {
          path: ':serviceId',
          element: (
            <IsExistsContianer
              useFetch={() => {
                const {serviceId} = useParams<'serviceId'>();
                return adminApi.endpoints.paymentsServiceIndex.useQuery(serviceId ? {serviceId} : skipToken);
              }}
            >
              {(service: PaymentsServices) => (
                <BreadcrumbsSet name={service.name}>
                  <CommonPage title="Платежные системы" heading={service.name}>
                    <PaymentServiceForm paymentServiceId={service._id} />
                  </CommonPage>
                </BreadcrumbsSet>
              )}
            </IsExistsContianer>
          ),
        },
      ],
    },
  ],
};

export default router;
