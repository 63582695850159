import {FC} from 'react';
import Box from '@mui/material/Box';

import {WidgetVisual} from './WidgetVisual';
import {useWidgetVisualsData} from './hooks';

export const WidgetVisualContainer: FC<{containerName?: string; userId?: string; stageId: string}> = ({
  containerName,
  userId,
  stageId,
}) => {
  const {visualsId} = useWidgetVisualsData({stageId, userId, containerName});
  if (!userId || !stageId) return null;
  return (
    <>
      {visualsId.map(visualId => (
        <Box key={visualId}>
          <WidgetVisual visualId={visualId} usersId={[userId]} stagesId={[stageId]} />
        </Box>
      ))}
    </>
  );
};
