import {useCallback, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router';
import {omit} from 'lodash';
import {skipToken} from '@reduxjs/toolkit/dist/query';

import {adminApi} from 'api';
import {Contents} from '@apiSchema/admin-api';
import {ContentFormFieldsType} from '../../containers/ContentItemForm';
import {getContentPath} from 'modules/contents/utils';

export const useGetContent = () => {
  const {contentId} = useParams<'contentId'>();
  return adminApi.endpoints.contentIndex.useQuery(contentId ? {contentId} : skipToken);
};

export const useCreateContent = (data?: Contents) => {
  const navigate = useNavigate();
  const [update] = adminApi.endpoints.contentUpdate.useMutation();
  const [patch] = adminApi.endpoints.contentPatch.useMutation();
  const [create, {data: result}] = adminApi.endpoints.contentsAdd.useMutation();

  const setActive = () => {
    if (!data) return;
    patch({contentId: data._id!, contentPartialDto: {enabled: !data.enabled}});
  };

  const onSubmit = useCallback(
    async (inputData: ContentFormFieldsType) => {
      const structure = [{type: inputData?.type, value: inputData?.value}];
      const dto = {...omit(inputData, ['type', 'value']), structure};
      dto.fileId = inputData.fileId || undefined;
      if (!inputData.categoryId) dto.categoryId = undefined;
      if (data && data._id) return update({contentId: data._id, contentDto: dto as any});
      else return create({contentDto: dto as any});
    },
    [data, update, create]
  );

  useEffect(() => {
    if (!data?._id && result) navigate(getContentPath(result._id!));
  }, [result, data?._id, navigate]);

  return {onSubmit, setActive};
};
