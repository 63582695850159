import _ from 'lodash';
import {skipToken} from '@reduxjs/toolkit/query';

import {usersApi} from 'api';
import {WidgetsInfoApiArg} from '@apiSchema/users-api';

import {filterNumbers} from 'utils';
import {UserIdProp} from 'modules/users/types';
import {GroupsIdProp} from 'modules/groups/types';
import {WidgetContainers} from 'modules/widgets/utils';

const getWidgetInfoApiArg = ({
  userId,
  groupsId,
  widgetsId,
}: Partial<GroupsIdProp & UserIdProp & {widgetsId?: string[]}>): WidgetsInfoApiArg | typeof skipToken =>
  widgetsId?.length && (userId || groupsId)
    ? {
        _id: widgetsId,
        groupBy: 'users',
        // если в параметрах указан конкретный пользователь – заберем информацию о нем
        // при этом, если указана группа – то игнорируем запрос за пользователем и отдаем предполчтение группе
        usersId: userId && !groupsId?.length ? [userId] : undefined,
        // если указана группа – берем информацию по группе
        groupsId: groupsId?.length ? groupsId : undefined,
        precision: 'lifetime',
      }
    : skipToken;

export const useUserInfoWidgetSubscription = ({userId, groupsId}: Partial<GroupsIdProp & UserIdProp>) => {
  // достанем все виджеты пользовательской информации
  const {data: widgets = []} = usersApi.endpoints.widgetsList.useQuery({
    type: 'slider',
    containerName: WidgetContainers.USER_INFO,
  });

  usersApi.endpoints.widgetsInfo.useQuerySubscription(
    getWidgetInfoApiArg({userId, groupsId, widgetsId: widgets.map(item => item._id!)})
  );
};

export const useUserInfoWidget = ({userId, groupsId}: Partial<GroupsIdProp & UserIdProp>) => {
  const {data: widgets = []} = usersApi.endpoints.widgetsList.useQuery({
    type: 'slider',
    containerName: WidgetContainers.USER_INFO,
  });

  const {data = []} = usersApi.endpoints.widgetsInfo.useQuery(
    getWidgetInfoApiArg({userId, groupsId, widgetsId: widgets.map(item => item._id!)}),
    {
      selectFromResult: ({data = []}) => ({
        data: _.flatten(
          data.map(widget =>
            widget.attrs
              .map(attr => {
                const values = _.flatten(
                  widget.values
                    .filter(item => !userId || item.userId === userId)
                    .map(item =>
                      _.flatten(
                        item.values
                          .filter(item => item.values.length && item.widgetAttrId === attr._id)
                          .map(({type, values}) => {
                            const isNumeric = ['number', 'score'].includes(type);
                            return values
                              .map(item => (isNumeric ? Number(item.value) : item.value))
                              .filter(value => (isNumeric ? _.isNumber(value) : !!value));
                          })
                      )
                    )
                    .filter(item => item.length > 0)
                );

                if (!values.length) return null;

                let value: string | number = '';
                switch (attr.formula) {
                  case 'sum':
                    value = _.sum(filterNumbers(values));
                    break;
                  case 'avg':
                    value = _.mean(filterNumbers(values));
                    break;
                  case 'max':
                    value = _.max(filterNumbers(values)) || 0;
                    break;
                  case 'min':
                    value = _.min(filterNumbers(values)) || 0;
                    break;
                  case 'first_value':
                    value = values[0];
                    break;
                  case 'last_value':
                    value = values[values.length - 1];
                    break;
                  default:
                    value = values.join(' ');
                    break;
                }

                return {
                  name: attr.name,
                  value,
                };
              })
              .filter(Boolean)
          )
        ),
      }),
    }
  );

  return {data};
};
