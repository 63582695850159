import {FC} from 'react';
import Button from '@mui/material/Button';

import {adminApi, usersApi} from 'api';
import {PostIdProp} from 'modules/publications/types';

export const PublicationView: FC<PostIdProp> = ({postId}) => {
  const [setViewed] = usersApi.endpoints.viewsSetViewed.useMutation();

  const {isViewed} = adminApi.endpoints.publicationIndex.useQuery(
    {postId},
    {
      selectFromResult: ({data}) => ({
        isViewed: data ? Boolean(data.isViewed) : undefined,
      }),
    }
  );

  if (typeof setViewed === 'undefined' || isViewed) return null;
  return (
    <>
      <Button
        size="small"
        variant="contained"
        onClick={() => setViewed({referenceId: postId, referenceName: 'Publications'})}
      >
        {isViewed ? 'Отметить как непрочитанную' : 'Прочитано'}
      </Button>
    </>
  );
};
