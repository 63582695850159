import {FC} from 'react';
import {parseISO, format} from 'date-fns';
import {Button, Card, CardContent, Box, CardHeader} from '@mui/material';
import {StringParam, useQueryParam, withDefault} from 'use-query-params';
import {GridValueFormatterParams, GridValueGetterParams} from '@mui/x-data-grid';

import {adminApi} from 'api';
import {AchievementsData} from '@apiSchema/users-api';
import {yupObject, yupString, yupStringRequired} from 'utils/validation';

import {Form} from 'components/Form';
import useDialog from 'components/Dialog';
import {UserIdProp} from 'modules/users/types';
import {UsersDataListProvider} from 'components/DataList/DataList';
import {ButtonFilter} from 'components/ButtonFilter';
import {rewardTypeOptions} from 'modules/tasks/utils';

const useRewardsList = () => {
  const {data} = adminApi.endpoints.rewardsIndex.useQuery(
    {type: 'achivement', enabled: true},
    {
      selectFromResult: ({data}) => ({data: data?.data?.map(item => ({value: item._id, name: item.name})) || []}),
    }
  );
  return data;
};

export const UserCardRewards: FC<UserIdProp> = ({userId}) => {
  const [rewardType, setRewardType] = useQueryParam('rewardType', withDefault(StringParam, 'achivement'));
  const [addAchievment] = adminApi.endpoints.achievementsAdd.useMutation();
  const [Dialog, openDialog, closeDialog] = useDialog();

  return (
    <>
      <Dialog title="Выдать награду">
        <Form
          onSubmit={async data => {
            addAchievment({achievementsDto: {toUserId: userId, ...data}}).finally(() => closeDialog());
          }}
          validation={yupObject({
            rewardId: yupStringRequired,
            title: yupString,
            description: yupStringRequired,
          })}
          initials={{rewardId: '', title: '', description: ''}}
          fields={[
            {
              type: 'select',
              name: 'rewardId',
              label: 'Награда',
              options: useRewardsList,
            },
            {
              type: 'text',
              name: 'title',
              label: 'Заголовок (опционально)',
            },
            {
              type: 'text',
              name: 'description',
              variant: 'text',
              label: 'За что дана награда?',
            },
          ]}
        />
      </Dialog>
      <Card>
        <CardHeader
          title="Достижения пользователя"
          action={
            <Button size="small" color="primary" onClick={openDialog}>
              Выдать достижение
            </Button>
          }
        />
        <CardContent sx={{px: 1}}>
          <Box px={1} mb={1}>
            <ButtonFilter
              label="Тип"
              value={rewardType}
              items={rewardTypeOptions.filter(item => ['reward', 'achivement'].includes(item.value))}
              onChange={type => setRewardType(type, 'replaceIn')}
            />
          </Box>
          <UsersDataListProvider
            hideToolbar
            hideFooterPagination
            api="usersApi"
            density="compact"
            endpoint="achievementsIndex"
            getData={data => data}
            getRowId={row => [row.rewardId, row.toUserId].join('.')}
            params={{userId: [userId], rewardType} as any}
            columns={[
              {
                flex: 1,
                field: 'reward__name',
                headerName: 'Награда',
                valueGetter: ({row}: GridValueGetterParams<any, AchievementsData>) => row.reward.name,
              },
              {
                flex: 1,
                field: 'lastCreatedAt',
                headerName: 'Дата выдачи',
                valueFormatter: ({value}: GridValueFormatterParams<string>) => format(parseISO(value), 'dd.MM.yyyy'),
              },
              {
                flex: 1,
                field: 'lastDescription',
                headerName: 'Описание',
              },
            ]}
          />
        </CardContent>
      </Card>
    </>
  );
};
