import {debounce} from 'lodash';
import {adminApi} from 'api';
import {QuestDto} from '@apiSchema/admin-api';
import {
  yupBoolean,
  yupBooleanRequired,
  yupObject,
  yupString,
  yupStringOfOptionsRequired,
  yupStringRequired,
} from 'utils/validation';

import {questTypesOptions, questStatusesOptions} from 'modules/events/types';
import {FieldStructure} from 'components/Form/types';
import {designFormValidation} from './questDesignField';

export const fields = (questId?: string): FieldStructure[] => [
  {
    type: 'text',
    name: 'name',
    label: 'Название',
  },
  {
    type: 'lexical',
    name: 'content',
    label: 'Материал для изучения',
    updateFn: debounce((content, {dispatch}) => {
      if (!questId) return;
      dispatch(adminApi.endpoints.questPatch.initiate({questId, questPartialDto: {content}}));
    }, 200),
  },
  {
    fields: [
      {
        type: 'select',
        name: 'type',
        label: 'Тип',
        options: questTypesOptions,
      },
      {
        type: 'select',
        name: 'status',
        label: 'Статус',
        options: questStatusesOptions,
      },
    ],
  },
  // {
  //   type: 'checkbox',
  //   name: 'isPattern',
  //   label: 'Квест является паттерном',
  // },
  // {
  //   type: 'checkbox',
  //   name: 'allowReject',
  //   label: 'Квест можно отклонить',
  // },
];

export const initialValues: QuestDto = {
  name: '',
  status: 'draft',
  type: 'default',
  description: '',
  content: '',
  enabled: true,
  isPattern: false,
  allowReject: false,
};

export const validation = yupObject({
  name: yupStringRequired,
  content: yupString,
  description: yupString,
  design: designFormValidation,
  type: yupStringOfOptionsRequired(questTypesOptions),
  status: yupStringOfOptionsRequired(questStatusesOptions),
  isPattern: yupBoolean,
  allowReject: yupBoolean,
  enabled: yupBooleanRequired,
});
