import {useCallback} from 'react';
import {useAppDispatch} from 'store';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import CardContent from '@mui/material/CardContent';

import {Icon} from '@iconify/react';
import cubeOutline from '@iconify/icons-eva/cube-outline';
import peopleOutline from '@iconify/icons-eva/people-outline';
import broadcastOutline from '@iconify/icons-eva/radio-outline';
import playOutline from '@iconify/icons-eva/play-circle-outline';
import stopOutline from '@iconify/icons-eva/stop-circle-outline';
import BadgeStatus from '__theme/components/BadgeStatus';

import {adminApi} from 'api';
import {Timelines, QuestTimelinePartialDto} from '@apiSchema/admin-api';

import useDialog, {useConfirmationDialog} from 'components/Dialog';

import {Timeline} from './Timeline';
import {QuestAccessSettingsProps} from './types';
import {QuestTimelineUsers} from './QuestTimelineUsers';

const createSetting = adminApi.endpoints.questTimelinesAdd.initiate;
const updateSetting = adminApi.endpoints.questTimelineElemPatch.initiate;
const deleteSetting = adminApi.endpoints.questTimelineElemDelete.initiate;

const QuestTimeline = ({
  timelineId,
  questId,
  data: initials,
}: {
  timelineId: string;
  questId: string;
  data: Timelines;
}) => {
  const dispatch = useAppDispatch();
  const [UsersDialog, openUserDialog, closeUserDialog] = useDialog();
  const {data} = adminApi.endpoints.questTimelineElemIndex.useQuery({timelineId, questId});

  const onRemove = useConfirmationDialog(
    useCallback((timelineId: string) => dispatch(deleteSetting({questId, timelineId})), [questId, dispatch]),
    {text: 'Восстановить удаленный таймлайн невозможно.', title: 'Удаление таймлайна'}
  );

  const onSave = async (data: QuestTimelinePartialDto, timelineId: string) =>
    dispatch(
      updateSetting({
        questId,
        timelineId,
        questTimelinePartialDto: {...data, dateStart: data.dateStart || null, dateFinish: data.dateFinish || null},
      })
    );

  const [emitTImeline, {isLoading: emitting}] = adminApi.endpoints.questTimelineElemEmit.useMutation();
  const [startTimeline, {isLoading: starting}] = adminApi.endpoints.questTimelineElemStart.useMutation();
  const [stopTimeline, {isLoading: stopping}] = adminApi.endpoints.questTimelineElemStop.useMutation();
  const [rebuildTimeline, {isLoading: rebuilding}] = adminApi.endpoints.questTimelineElemRebuild.useMutation();
  const isLoading = emitting || starting || stopping || rebuilding;

  let badgeStatus: string | undefined = undefined;
  switch (data?.status) {
    case 'active': {
      badgeStatus = 'online';
      break;
    }
    case 'building': {
      badgeStatus = 'unread';
      break;
    }
    case 'checking': {
      badgeStatus = 'away';
      break;
    }
    case 'closed': {
      badgeStatus = 'busy';
      break;
    }
  }

  const Controls = (
    <Stack spacing={1} direction="column" alignItems="center">
      <BadgeStatus status={badgeStatus} size="large" />
      <Tooltip title="Запустить" placement="left">
        <span>
          <IconButton
            size="small"
            onClick={() => startTimeline({timelineId, questId})}
            disabled={isLoading || data?.status === 'active'}
          >
            <Icon icon={playOutline} />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Остановить" placement="left">
        <span>
          <IconButton
            size="small"
            onClick={() => stopTimeline({timelineId, questId})}
            disabled={isLoading || data?.status !== 'active'}
          >
            <Icon icon={stopOutline} />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Раздать квест всем" placement="left">
        <span>
          <IconButton
            size="small"
            onClick={() => emitTImeline({timelineId, questId})}
            disabled={isLoading || data?.status === 'building'}
          >
            <Icon icon={broadcastOutline} />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Выбрать пользователей для раздачи квеста" placement="left">
        <IconButton size="small" onClick={openUserDialog}>
          <Icon icon={peopleOutline} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Пересобрать структуру" placement="left">
        <span>
          <IconButton
            size="small"
            onClick={() => rebuildTimeline({timelineId, questId})}
            disabled={isLoading || data?.status === 'building'}
          >
            <Icon icon={cubeOutline} />
          </IconButton>
        </span>
      </Tooltip>
    </Stack>
  );

  return (
    <>
      <UsersDialog title="Пользователи" maxWidth={false}>
        <QuestTimelineUsers questId={questId} timelineId={timelineId} onClose={closeUserDialog} />
      </UsersDialog>
      <Timeline onSave={onSave} questId={questId} Controls={Controls} data={data || initials} onRemove={onRemove} />
    </>
  );
};

export const QuestTimelines = ({questId}: QuestAccessSettingsProps) => {
  const {data: settings} = adminApi.endpoints.questTimelinesIndex.useQuery({questId});
  const dispatch = useAppDispatch();
  const onCreate = () => dispatch(createSetting({questId, questTimelineDto: {access: {isActive: false}}}));

  return (
    <>
      <CardHeader
        title="Таймлайн"
        action={
          <Button onClick={onCreate} variant="contained" size="medium">
            Добавить строку
          </Button>
        }
      />
      <Stack spacing={3}>
        {(settings || []).map(item => (
          <Card key={`${item._id}`}>
            <CardContent>
              <QuestTimeline timelineId={`${item._id}`} questId={questId} data={item} />
            </CardContent>
          </Card>
        ))}
      </Stack>
    </>
  );
};
