import * as yup from 'yup';
import {FC, useState} from 'react';
import {skipToken} from '@reduxjs/toolkit/dist/query';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import plusIcon from '@iconify/icons-eva/plus-outline';

import {adminApi} from 'api';

import {Form} from 'components/Form';
import {useDialog} from 'components/Dialog';
import useDialogSelector from 'components/DialogSelector';

import {TemplateOptionProp} from 'modules/tasks/types';
import {templateListColumns} from 'modules/tasks/utils';
import {WidgetAttrFormDto, WidgetIdProp} from 'modules/widgets/types';

import {attributeFormulaSelect} from '../WidgetAttributeForm/fields';
import {useSnackbar} from 'notistack';

export const SelectAttributeTemplate: FC<WidgetIdProp> = ({widgetId}) => {
  const [Dialog] = useDialog();
  const [Selector, openSelector] = useDialogSelector();
  const [templateId, setTemplateId] = useState<string | undefined>(undefined);

  const [createAttr] = adminApi.endpoints.widgetAttrsAdd.useMutation();
  const [getTemplate] = adminApi.endpoints.templateIndex.useLazyQuerySubscription();
  const {data: template} = adminApi.endpoints.templateIndex.useQuery(templateId ? {templateId} : skipToken);
  const {data: templateOptions} = adminApi.endpoints.templateOptionsIndex.useQuery(
    templateId ? {templateId} : skipToken
  );

  const createForTemplate = async (templateId?: string) => {
    if (!templateId) return;
    const result = await getTemplate({templateId});
    if ('data' in result && result.data) {
      await createAttr({
        widgetId,
        widgetAttrDto: {
          widgetId,
          formula: 'list',
          templateId,
          name: result.data.name,
        },
      });
    }
  };

  return (
    <>
      <Selector
        single
        params={{}}
        title="Выберите шаблон"
        queryName="templatesIndex"
        columns={templateListColumns()}
        onConfirm={item => createForTemplate(item[0])}
        confirmText="Выбрать весь шаблон"
        actions={[{title: 'Выбрать опции шаблона', onClick: ids => setTemplateId(ids[0]?.toString())}]}
      />
      <Dialog title="Выбор опции шаблона" open={!!templateId}>
        {template && (
          <Box>
            <Box mb={2}>
              <Typography variant="body1">{`Шаблон «${template.name}»`}</Typography>
              <Typography variant="subtitle1" color="grey.500">
                {template.type}
              </Typography>
            </Box>
            <Stack spacing={2} my={2}>
              {templateOptions?.map(item => (
                <WidgetAttributeForm key={item._id} templateOption={item} widgetId={widgetId} />
              ))}
            </Stack>

            <Button onClick={() => setTemplateId(undefined)} variant="text" fullWidth>
              Закрыть окно
            </Button>
          </Box>
        )}
      </Dialog>
      <Button variant="contained" onClick={openSelector}>
        Добавить атрибуты
      </Button>
    </>
  );
};

const WidgetAttributeForm: FC<TemplateOptionProp & WidgetIdProp> = ({templateOption, widgetId}) => {
  const [add, {isLoading}] = adminApi.endpoints.widgetAttrsAdd.useMutation();
  const {enqueueSnackbar} = useSnackbar();
  const onSubmit = async (data: Pick<WidgetAttrFormDto, 'formula'>) => {
    if (templateOption.templateId && templateOption._id) {
      const result = await add({
        widgetId,
        widgetAttrDto: {
          ...data,
          widgetId,
          name: templateOption.name,
          templateId: templateOption.templateId,
          templateOptionId: templateOption._id,
        },
      });
      if ('data' in result) enqueueSnackbar('Атрибут добавлен', {variant: 'success'});
      else enqueueSnackbar('Ошибка добавления атрибута', {variant: 'error'});
    }
  };

  return (
    <Stack direction="row" justifyContent="space-between">
      <Box>
        <Typography variant="body2">{templateOption.name}</Typography>
        <Typography variant="subtitle2" color="grey.500">
          {templateOption.type}
        </Typography>
      </Box>
      <Form
        buttonsPosition="right"
        fields={[...attributeFormulaSelect]}
        initials={{formula: 'first_value'}}
        validation={yup.object().shape({
          formula: yup.string().required(),
        })}
        onSubmit={onSubmit}
        buttonsStructure={[{type: 'submit', text: 'Сохранить', display: 'icon', icon: plusIcon, disabled: isLoading}]}
      />
    </Stack>
  );
};
