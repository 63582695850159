import {createSelector} from '@reduxjs/toolkit';
import {useSelector, shallowEqual} from 'react-redux';
import {useCallback, useEffect, useState} from 'react';

import {usersApi} from 'api';
import {WidgetsVisualsIndexApiArg} from '@apiSchema/users-api';

type StageIdProp = {stageId: string};

export const useWidgetVisualsData = ({
  stageId,
  userId,
  containerName,
}: StageIdProp & Partial<{containerName?: string; userId?: string}>) => {
  const [getWidgetInfo] = usersApi.endpoints.widgetsInfo.useLazyQuerySubscription();
  const [getVisuals] = usersApi.endpoints.widgetsVisualsIndex.useLazyQuerySubscription();

  const [isLoading, setLoading] = useState(true);
  const {isLoading: isVisualsLoading, visualsId} = useSelector<any>(
    state => selectWidgetsInfo(state, stageId, containerName),
    shallowEqual
  ) as any;

  const load = useCallback(async () => {
    setLoading(true);
    const result = await getVisuals(getRequestProps(stageId, containerName));
    await Promise.all(
      (result.data || []).map(visual =>
        getWidgetInfo({_id: visual.widgetsId, stagesId: [stageId], usersId: userId ? [userId] : undefined})
      )
    );
    setLoading(false);
  }, [stageId, getVisuals, getWidgetInfo, containerName, userId]);

  useEffect(() => {
    load();
  }, [load]);

  return {isLoading: isLoading || isVisualsLoading, visualsId};
};

const getRequestProps = (stageId: string, containerName?: string) =>
  ({
    containerName,
    stagesId: [stageId],
    withSubStages: true,
    withParentStages: true,
    withStagesSubscriptions: true,
    populates: ['designs', 'props'],
  } as WidgetsVisualsIndexApiArg);

const selectWidgetsInfo = createSelector(
  (state: any) => state,
  (_: any, stageId: string) => stageId,
  (_: any, stageId: string, containerName?: string) => containerName,
  (state: any, stageId: string, containerName?: string) => {
    const {isLoading, isUninitialized, data} = usersApi.endpoints.widgetsVisualsIndex.select(
      getRequestProps(stageId, containerName)
    )(state);
    return {
      isLoading: isLoading || isUninitialized,
      visualsId: data?.map(item => item._id!).join('.'),
    };
  }
);
