import {without} from 'lodash';
import {FC} from 'react';
import {GridRowId} from '@mui/x-data-grid';

import {Button, Card, CardHeader, IconButton} from '@mui/material';
import {Icon} from '@iconify/react';
import RemoveIcon from '@iconify/icons-eva/trash-outline';

import {adminApi} from 'api';

import {useDialogSelector} from 'components/DialogSelector';
import {DataList} from 'components/DataList';
import {tagsColumns} from 'modules/catalogs/pages/TagList';
import {TagsIndexApiArg} from '@apiSchema/admin-api';

type TagSelectorProps = {
  entryId: string;
  targetEntity: 'content' | 'quest';
  params?: TagsIndexApiArg;
};

export const TagsSelector: FC<TagSelectorProps> = ({entryId, targetEntity, params}) => {
  const mutationName = `${targetEntity}TagsSet` as const;
  const queryName = `${targetEntity}TagsIndex` as const;
  const idName = `${targetEntity}Id` as const;

  const [setTags] = adminApi.endpoints[mutationName].useMutation();
  const onTagsSet = async (tagsId: GridRowId[]) => {
    setTags({body: {tagsId: tagsId.map(item => item.toString())}, [idName]: entryId} as any);
  };

  const [Selector, openGroupsSelector] = useDialogSelector();
  return (
    <Card>
      <CardHeader
        title="Теги"
        action={
          <Button onClick={openGroupsSelector} variant="outlined">
            Добавить теги
          </Button>
        }
      />
      <DataList
        sx={{minHeight: 400, height: '100%'}}
        columns={tagsColumns(({api, row}) => (
          <IconButton size="small" onClick={() => onTagsSet(without(api.getAllRowIds(), `${row._id}`))}>
            <Icon icon={RemoveIcon} />
          </IconButton>
        ))}
        queryName={queryName}
        params={{[idName]: entryId} as any}
        getData={response => response}
      />
      <Selector
        title="Выбор тегов"
        initialSelection={[]}
        params={params}
        onConfirm={onTagsSet}
        columns={tagsColumns()}
        queryName="tagsIndex"
      />
    </Card>
  );
};
