import {UserDto} from '@apiSchema/admin-api';
import {yupDate, yupObject, yupString, yupStringOfOptions, yupStringRequired} from 'utils/validation';

import {FieldStructure} from 'components/Form/types';

const genderOptions = [
  {
    name: 'Мужчина',
    value: 'male',
  },
  {
    name: 'Женщина',
    value: 'female',
  },
];

export const fields: FieldStructure[] = [
  {
    type: 'text',
    name: 'name',
    label: 'Имя',
  },
  {
    type: 'text',
    name: 'surname',
    label: 'Фамилия',
  },
  // {
  //   type: 'dateTime',
  //   name: 'birthDate',
  //   label: 'Дата рождения',
  //   onlyDate: true,
  // },
  {
    type: 'select',
    name: 'gender',
    label: 'Пол',
    options: genderOptions,
  },
  // {
  //   type: 'checkbox',
  //   name: 'enabled',
  //   label: 'Активный',
  // },
];

export const initialValues: UserDto = {name: '', surname: '', birthDate: undefined, gender: 'not_sure', enabled: true};

export const validation = yupObject({
  name: yupStringRequired,
  surname: yupString,
  birthDate: yupDate,
  gender: yupStringOfOptions(genderOptions),
  // enabled: yupBooleanRequired,
});
