import {FC} from 'react';

import Card from '@mui/material/Card';

import {getQuestPath} from 'modules/events/utils';
import {EntityPreview} from 'components/EntityPreview';

import {questTypesNames} from 'modules/events/types';
import {PostIdProp} from 'modules/publications';
import {useGetStageInfo} from 'modules/publications/utils';

export const PublicationStageInfo: FC<PostIdProp> = ({postId}) => {
  const {stageId, questName, questType, questId, questFileId} = useGetStageInfo({postId});
  if (!stageId) return null;
  return (
    <Card sx={{p: 1}}>
      <EntityPreview
        p={1}
        noBorder
        target="_blank"
        title={questName || ''}
        to={getQuestPath(questId)}
        fileId={questFileId || undefined}
        subTitle={questType ? questTypesNames[questType] : undefined}
      />
    </Card>
  );
};
