import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

export const ButtonFilter: React.VFC<{
  items: {
    value: string;
    name: string;
  }[];
  label?: string;
  value?: string | null;
  onChange: (value: string | null | undefined) => void;
}> = ({items = [], value, onChange}) => {
  return (
    <Stack spacing={1}>
      {/* <Typography variant="caption">{label}</Typography> */}
      <ButtonGroup color="inherit">
        {items.map(item => (
          <Button
            key={item.value}
            onClick={() => onChange(value === item.value ? undefined : item.value)}
            color={item.value === value ? 'primary' : 'inherit'}
          >
            {item.name}
          </Button>
        ))}
      </ButtonGroup>
    </Stack>
  );
};
