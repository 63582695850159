import {adminApi} from 'api';
import {GroupsUsers} from '@apiSchema/admin-api';

import {useNavigate} from 'react-router';
import {useCallback, useMemo, useState} from 'react';

import {GridRowParams} from '@mui/x-data-grid';

import OpenInNew from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import SchoolOutlined from '@mui/icons-material/SchoolOutlined';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

import Card from '@mui/material/Card';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import {getUserPath} from 'modules/users';
import {DataList} from 'components/DataList';
import {useConfirmationDialog} from 'components/Dialog';
import useDialogSelector from 'components/DialogSelector';
import {usersColumns} from 'modules/users/containers/UsersList/columns';

import {groupMembersColumns} from './columns';

export const GroupMembers = ({
  groupId,
  onRowClick,
}: {
  groupId: string;
  onRowClick?: (data: GridRowParams<GroupsUsers>) => void;
}) => {
  const [withSubGroups, setWithSubGroups] = useState(true);
  const navigate = useNavigate();
  const [UsersSelector, openUsersSelector] = useDialogSelector();
  const {data} = adminApi.endpoints.groupUsers.useQuery({groupId});
  const {data: group} = adminApi.endpoints.groupIndex.useQuery({groupId});
  const [addUsers] = adminApi.useGroupAddUsersMutation();
  const onUsersAdd = useCallback((usersId: string[]) => addUsers({groupId, body: {usersId}}), [groupId, addUsers]);

  const [removeUser] = adminApi.useGroupDeleteUserMutation();
  const removeWithConfirmation = useConfirmationDialog(removeUser);

  const [removeCurator] = adminApi.endpoints.groupRemoveCurator.useMutation();
  const [setCurator] = adminApi.endpoints.groupSetCurator.useMutation();
  const [setLeader] = adminApi.endpoints.groupSetLeader.useMutation();
  const onSetLeader = ({userId}: GroupsUsers) => setLeader({groupId, userId});
  const onSetCurator = ({userId}: GroupsUsers) => setCurator({groupId, userId});
  const onRemoveCurator = ({userId}: GroupsUsers) => removeCurator({groupId, userId});

  const Action = (
    <Button onClick={openUsersSelector} size="small">
      Добавить
    </Button>
  );

  const usersParams = useMemo(
    () => ({skipId: data?.map(item => item.userId), limit: 10000, populates: ['logins']}),
    [data]
  );
  return (
    <>
      <UsersSelector
        title="Выбор пользователей"
        initialSelection={[]}
        onConfirm={onUsersAdd}
        columns={usersColumns()}
        queryName="usersIndex"
        params={usersParams}
      />
      <Card>
        <CardHeader title="Состав" action={Action} />
        <CardContent sx={{px: 1}}>
          <Alert severity="info">
            Нажмите на строку с именем пользователя для просмотра статистики и публикаций пользователя
          </Alert>
          <DataList
            hideToolbar
            customPagination
            density="compact"
            queryName="groupUsers"
            onRowClick={onRowClick}
            params={{groupId, withSubGroups}}
            getData={data => data as any}
            columns={groupMembersColumns(undefined, {flex: 1}, ({row}: {row: GroupsUsers}) =>
              row.groupId === groupId
                ? [
                    {
                      title: row.isCurator ? 'Куратор группы' : 'Сделать куратором',
                      label: row.isCurator ? 'Куратор группы' : 'Сделать куратором',
                      icon: <SchoolOutlined fontSize="small" color={row.isCurator ? 'info' : undefined} />,
                      onClick: () => (row.isCurator ? onRemoveCurator(row as any) : onSetCurator(row as any)),
                    },
                    {
                      disabled: row.isLeader,
                      title: row.isLeader ? 'Лидер группы' : 'Сделать лидером',
                      label: row.isLeader ? 'Лидер группы' : 'Сделать лидером',
                      icon: <EmojiEventsIcon fontSize="small" color={row.isLeader ? 'info' : undefined} />,
                      onClick: () => onSetLeader(row as any),
                    },
                    {
                      icon: <DeleteIcon fontSize="small" />,
                      title: 'Удалить из группы',
                      label: 'Удалить из группы',
                      onClick: () =>
                        removeWithConfirmation(
                          {userId: row.userId, groupId},
                          {
                            btnType: 'error',
                            title: `Удалить пользователя ${row.user?.name} ${row.user?.surname} из группы?`,
                          }
                        ),
                    },
                  ]
                : [
                    {
                      icon: <OpenInNew fontSize="small" />,
                      title: 'Перейти к пользователю',
                      label: 'Перейти к пользователю',
                      onClick: () => navigate(getUserPath(row.userId)),
                    },
                  ]
            )}
          />
          {!!group?.subGroupsId?.length && (
            <Stack spacing={1}>
              {withSubGroups && <Alert severity="info">Показаны участники вложенных групп</Alert>}
              <Button size="small" fullWidth onClick={() => setWithSubGroups(state => !state)}>
                {withSubGroups ? 'Показать участников группы' : 'Показать участников вложенных групп'}
              </Button>
            </Stack>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default GroupMembers;
