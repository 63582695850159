import {unflatten} from 'flat';

import {shallowEqual, useSelector} from 'react-redux';
import React, {FC, memo, useContext, useMemo} from 'react';

import {usersApi} from 'api';
import {VisualComponent} from '../types';
import {WidgetQueryContext} from '../context';
import {selectWidgetValues} from '../selectors';
import {visualComponents} from '../VisualComponents';

const {container: ContainerComponent} = visualComponents;

export const WidgetVisualComponent: FC<{visualId: string; containerName: string; designId: string}> = memo(
  ({designId, ...props}) => {
    // достанем сам визуальчик
    const {data: design} = usersApi.endpoints.visualDesign.useQueryState(props, {
      selectFromResult: ({data}) => ({data: data?.find(item => item._id === designId)}),
    });
    // достанем данные
    const widgetQuery = useContext(WidgetQueryContext);
    const rawValues = useSelector<any>(
      state => selectWidgetValues(state, widgetQuery, design?.widgetId, design?.widgetAttrId),
      shallowEqual
    );

    const values = useMemo(() => unflatten(rawValues), [rawValues]) as any[];
    if (!design) return null;
    const {options} = design;
    const Component = (visualComponents as any)[options.elementType as any] as VisualComponent<any> | undefined;
    if (!Component) return null;

    let Content: JSX.Element[] | JSX.Element | null = null;

    if (values) {
      Content = Object.values(values).map((item, index) => (
        <Component
          name={''}
          type={item.type}
          index={Object.values(values).length > 1 ? index + 1 : undefined}
          value={item.value || options.value}
          key={`${index}`}
          options={options[`${options.elementType}Options` as const] as any}
        />
      ));
    } else if (options.value) {
      Content = (
        <Component name={''} value={options.value} options={options[`${options.elementType}Options` as const] as any} />
      );
    }

    if (!options.containerOptions) return <React.Fragment>{Content}</React.Fragment>;
    return <ContainerComponent options={options.containerOptions}>{Content}</ContainerComponent>;
  }
);
