import {DropzoneProps, useDropzone} from 'react-dropzone';

import {SxProps} from '@mui/system';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import {Files} from '@apiSchema/files-api';

import RetryIcon from '@mui/icons-material/Autorenew';
import AttachIcon from '@mui/icons-material/AttachFile';
import CircularIcon from '@mui/icons-material/HourglassBottom';

import {FileAnswer, PhotoAnswer} from 'components/__general/tasks/Answers';

const LoadingMod = () => {
  return (
    <Stack
      borderRadius={2}
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      sx={{
        opacity: '0.8',
        position: 'absolute',
        bgcolor: 'primary.500',
      }}
    >
      <CircularIcon />
    </Stack>
  );
};

const RetryMod = () => (
  <Stack alignItems="center" justifyContent="center" position="absolute" width="100%" height="100%">
    <RetryIcon />
  </Stack>
);

type FileDropzoneProps = {
  accept?: DropzoneProps['accept'];
  onDrop: DropzoneProps['onDrop'];
  onRemove: () => void;
  file?: Files | null;
  meta?: {isLoading: boolean; isError: boolean};
  btnText?: string;
  sx?: SxProps;
};

export const FileDropzone = ({onDrop, onRemove, file, meta, accept, btnText, sx}: FileDropzoneProps) => {
  const {getRootProps, getInputProps} = useDropzone({multiple: false, accept, onDrop, disabled: !!file});
  const {isLoading = false, isError = false} = meta || {};
  const isImage = file?.type.includes('image');
  const showButton = !(file || isLoading || isError);

  return (
    <Stack {...getRootProps()} sx={{'&:focus, &:active': {outline: 'none'}}}>
      <input {...getInputProps()} style={{display: 'none'}} />
      {showButton && (
        <div>
          <Button size="medium" variant="contained" color="secondary">
            {btnText || <AttachIcon />}
          </Button>
        </div>
      )}
      {!showButton && (
        <Stack
          height="100%"
          alignItems="center"
          position="relative"
          justifyContent="center"
          borderRadius={2}
          sx={{
            cursor: 'pointer',
            bgcolor: 'secondary.main',
            width: !file && btnText ? 'fit-content' : 64,
            height: !file && btnText ? 'fit-content' : 64,
            transition: 'all .25s',
            '&:hover': {
              bgcolor: 'primary.25',
            },
            ...sx,
          }}
        >
          {isLoading && <LoadingMod />}
          {isError && <RetryMod />}
          {file &&
            file._id &&
            (isImage ? (
              <PhotoAnswer fileId={file._id} onRemove={onRemove} />
            ) : (
              <FileAnswer fileId={file._id} onRemove={onRemove} />
            ))}
        </Stack>
      )}
    </Stack>
  );
};
