import {GridActionsCellItemProps, GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import {formatDate} from 'utils';
import {getTableAction} from './tableActions';

export const enabledColumn: GridColDef = {
  width: 80,
  field: 'enabled',
  type: 'boolean',
  headerName: 'Активный',
  headerAlign: 'left',
};

export const nameColumn: GridColDef = {
  field: 'name',
  headerName: 'Название',
  flex: 0.5,
};

export const dateColumns: GridColDef[] = [
  {
    field: 'createdAt',
    headerName: 'Дата создания',
    type: 'date',
    flex: 0.2,
    sortingOrder: ['desc', 'asc', null],
    valueFormatter: ({value}) => formatDate(value as string),
  },
  {
    field: 'updatedAt',
    headerName: 'Дата изменения',
    type: 'dateTime',
    flex: 0.2,
    valueFormatter: ({value}) => formatDate(value as string),
  },
];

export const enchanceColumns =
  <T extends unknown>(columns: GridColDef[], columnsOptions?: {hideEnabled?: boolean; hideDates?: boolean}) =>
  (
    actionCell?: (props: GridRenderCellParams<any, any>) => JSX.Element,
    options?: {width?: number; flex?: number; hideEnabled?: boolean; hideDates?: boolean},
    actions?: (params: GridRenderCellParams<any, any>) => (GridActionsCellItemProps | undefined)[]
  ) => {
    const additionalColumns: GridColDef[] = [];
    if (!columnsOptions?.hideEnabled && !options?.hideEnabled) additionalColumns.push(enabledColumn);
    if (!columnsOptions?.hideDates && !options?.hideDates) additionalColumns.push(...dateColumns);

    return [
      ...columns,
      ...additionalColumns,
      actions && {
        field: 'actions',
        type: 'actions',
        width: options?.width || 80,
        flex: options?.flex,
        align: 'right',
        getActions: (params: GridRenderCellParams<T>) => actions(params).map(props => getTableAction(props)),
      },
      actionCell && {
        field: '__action',
        headerAlign: 'right',
        align: 'right',
        headerName: '',
        renderCell: actionCell,
        disableColumnMenu: true,
        width: options?.width,
        flex: options?.flex || 0.2,
      },
    ].filter(Boolean) as GridColDef[];
  };
