/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback} from 'react';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {BooleanParam, StringParam, useQueryParams} from 'use-query-params';
import {LinearProgress, Dialog, DialogTitle, DialogContent} from '@mui/material';

import {adminApi} from 'api';
import {Rewards} from '@apiSchema/admin-api';
import {Form} from 'components/Form';

import {fields, validation} from './fields';
import {useCreateReward} from './hook';

const queryShape = {createReward: BooleanParam, editRewardId: StringParam};

export type RewardFormProps = {
  rewardId?: string;
  onReset?: () => void;
  onCreate?: (data: Rewards) => void;
};
type GoalTypeDialogProps = Pick<RewardFormProps, 'onCreate'>;

export const RewardForm = ({rewardId, onReset, onCreate}: RewardFormProps) => {
  const {data, isFetching} = adminApi.endpoints.rewardIndex.useQuery(rewardId ? {rewardId} : skipToken);
  const {onSubmit, initials} = useCreateReward(data, onCreate);

  if (isFetching && !data) return <LinearProgress />;
  return <Form fields={fields} initials={initials} validation={validation} onSubmit={onSubmit} onReset={onReset} />;
};

export const useRewardForm = (): [
  (props: GoalTypeDialogProps) => JSX.Element,
  () => void,
  (userId: string) => void
] => {
  const [{editRewardId, createReward}, setQuery] = useQueryParams(queryShape);
  const open = useCallback(() => setQuery({createReward: true}, 'replace'), []);
  const edit = useCallback((userId: string) => setQuery({editRewardId: userId}, 'replace'), []);
  const close = useCallback(() => setQuery({}, 'replace'), []);

  const FormContainer = useCallback(
    (props: GoalTypeDialogProps) => (
      <Dialog open={Boolean(createReward) || Boolean(editRewardId)} maxWidth="sm" fullWidth onClose={close}>
        <DialogContent dividers>
          <DialogTitle>{editRewardId ? 'Редактирование награды' : 'Новая награда'}</DialogTitle>
          <RewardForm rewardId={editRewardId as string | undefined} onReset={close} {...props} />
        </DialogContent>
      </Dialog>
    ),
    [createReward, editRewardId, close]
  );

  return [FormContainer, open, edit];
};

export default RewardForm;
