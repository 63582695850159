import _ from 'lodash';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import Player from 'video.js/dist/types/player';

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';

import {Icon} from '@iconify/react';
import refreshIcon from '@iconify/icons-material-symbols/refresh-rounded';

import {FC, memo, useEffect, useRef} from 'react';

import {filesApi} from 'api';
import {filePath} from 'utils';
import {VideoTimecodes} from 'modules/files/containers';
import {MakeVideoPreview} from './MakeVideoPreview';

const mimeTypes: Record<string, string> = {
  mp4: 'video/mp4',
  webm: 'video/webm',
  ogg: 'video/ogg',
  ogv: 'video/ogg',
  mov: 'video/quicktime',
  m4v: 'video/x-m4v',
  avi: 'video/x-msvideo',
  wmv: 'video/x-ms-wmv',
  flv: 'video/x-flv',
  m3u8: 'application/x-mpegURL',
};

type VideoPlayerProps = {
  fileId: string;
};

export const VideoPlayer: FC<VideoPlayerProps & {withTimecodes?: boolean; withPreviewUpload?: boolean}> = memo(
  ({fileId, withTimecodes, withPreviewUpload}) => {
    const videoRef = useRef<HTMLDivElement | null>(null);
    const playerRef = useRef<Player | null>(null);
    const {data, refetch} = filesApi.endpoints.fileInfo.useQuery(
      {fileId},
      {
        selectFromResult: ({data}) => {
          if (!data) return {data: undefined};
          const isVideo = _.startsWith(data.type, 'video');
          const extension = (data.location || '').split('.').pop()?.toLowerCase();

          return {
            data: {
              isVideo,
              isConverted: data.s3Status === 'converted',
              sources:
                extension && extension in mimeTypes && isVideo
                  ? [{src: data.location, type: mimeTypes[extension]}]
                  : undefined,
            },
          };
        },
      }
    );

    useEffect(() => {
      if (data?.isVideo && data?.sources && data.isConverted) {
        if (!playerRef.current) {
          const videoElement = document.createElement('video-js');
          videoElement.classList.add('vjs-big-play-centered');
          videoRef.current?.appendChild(videoElement);

          playerRef.current = videojs(
            videoElement,
            {
              fluid: true,
              responsive: true,
              controls: true,
              autoplay: false,
              preload: 'auto',
              sources: data.sources,
              poster: filePath(fileId, 'preview'),
            },
            () => {}
          );
        }
      }
    }, [data, fileId]);

    useEffect(() => {
      const player = playerRef.current;

      return () => {
        if (player && !player.isDisposed()) {
          player.dispose();
          playerRef.current = null;
        }
      };
    }, [playerRef]);

    return (
      <Stack spacing={1}>
        <Box
          data-vjs-player
          width={1}
          sx={{
            '.vjs-poster img': {
              objectFit: 'cover',
            },
          }}
        >
          <div ref={videoRef} />
        </Box>
        {data && !data.isConverted && (
          <Alert
            action={
              <IconButton size="small" onClick={() => refetch()}>
                <Icon icon={refreshIcon} />
              </IconButton>
            }
          >
            Видео обрабатывается. Как только оно будет обработано, оно станет доступно для просмотра
          </Alert>
        )}
        {withPreviewUpload && <MakeVideoPreview fileId={fileId} />}
        {withTimecodes && <VideoTimecodes fileId={fileId} videoPlayerRef={playerRef} />}
      </Stack>
    );
  }
);
