import {QuestPointDto, QuestsPointOptions, QuestsPoints} from '@apiSchema/admin-api';
import {FieldStructure} from 'components/Form/types';
import {tagsColumns} from 'modules/catalogs/pages/TagList';
import {questPointContainerOptions} from 'modules/events/types';
import {questColumns} from 'modules/events/pages/EventsList/columns';
import {
  yupArrayStrings,
  yupBoolean,
  yupBooleanRequired,
  yupObject,
  yupString,
  yupStringJSON,
  yupStringRequired,
} from 'utils/validation';

export const initialValues: Pick<QuestPointDto, 'name'> & {
  options: Pick<QuestsPointOptions, 'isRequired' | 'notInProgress' | 'design'>;
} = {
  name: '',
  options: {
    isRequired: true,
    notInProgress: false,
    design: {
      containerName: 'default',
    },
  },
};

export const fields = (type: QuestsPoints['type']): FieldStructure[] => [
  {
    type: 'select',
    name: 'type',
    disabled: true,
    label: 'Тип шага',
    options: [
      {value: 'quest', name: 'Квест'},
      {value: 'task', name: 'Задание'},
      {value: 'button', name: 'Кнопка'},
      {value: 'publication', name: 'Публикация'},
      {value: 'content', name: 'Материал'},
      {value: 'widget', name: 'Виджет'},
    ],
  },
  {
    type: 'text',
    name: 'name',
    label: 'Название шага',
  },
  {
    type: 'select',
    name: 'options.design.containerName',
    label: 'Вид отображения',
    options: questPointContainerOptions,
  },
  {
    type: 'checkbox',
    name: 'options.isRequired',
    label: 'Обязательный шаг',
  },
  {
    type: 'checkbox',
    name: 'options.notInProgress',
    label: 'Не учитывать в прогрессе',
  },
  {
    showIf: type === 'publication',
    direction: 'column',
    fields: [
      {
        name: 'options.publications.tagsId',
        type: 'dialogSelect',
        getName: item => item.name,
        label: 'Теги для созданной публикации',
        queryName: 'tagsIndex',
        columns: tagsColumns(),
        multiple: true,
        initial: [],
      },
      {
        type: 'checkbox',
        name: 'options.publications.contentRequired',
        label: 'Необходимо написать текст или прикрепить файлы',
      },
    ],
  },
  {
    showIf: type === 'quest',
    direction: 'column',
    fields: [
      {
        name: 'options.quests.questId',
        type: 'dialogSelect',
        label: 'Квест',
        multiple: false,
        queryName: 'questsIndex',
        params: {},
        columns: questColumns(),
        getName: item => item.name,
      },
    ],
  },
  {
    showIf: type === 'button',
    direction: 'column',
    label: 'Настройки кнопки (для веб версии)',
    fields: [
      {
        type: 'text',
        name: 'options.buttons.title',
        label: 'Текст на кнопке',
      },
      // {
      //   type: 'text',
      //   name: 'confirmText',
      //   label: 'Текст подтверждения действия',
      // },
      {
        fields: [
          {
            type: 'select',
            name: 'options.buttons.color',
            label: 'Цвет кнопки',
            options: [
              {value: 'primary', name: 'Основной'},
              {value: 'secondary', name: 'Вторичный'},
              {value: 'success', name: 'Цвет успеха'},
              {value: 'error', name: 'Цвет неудачи :('},
              {value: 'info', name: 'Инормационная'},
              {value: 'warning', name: 'Предупреждающая'},
            ],
          },
          {
            type: 'select',
            name: 'options.buttons.variant',
            label: 'Тип кнопки',
            options: [
              {value: 'text', name: 'Текстовая'},
              {value: 'contained', name: 'Заполненная'},
              {value: 'outlined', name: 'Контурная'},
            ],
          },
        ],
      },
    ],
  },
];

// export const validationSchema = yupObject({
//   name: yupString,
//   options: yupObject({
//     isRequired: yupBooleanRequired,
//     notInProgress: yupBoolean,
//     design: yupObject({
//       containerName: yupStringRequired,
//     }),
//     quests: yupObject({
//       questId: yupStringRequired,
//     }),
//     // publications: yupObject({
//     //   contentRequired: yupBooleanRequired,
//     //   tagsId: yupArrayStrings,
//     // }),
//     // buttons: yupObject({
//     //   title: yupStringRequired,
//     //   color: yupString.nullable(),
//     //   variant: yupString.nullable(),
//     // }),
//   }),
// });

export const validationSchema = (type: QuestsPoints['type']) => {
  const validation: any = yupObject({
    name: yupString,
    options: yupObject({
      isRequired: yupBooleanRequired,
      notInProgress: yupBoolean,
      design: yupObject({
        title: yupString,
        description: yupString,
        customPropsJSONString: yupStringJSON,
      }),
    }),
  });

  if (type === 'button')
    validation.buttons = yupObject({
      title: yupStringRequired,
      color: yupString.nullable(),
      variant: yupString.nullable(),
    });
  if (type === 'quest')
    validation.quests = yupObject({
      questId: yupStringRequired,
    });
  if (type === 'publication')
    validation.publications = yupObject({
      contentRequired: yupBooleanRequired,
      tagsId: yupArrayStrings,
    });

  return validation;
};
