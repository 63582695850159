import {FC} from 'react';
import {Icon} from '@iconify/react';
import openInNew from '@iconify/icons-material-symbols/open-in-new';

import {
  CardContent,
  Card,
  CardHeader,
  Table,
  IconButton,
  TableBody,
  TableRow,
  TableCell,
  Alert,
  Button,
} from '@mui/material';

import {getStreamPath} from 'modules/streams/utils';

import {adminApi, usersApi} from 'api';
import {UserIdProp} from 'modules/users/types';
import {streamsColumns} from 'modules/streams/containers/StreamsList/columns';
import {useDialogSelector} from 'components/DialogSelector';

export const UserStreams: FC<UserIdProp> = ({userId}) => {
  const {streams} = usersApi.endpoints.userIndex.useQuery(
    {userId},
    {selectFromResult: ({data}) => ({streams: data?.account.stats?.streams || []})}
  );
  const [StreamSelector, openStreamSelector] = useDialogSelector();
  const [addToStream] = adminApi.endpoints.streamAddUsers.useMutation();
  const onConfirm = ([streamId]: string[]) => {
    if (!streamId) return;
    addToStream({streamId, body: {usersId: [userId]}});
  };

  return (
    <>
      <StreamSelector
        single
        hideToolbar
        onConfirm={onConfirm}
        queryName="streamsIndex"
        params={{limit: 10000}}
        title="Добавить в поток"
        columns={streamsColumns()}
      />
      <Card>
        <CardHeader title="Потоки" action={<Button onClick={openStreamSelector}>Добавить</Button>} />
        <CardContent sx={{px: 0}}>
          {!streams.length ? (
            <Alert severity="info" sx={{mx: 2}}>
              Пользователь не состоит ни в одном потоке
            </Alert>
          ) : (
            <Table size="small">
              <TableBody>
                {streams.map(stream => (
                  <TableRow key={stream._id!}>
                    <TableCell>{stream.name}</TableCell>
                    <TableCell align="right">
                      <IconButton size="small" href={getStreamPath(stream._id!)} target="_blank">
                        <Icon icon={openInNew} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </CardContent>
      </Card>
    </>
  );
};
