import {FC, useEffect, useState} from 'react';
import {Outlet, useParams} from 'react-router';

import {Grid, Card, Stack, CardContent, useTheme, useMediaQuery} from '@mui/material';
import {skipToken} from '@reduxjs/toolkit/query';
import {APPBAR_DESKTOP} from '__theme/layouts/dashboard/DashboardNavbar';
import {adminApi} from 'api';
import StickyBox from 'components/StickyBox/StickyBox';
import {UserItemHook} from 'components/UserItem';
import {GroupIdProp} from 'modules/groups/types';
import {PublicationsList} from 'modules/publications';
import {UserIdProp, UserInfoWidget} from 'modules/users';

const useIsMobile = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('md'));
};

export const GroupInfoLayout: FC<GroupIdProp> = ({groupId}) => {
  const isMobile = useIsMobile();
  const {postId} = useParams<'postId'>();

  const [, setIsShown] = useState(!isMobile || (isMobile && !Boolean(postId)));
  useEffect(() => {
    if (isMobile && Boolean(postId)) setIsShown(false);
  }, [postId, isMobile]);

  const {data} = adminApi.useGroupIndexQuery(groupId ? {groupId} : skipToken);
  if (!data) return null;
  return (
    <>
      <Grid item md={3} lg={3} sm={12} xs={12}>
        <Stack spacing={2}>
          {/* {isShown ? (
            <PublicationsList
              externalParams={{groupId: data.subGroupsId ? data.subGroupsId.concat([groupId]) : [groupId]}}
            />
          ) : (
            <Card>
              <CardContent>
                <Button size="small" onClick={() => setIsShown(true)} fullWidth>
                  Показать список публикаций
                </Button>
              </CardContent>
            </Card>
          )} */}
          <PublicationsList
            externalParams={{groupId: data.subGroupsId ? data.subGroupsId.concat([groupId]) : [groupId]}}
          />
        </Stack>
      </Grid>
      <Grid item md={5} lg={6} xs={12} sm={12}>
        <StickyBox offsetTop={APPBAR_DESKTOP} offsetBottom={20}>
          <Outlet />
        </StickyBox>
      </Grid>
    </>
  );
};

export const GroupUserInfoLayout: FC<UserIdProp> = ({userId}) => {
  const isMobile = useIsMobile();
  const {postId} = useParams<'postId'>();
  const [, setIsShown] = useState(!isMobile || (isMobile && !Boolean(postId)));
  useEffect(() => {
    if (isMobile && Boolean(postId)) setIsShown(false);
  }, [postId, isMobile]);

  return (
    <>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <Stack spacing={2}>
          <Card>
            <CardContent>
              <UserItemHook userId={userId} />
            </CardContent>
          </Card>
          <Card>
            <CardContent sx={{px: 0}}>
              <UserInfoWidget userId={userId} />
            </CardContent>
          </Card>
          {/* {isShown ? (
            <PublicationsList externalParams={{userId}} />
          ) : (
            <Card>
              <CardContent>
                <Button size="small" onClick={() => setIsShown(true)} fullWidth>
                  Показать список публикаций
                </Button>
              </CardContent>
            </Card>
          )} */}
          <PublicationsList externalParams={{userId}} />
        </Stack>
      </Grid>
      <Grid item sm={12} xs={12} md={5} lg={6}>
        <StickyBox offsetTop={APPBAR_DESKTOP} offsetBottom={20}>
          <Outlet />
        </StickyBox>
      </Grid>
    </>
  );
};
