import {Streams} from '@apiSchema/admin-api';
import {enchanceColumns, nameColumn} from 'utils';

export const streamsColumns = enchanceColumns<Streams>(
  [
    nameColumn,
    {
      field: 'description',
      headerName: 'Описание',
      flex: 1,
    },
  ],
  {hideDates: true}
);
