import {merge} from 'lodash';
import {adminApi} from 'api';
import {QuestsPointOptions} from '@apiSchema/users-api';
import {QuestPointIdProp} from './types';

export const usePatchQuestPoint = ({pointId}: QuestPointIdProp) => {
  const [patchPoint] = adminApi.endpoints.questPointPatch.useMutation();
  const {data: point} = adminApi.endpoints.questPointIndex.useQuery({pointId});
  const onSubmit = (data: Partial<QuestsPointOptions>) => {
    if (!point) return;
    const result = merge({}, point.options, data);
    return patchPoint({
      pointId,
      questPointPartialDto: {
        name: point.name,
        options: result,
      },
    });
  };

  return {patchPoint: onSubmit};
};
