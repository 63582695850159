import {enchanceColumns} from 'utils';
import {Widgets, WidgetsAttrs, WidgetsVisuals} from '@apiSchema/admin-api';

export const widgetsColumns = enchanceColumns<Widgets>([
  {
    field: 'name',
    headerName: 'Название',
    flex: 0.4,
  },
  {
    field: 'type',
    headerName: 'Тип',
    type: 'string',
    flex: 0.2,
  },
]);

export const widgetAttrsColumns = enchanceColumns<WidgetsAttrs>([
  {
    field: 'name',
    headerName: 'Название',
    flex: 0.4,
  },
]);

export const widgetsVisualsColumns = enchanceColumns<WidgetsVisuals>([
  {
    field: 'name',
    headerName: 'Название',
    flex: 0.4,
  },
]);
