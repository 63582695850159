import {IconButton, Stack, Tooltip} from '@mui/material';
import {GridToolbar, GridToolbarContainerProps} from '@mui/x-data-grid';

import {Icon} from '@iconify/react';
import restoreFill from '@iconify/icons-eva/refresh-fill';

export const CustomToolbar = ({onResetFilters, ...props}: GridToolbarContainerProps & {onResetFilters: () => void}) => (
  <Stack alignItems="center" direction="row" py={1} justifyContent="space-between">
    <GridToolbar {...props} />
    <Tooltip title="Сбросить фильтры">
      <IconButton size="small" onClick={onResetFilters}>
        <Icon icon={restoreFill} />
      </IconButton>
    </Tooltip>
  </Stack>
);
