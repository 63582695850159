import {useUnmount} from 'react-use';
import {useAtomCallback} from 'jotai/utils';
import isUndefined from 'lodash/isUndefined';
import {useFieldArray} from 'react-hook-form';
import {memo, useEffect, useCallback, useMemo} from 'react';

import Box from '@mui/system/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import PlusIcon from '@mui/icons-material/Add';
import TrashIcon from '@mui/icons-material/Delete';
import {Templates, TemplatesOptions} from '@apiSchema/users-api';

import {Template} from './Template';
import {AnswerProps} from './types';
import {scriptActionArrayValue} from './atoms';
import {ListPosition} from 'components/__general/components/ListPosition';

type TemplateArrayProps = Omit<AnswerProps, 'index' | 'templateOptionId'> & {
  min: number;
  max?: number;
  template: Templates;
  options: TemplatesOptions[];
};

export const TemplateArray = memo(({min, max, template, scriptActionId, options}: TemplateArrayProps) => {
  const {fields, replace, append, remove} = useFieldArray({name: scriptActionId});
  const optionsIds = options.map(item => `${item._id}`);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const params = useMemo(() => ({actionId: scriptActionId, min, options: optionsIds}), [scriptActionId, min]);
  const getInitials = useAtomCallback(
    useCallback(
      get => {
        const values = get(scriptActionArrayValue(params));
        replace(values);
      },
      [replace, params]
    )
  );

  useEffect(() => {
    getInitials();
  }, [getInitials]);

  useUnmount(() => {
    scriptActionArrayValue.remove(params);
  });

  return (
    <>
      {fields.map((item, index) => (
        <Stack key={item.id} direction="row" spacing={2.75} alignItems="top">
          <Box height={48} display="flex" alignItems="center">
            <ListPosition index={index} />
          </Box>
          <Box flexGrow={1}>
            <Template options={options} index={index} template={template} scriptActionId={scriptActionId} />
          </Box>
          {fields.length > min && index > 0 && index > min - 1 && (
            <Button variant="contained" size="small" color="error" onClick={() => remove(index)} sx={{px: 0.5}}>
              <Box component={TrashIcon} color="error.main" width={24} height={24} />
            </Button>
          )}
        </Stack>
      ))}
      {((!!max && fields.length < max) || isUndefined(max)) && (
        <Box>
          <Button size="small" color="secondary" variant="contained" onClick={() => append({})}>
            <Box component={PlusIcon} color="primary.500" width={16} height={16} mr={0.5} />
            Еще
          </Button>
        </Box>
      )}
    </>
  );
});
