import * as yup from 'yup';
import {yupBooleanRequired, yupObject, yupStringOfOptionsRequired, yupStringRequired} from 'utils/validation';

import {FieldStructure} from 'components/Form/types';
import {loginTypesOptions} from 'modules/users/utils';

export const passFields: FieldStructure[] = [
  {
    type: 'text',
    variant: 'password',
    name: 'password',
    label: 'Пароль',
  },
  {
    type: 'text',
    variant: 'password',
    name: 'passwordConfirm',
    label: 'Подверджение пароля',
  },
];

export const passInitials = {
  password: '',
  passwordConfirm: '',
};

export const passValidation = yupObject({
  password: yupStringRequired,
  passwordConfirm: yup
    .mixed()
    .required()
    .nullable()
    .oneOf([yup.ref('password')], 'Пароли не совпадают'),
});

export const fields: FieldStructure[] = [
  {
    type: 'select',
    name: 'type',
    label: 'Тип',
    options: loginTypesOptions,
  },
  {
    type: 'text',
    name: 'value',
    label: 'Значение',
  },
  {
    type: 'checkbox',
    name: 'enabled',
    label: 'Активный',
  },
];

export const initialValues = {enabled: true, value: '', type: 'plain' as const};

export const validation = yupObject({
  value: yupStringRequired,
  enabled: yupBooleanRequired,
  type: yupStringOfOptionsRequired(loginTypesOptions),
});
