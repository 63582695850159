import React, {FC, memo} from 'react';
import {Link} from 'react-router-dom';
import {DragDropContext, Droppable} from '@hello-pangea/dnd';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';

import {Icon} from '@iconify/react';
import plusOutline from '@iconify/icons-eva/plus-outline';
import externalLink from '@iconify/icons-eva/external-link-outline';
import chevronDown from '@iconify/icons-eva/chevron-down-fill';

import {adminApi} from 'api';
import {usePositions} from 'utils';

import {QuestIdProp, QuestPointIdProp} from 'modules/events/types';
import {getQuestPath} from 'modules/events/utils';

import {useQuestPointHistory, useQuestStructureContext} from './context';
import {QuestStructurePoint} from './QuestStructurePoint';

export const QuestStructureBranch: FC<QuestIdProp & Partial<QuestPointIdProp>> = memo(({questId, pointId}) => {
  const {togglePoint, pointsId: expandedPoints} = useQuestPointHistory();
  const collapsed = !expandedPoints.includes(pointId || '');

  const {openPointMenu} = useQuestStructureContext();
  const onPointCreateClick = (e: React.MouseEvent<HTMLButtonElement>) => openPointMenu(e, questId);

  const [changePositions] = adminApi.endpoints.questPointsChangePositions.useMutation();
  const {pointsId} = adminApi.endpoints.questPointsIndex.useQuery(
    {questId},
    {selectFromResult: ({data = []}) => ({pointsId: data.map(item => item._id!)})}
  );
  const {positions, onDragEnd} = usePositions(pointsId, newPositions =>
    changePositions({questId, body: {pointsId: newPositions}})
  );

  return (
    <>
      {!pointId && (
        <Button fullWidth startIcon={<Icon icon={plusOutline} />} onClick={onPointCreateClick as any} size="small">
          Добавить точку квеста
        </Button>
      )}
      {pointId && (
        <Stack direction="row" justifyContent="space-between" mt={1}>
          <IconButton size="small" onClick={() => togglePoint(pointId)} disabled={!positions.length}>
            <Icon icon={chevronDown} style={{transform: `rotate(${collapsed ? 0 : -180}deg)`}} />
          </IconButton>

          <Stack direction="row" justifyContent="flex-end">
            <IconButton size="small" onClick={onPointCreateClick}>
              <Icon icon={plusOutline} />
            </IconButton>

            <Link to={getQuestPath(questId)} target="_blank">
              <IconButton size="small">
                <Icon icon={externalLink} />
              </IconButton>
            </Link>
          </Stack>
        </Stack>
      )}

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={questId}>
          {({innerRef, droppableProps, placeholder}) => (
            <div ref={innerRef} {...droppableProps}>
              <Collapse in={!collapsed}>
                {!collapsed && (
                  <Stack spacing={1} pl={pointId ? 2 : 0} my={1}>
                    {positions.map(pointId => (
                      <QuestStructurePoint key={pointId} pointId={pointId} />
                    ))}
                  </Stack>
                )}
              </Collapse>
              {placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
});
