import {Icon} from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';

import {Button} from '@mui/material';

import {ListPage} from 'components/ListPage';
import {useTagForm} from 'modules/catalogs/containers';

import {tagsColumns} from './columns';

export const TagListPage = () => {
  const [TagForm, openTagForm, editTag] = useTagForm();
  return (
    <>
      <TagForm />
      <ListPage
        columns={tagsColumns()}
        onItemClick={row => editTag(row._id)}
        queryName="tagsIndex"
        heading="Теги"
        params={{limit: 10000}}
        action={
          <Button variant="contained" startIcon={<Icon icon={plusFill} />} onClick={openTagForm}>
            Добавить тег
          </Button>
        }
      />
    </>
  );
};

export default TagListPage;
