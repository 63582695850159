import {useCallback, useEffect} from 'react';
import {useNavigate} from 'react-router';

import {adminApi} from 'api';
import {ReactionsBlocks} from '@apiSchema/admin-api';

import {ReactionFormDataType, initials} from './fields';

export const useCreateReaction = (data?: ReactionsBlocks) => {
  const navigate = useNavigate();
  const [update] = adminApi.endpoints.reactionsBlockUpdate.useMutation();
  const [create, {data: result}] = adminApi.endpoints.reactionsBlocksAdd.useMutation();
  const onSubmit = useCallback(
    (inputData: ReactionFormDataType) => {
      const reactionsBlockDto = {
        ...inputData,
        rewardValues: inputData.rewardValues.map(i => ({rewardId: i, value: 0})),
      };
      if (data && data._id) return update({blockId: data._id, reactionsBlockDto});
      else return create({reactionsBlockDto});
    },
    [data, update, create]
  );

  useEffect(() => {
    if (!data?._id && result) navigate(`${result._id}`);
  }, [result, data?._id, navigate]);

  return {initials: initials(data), onSubmit};
};

export const useRewardValuesList = () => {
  const {data} = adminApi.endpoints.rewardsIndex.useQuery({});

  return (data?.data || []).map(i => ({
    name: i.name,
    type: i.type,
    value: i._id,
  }));
};
