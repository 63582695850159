import {RewardDto} from '@apiSchema/admin-api';
import {
  yupBooleanRequired,
  yupNumberRequired,
  yupObject,
  yupString,
  yupStringOfOptionsRequired,
  yupStringRequired,
  yupUndefined,
} from 'utils/validation';
import {FieldStructure} from 'components/Form/types';
import {rewardTypeOptions} from 'modules/tasks/utils';

export const fields: FieldStructure<RewardDto>[] = [
  {
    fields: [
      {
        type: 'text',
        name: 'name',
        label: 'Название',
      },
      {
        type: 'select',
        name: 'type',
        label: 'Тип награды',
        options: rewardTypeOptions,
      },
    ],
  },
  {
    label: 'Настройки валюты',
    showIf: values => values.type === 'currency',
    fields: [
      {
        type: 'text',
        name: 'currency.code',
        label: 'Код валюты',
      },
      {
        type: 'text',
        name: 'currency.isoNumber',
        label: 'ISO код валюты',
        variant: 'number',
      },
    ],
  },
  {
    type: 'text',
    name: 'description',
    label: 'Описание',
    variant: 'text',
    minRows: 2,
  },
  {
    type: 'text',
    name: 'backgroundColor',
    label: 'Цвет бэкграунда экрана награды',
  },
  {
    showIf: props => props.type === 'currency',
    fields: [
      {
        type: 'text',
        name: 'symbol',
        label: 'Символ',
      },
      {
        type: 'text',
        name: 'expKoef',
        label: 'Коэффициент',
        variant: 'number',
      },
    ],
  },
  {
    direction: 'row',
    fields: [
      {
        type: 'file',
        name: 'fileId',
        variant: 'image',
        label: 'Изображение',
      },
      {
        type: 'file',
        name: 'inactiveFileId',
        variant: 'image',
        label: 'Неактивное изображение',
      },
    ],
  },

  // {
  //   type: 'checkbox',
  //   name: 'isSystem',
  //   label: 'Только для системного использования',
  // },
  // {
  //   type: 'checkbox',
  //   name: 'isPublic',
  //   label: 'Доступно для публичного использования',
  // },
  {
    type: 'checkbox',
    name: 'enabled',
    label: 'Активный',
  },
];

export const initialValues: RewardDto = {
  name: '',
  description: '',
  backgroundColor: '',
  symbol: '',
  isSystem: false,
  isPublic: false,
  enabled: true,
  type: 'default',
  fileId: null,
  inactiveFileId: null,
  currency: undefined,
};

export const validation = yupObject({
  name: yupStringRequired,
  currency: yupObject({
    code: yupStringRequired,
    isoNumber: yupNumberRequired,
  }).when('type', ([type], schema) => (type === 'currency' ? schema.required() : yupUndefined)),
  description: yupString,
  backgroundColor: yupString,
  symbol: yupString,
  fileId: yupString.nullable(),
  inactiveFileId: yupString.nullable(),
  type: yupStringOfOptionsRequired(rewardTypeOptions),
  isSystem: yupBooleanRequired,
  isPublic: yupBooleanRequired,
  enabled: yupBooleanRequired,
});
