import {useMeasure} from 'react-use';
import {LazyLoadImage} from 'react-lazy-load-image-component';

import {SxProps} from '@mui/system';
import {Theme, Box} from '@mui/material';

import {FileCropApiArg} from '@apiSchema/files-api';
import {getRatioMultiplier, croppedImagePath} from 'utils';

export const LazyImage = ({
  ratio = '1:1',
  width,
  fileId,
  alt,
  blurred,
  withPreview,
  borderRadius,
  sx,
}: {
  width?: number;
  alt: string;
  fileId: string;
  ratio?: FileCropApiArg['ratio'];
  withPreview?: boolean;
  borderRadius?: number;
  sx?: SxProps<Theme>;
  blurred?: boolean;
}) => {
  const [ref, {width: boxWidth}] = useMeasure<HTMLDivElement>();
  const height = boxWidth / getRatioMultiplier(ratio);

  return (
    <Box
      ref={ref}
      sx={{
        borderRadius,
        position: 'relative',
        overflow: 'hidden',
        '& span': {
          display: 'block !important',
          filter: blurred ? 'blur(6px) !important' : undefined,
          transform: blurred ? 'scale(1.2)' : undefined,
        },
        '&::after': {
          top: 0,
          left: 0,
          borderRadius,
          content: '""',
          width: 1 / 1,
          height: 1 / 1,
          display: 'block',
          position: 'absolute',
          boxShadow: theme => theme.customShadows.z16,
        },
        ...sx,
      }}
    >
      {withPreview && (
        <Box top={0} left={0} position="absolute" width="100%" height="100%">
          <LazyLoadImage
            alt={alt}
            effect="blur"
            width={boxWidth}
            height={height}
            src={croppedImagePath(fileId, {ratio, maxWidth: 50})}
          />
        </Box>
      )}
      <LazyLoadImage
        alt={alt}
        effect="blur"
        width={boxWidth}
        height={height}
        src={croppedImagePath(fileId, {ratio, maxWidth: width})}
      />
    </Box>
  );
};
