import {useCallback} from 'react';
import {useAppDispatch} from 'store';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {pick} from 'lodash';

import {adminApi} from 'api';
import {UsersLogins} from '@apiSchema/admin-api';

import {initialValues} from './fields';

const update = adminApi.endpoints.userLoginUpdate.initiate;
const create = adminApi.endpoints.userLoginsAdd.initiate;

export const useUserLogin = (userId: string, loginId?: string) => {
  const dispatch = useAppDispatch();
  const {data, isFetching} = adminApi.useUserLoginIndexQuery(loginId ? {userId, loginId} : skipToken);
  const onSubmit = useCallback(
    (inputData: UsersLogins) => {
      if (data) return dispatch(update({userId, loginId: data._id as string, usersLogins: inputData}));
      else return dispatch(create({usersLogins: inputData, userId}));
    },
    [data, userId, dispatch]
  );

  const initials = data ? (pick(data, Object.keys(initialValues)) as UsersLogins) : initialValues;

  return {initials, onSubmit, isFetching};
};

export default useUserLogin;
