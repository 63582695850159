import {FC} from 'react';

import {usersApi} from 'api';
import {WidgetInfo, WidgetInfoApiArg} from '@apiSchema/users-api';

import {SliderWidget} from './SliderWidget';

export const Widget: FC<WidgetInfoApiArg> = props => {
  const {type} = usersApi.endpoints.widgetInfo.useQueryState(props, {
    selectFromResult: ({data}: {data?: WidgetInfo}) => ({
      type: data?.widget?.type,
    }),
  });

  if (type === 'slider') return <SliderWidget {...props} />;
  return null;
};
