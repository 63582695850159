// i18n
import './__theme/locales/i18n';

// highlight
import './__theme/utils/highlight';

// scroll bar
import 'simplebar/src/simplebar.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// lightbox
import 'react-image-lightbox/style.css';

// editor
import 'react-quill/dist/quill.snow.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3';

// lazy image
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import React from 'react';
import ReactDOM from 'react-dom/client';
// import {BrowserRouter} from 'react-router-dom';
import {HelmetProvider} from 'react-helmet-async';
import {PersistGate} from 'redux-persist/lib/integration/react';
// material
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers';
// import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
// redux
import {persistor} from './__theme/redux/store';
// contexts
import {SettingsProvider} from './__theme/contexts/SettingsContext';
import {CollapseDrawerProvider} from './__theme/contexts/CollapseDrawerContext';
// components
import LoadingScreen from './__theme/components/LoadingScreen';

// Check our docs
// https://docs-minimals.vercel.app/authentication/ts-version

import {AuthProvider} from './__theme/contexts/JWTContext';
// import { AuthProvider } from './__theme/contexts/AwsCognitoContext';
// import { AuthProvider } from './__theme/contexts/Auth0Context';
// import { AuthProvider } from './__theme/contexts/FirebaseContext';

//
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {ru} from 'date-fns/locale';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <HelmetProvider>
      {/* <ReduxProvider store={store}> */}
      <PersistGate loading={<LoadingScreen />} persistor={persistor}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
          <SettingsProvider>
            <CollapseDrawerProvider>
              <AuthProvider>
                <App />
              </AuthProvider>
            </CollapseDrawerProvider>
          </SettingsProvider>
        </LocalizationProvider>
      </PersistGate>
      {/* </ReduxProvider> */}
    </HelmetProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
