import {last} from 'lodash';
import {useToggle} from 'react-use';
import {useCallback, useState} from 'react';
import {Button, Dialog, Stack, DialogContent, DialogTitle} from '@mui/material';

import {adminApi} from 'api';
import {DataList} from 'components/DataList';
import {DialogSelectorContainerProps, UseDialogSelector} from './types';

const InnerContainer = <T extends keyof typeof adminApi.endpoints>({
  title,
  params,
  columns,
  onClose,
  onConfirm,
  queryName,
  disableLimit,
  getData,
  actions,
  confirmText,
  single = false,
  initialSelection = [],
  hideFooterPagination,
  hideToolbar,
}: DialogSelectorContainerProps<T>) => {
  const [selectedIds, setSelected] = useState<(string | number)[]>(initialSelection);
  const onSelect = useCallback(
    (ids: (string | number)[]) => {
      setSelected(single ? [last(ids) as string | number] : ids);
    },
    [setSelected, single]
  );
  const confirmHandler = useCallback(async () => {
    await onConfirm(selectedIds.map(item => item.toString()));
    if (onClose) onClose();
  }, [selectedIds, onConfirm, onClose]);

  return (
    <>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          {title}
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <Button variant="contained" size="small" onClick={confirmHandler}>
              {confirmText || 'Подтвердить выбор'}
            </Button>
            {actions?.map(({title, onClick, color, variant = 'text'}) => (
              <Button
                variant={variant}
                size="small"
                onClick={() => onClick(selectedIds)}
                color={color}
                key={title}
                disabled={!selectedIds.length}
              >
                {title}
              </Button>
            ))}
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{p: 0}} dividers>
        <DataList
          params={params}
          getData={getData}
          columns={columns}
          onSelect={onSelect}
          queryName={queryName}
          selection={selectedIds}
          disableLimit={disableLimit}
          hideToolbar={hideToolbar}
          hideFooterPagination={hideFooterPagination}
        />
      </DialogContent>
    </>
  );
};

export function useDialogSelector<T extends keyof typeof adminApi.endpoints>(): UseDialogSelector<T> {
  const [open, toggleOpen] = useToggle(false);
  const closeDialog = useCallback(() => toggleOpen(false), [toggleOpen]);
  const openDialog = useCallback(() => toggleOpen(true), [toggleOpen]);

  const Selector = useCallback(
    <T extends keyof typeof adminApi.endpoints>(props: DialogSelectorContainerProps<T>) => (
      <Dialog open={open} onClose={closeDialog} maxWidth="md" fullWidth>
        <InnerContainer {...props} onClose={closeDialog} />
      </Dialog>
    ),
    [closeDialog, open]
  );

  return [Selector, openDialog];
}

export default useDialogSelector;
