import {useState} from 'react';

import Box from '@mui/material/Box';
import {DataGrid, GridColDef, GridToolbar} from '@mui/x-data-grid';

import {adminApi} from 'api';
import {getRowId as defaultGetRowId} from 'utils';

import {useList, useIsQueryExists, useIsUserQueryExists} from './hook';
import {
  APIEndpoint,
  APINames,
  DataListCommonProps,
  DataListProps,
  DataListProviderProps,
  EndpointNames,
  ListPageCommonProps,
} from './types';
import {CustomPagination} from './CustomPagination';

function DataList<Query, Result>({
  sx,
  debug,
  params,
  columns,
  getData,
  onSelect,
  useFetch,
  selection,
  hideToolbar,
  onRowClick,
  disableLimit,
  checkboxSelection,
  hideFooterPagination,
  Toolbar,
  customPagination,
  density = 'standard',
  getRowId = defaultGetRowId,
}: DataListProps<Query, Result>) {
  const [pageSize, setPageSize] = useState(10);
  const {data, isLoading, isFetching} = useList<Query, Result>({useFetch, params, debug, getData, disableLimit});

  return (
    <Box display="flex" height="100%" sx={sx}>
      <Box flexGrow={1} justifyContent="center" alignItems="flex-start">
        <DataGrid
          rows={data}
          autoHeight
          density={density}
          getRowId={getRowId}
          pageSize={pageSize}
          onRowClick={onRowClick}
          loading={isLoading || isFetching}
          onSelectionModelChange={onSelect}
          rowsPerPageOptions={[10, 20, 50, 100]}
          hideFooterPagination={hideFooterPagination}
          disableSelectionOnClick={Boolean(onRowClick)}
          columns={columns.filter(Boolean) as GridColDef[]}
          checkboxSelection={checkboxSelection ?? !!onSelect}
          onPageSizeChange={pageSize => setPageSize(pageSize)}
          selectionModel={isLoading || isFetching ? undefined : selection}
          components={{
            Toolbar: hideToolbar ? undefined : Toolbar || GridToolbar,
            Pagination: customPagination ? CustomPagination : undefined,
          }}
          sx={{
            '& .MuiDataGrid-toolbarContainer': {
              backgroundColor: 'transparent',
              display: hideToolbar ? 'none' : undefined,
            },
            '& .MuiDataGrid-footerContainer': {
              backgroundColor: 'transparent',
              display: hideFooterPagination ? 'none' : undefined,
            },
          }}
        />
      </Box>
    </Box>
  );
}

function DataListProvider<T extends keyof typeof adminApi.endpoints>({queryName, ...props}: DataListProviderProps<T>) {
  const useFetch = useIsQueryExists(queryName);
  if (!useFetch) return null;
  return <DataList useFetch={useFetch as any} {...props} />;
}

export function UsersDataListProvider<
  APIName extends APINames,
  EndpointName extends EndpointNames[APIName],
  Item extends object = any
>(props: APIEndpoint<APIName, EndpointName, Item> & ListPageCommonProps & DataListCommonProps<Item>) {
  const {api, endpoint} = props;
  const useFetch = useIsUserQueryExists({api, endpoint});
  if (!useFetch) return null;
  return <DataList useFetch={useFetch as any} {...(props as any)} />;
}

export default DataListProvider;
