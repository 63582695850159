import {enchanceColumns, nameColumn} from 'utils';

export const tasksColumns = enchanceColumns([
  nameColumn,
  {
    flex: 0.1,
    field: 'status',
    headerName: 'Статус',
    type: 'singleSelect',
    valueOptions: ['draft', 'checking', 'active', 'close', 'archived'],
  },
]);

export default tasksColumns;
