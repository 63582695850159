import {FC} from 'react';
import {DragDropContext, Draggable, Droppable, DropResult} from '@hello-pangea/dnd';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import {adminApi} from 'api';
import {WidgetIdProp} from 'modules/widgets/types';

import {WidgetAttributeForm} from '../WidgetAttributeForm';
import {useAttributeDeletedParam} from 'modules/widgets/utils';

export const WidgetAttributesList: FC<WidgetIdProp> = ({widgetId}) => {
  const [showDeleted] = useAttributeDeletedParam();
  const [patchPositions] = adminApi.endpoints.widgetAttrsChangePositions.useMutation();
  const {data: attrs} = adminApi.endpoints.widgetAttrsIndex.useQuery({widgetId, isDeleted: !!showDeleted});
  if (!attrs) return null;

  const changePositions = (data: DropResult) => {
    if (data.destination) {
      const attrsId = attrs.map(i => `${i._id}`);
      const removed = attrsId.splice(data.source.index, 1);
      attrsId.splice(data.destination.index, 0, removed[0]);
      patchPositions({widgetId, widgetAttrsPositions: {attrsId}});
    }
  };
  return (
    <DragDropContext onDragEnd={changePositions}>
      <Droppable droppableId={`widget_attributes${widgetId}`}>
        {p => (
          <Stack spacing={2} ref={p.innerRef} {...p.droppableProps}>
            {attrs.map((attr, index) => (
              <Draggable key={attr._id} draggableId={`${attr._id}`} index={index}>
                {p => (
                  <Box ref={p.innerRef} {...p.draggableProps} {...p.dragHandleProps}>
                    <WidgetAttributeForm attrId={attr._id!} widgetId={widgetId} />
                  </Box>
                )}
              </Draggable>
            ))}
          </Stack>
        )}
      </Droppable>
    </DragDropContext>
  );
};
