import {FC, PropsWithChildren} from 'react';

import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Paper, {PaperProps} from '@mui/material/Paper';
import {Box, Alert, Table, TableBody, TableCell, TableRow} from '@mui/material';

import {UserItem} from 'components/UserItem';

import {adminApi} from 'api';
import {UserIdProp} from 'modules/users/types';
import {GroupsIdProp} from 'modules/groups/types';
import {loginTypesOptions} from 'modules/users/utils';

import {useUserInfoWidget} from './hook';
import {UserContract} from '../UserContract';

const UserSubscription: FC<UserIdProp> = ({userId}) => {
  adminApi.endpoints.userIndex.useQuerySubscription({userId});
  return null;
};

export const UserInfoWidget: FC<UserIdProp & Partial<GroupsIdProp> & Pick<PaperProps, 'sx'> & {emptyText?: string}> = ({
  userId,
  groupsId,
  emptyText,
}) => {
  const {data: widgetData} = useUserInfoWidget({userId, groupsId});

  if (!widgetData.length) {
    if (emptyText)
      return (
        <Alert severity="info" sx={{mx: 2}}>
          {emptyText}
        </Alert>
      );
    return null;
  }
  return (
    <>
      <Table size="small">
        <TableBody>
          {widgetData.filter(Boolean).map(value => (
            <TableRow key={value?.name}>
              <TableCell>{value?.name}</TableCell>
              <TableCell align="right">{value?.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box sx={{px: 2, mt: 1}}>
        <UserContract userId={userId} />
      </Box>
    </>
  );
};

export const UserInfo: FC<PropsWithChildren<UserIdProp & Partial<GroupsIdProp> & Pick<PaperProps, 'sx'>>> = ({
  userId,
  groupsId,
  sx = {},
  children,
}) => {
  const {data: user} = adminApi.endpoints.userIndex.useQuery({userId});

  if (!user) return null;
  const {logins} = user;
  return (
    <Paper
      py={2}
      elevation={1}
      component={Stack}
      sx={{
        width: 1,
        position: 'relative',
        boxShadow: theme => theme.customShadows.z1,
        '&:hover': {
          boxShadow: theme => theme.customShadows.z16,
        },
        ...sx,
      }}
    >
      <UserSubscription userId={userId} />
      <Box px={2} mb={2}>
        <UserItem user={user} />
      </Box>
      <UserInfoWidget userId={userId} groupsId={groupsId} />
      <Table size="small">
        <TableBody>
          {user.tgUsername && (
            <TableRow>
              <TableCell>Телеграм</TableCell>
              <TableCell align="right">
                <Link target="_blank" href={user.tgUsername}>
                  {user.tgUsername}
                </Link>
              </TableCell>
            </TableRow>
          )}
          {logins?.map(login => (
            <TableRow key={login._id!}>
              <TableCell>{loginTypesOptions.find(option => option.value === login.type)?.name}</TableCell>
              <TableCell align="right">{login.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {children}
    </Paper>
  );
};
