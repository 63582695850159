import {FC} from 'react';
import {adminApi} from 'api';

import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import {ImagesGallery} from 'components/ImagesGallery';
import {PostIdProp} from 'modules/publications/types';
import {useGetStageInfo} from 'modules/publications/utils';

import {PublicationWidgetContent} from './PublicationWidgetContent';
import {PublicationDeleteRestore} from './PublicationDeleteRestore';
import {PublicationView} from './PublicationView';
import {CardActions} from '@mui/material';

const PublicationBodyContent: FC<PostIdProp & {noCard?: boolean}> = ({postId, noCard}) => {
  const {content, filesId} = adminApi.endpoints.publicationIndex.useQuery(
    {postId},
    {
      selectFromResult: ({data}) => ({
        content: data?.content,
        filesId: data?.files?.map(item => item._id!),
      }),
    }
  );
  const withFiles = !!filesId && Boolean(filesId?.length);
  const {stageId} = useGetStageInfo({postId});
  const Content = (
    <>
      {!withFiles && !content && !stageId && <Typography variant="body2">Содержание отсутствует</Typography>}
      {content && <Typography variant="body2">{content}</Typography>}
      {withFiles && <ImagesGallery filesId={filesId} />}
      {stageId && <PublicationWidgetContent postId={postId} stageId={stageId} />}
    </>
  );

  if (noCard) return Content;
  return (
    <Card>
      <CardHeader title="Публикация" />
      <CardContent>{Content}</CardContent>
      <CardActions sx={{justifyContent: 'flex-end', pb: 2}}>
        <PublicationView postId={postId} />
        <PublicationDeleteRestore postId={postId} />
      </CardActions>
    </Card>
  );
};

export const PublicationBody: FC<PostIdProp & {noCard?: boolean}> = props => {
  const {postId} = props;
  const {data} = adminApi.endpoints.publicationIndex.useQuery({postId});
  if (!data) return null;
  return <PublicationBodyContent {...props} />;
};
