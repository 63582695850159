import {Typography, TypographyProps} from '@mui/material';
import _ from 'lodash';
import numeral from 'numeral';

import {VisualComponent} from '../types';

export type TypographyComponentProps = Pick<TypographyProps, 'variant'>;

export const TypographyComponent: VisualComponent<TypographyComponentProps> = ({
  value = '',
  type,
  index,
  options = {},
}) => {
  let formattedValue: string | number = value;
  if (type === 'number') formattedValue = numeral(value).format('0,0');
  else if (type === 'score') formattedValue = parseInt(value, 10) + 1;

  const Content = <Typography {..._.pick(options || {}, ['variant'])}>{formattedValue}</Typography>;
  if (_.isUndefined(index)) return Content;
  return (
    <ul>
      <li>{Content}</li>
    </ul>
  );
};
