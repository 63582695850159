import {Link} from 'react-router-dom';

import {Icon} from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import trashOutline from '@iconify/icons-eva/trash-outline';
import restoreFill from '@iconify/icons-eva/refresh-fill';
import {Button, IconButton} from '@mui/material';

import {adminApi} from 'api';
import {PATH_APP} from 'router/routes';

import {ListPage} from 'components/ListPage';
import {rolesColumns} from './columns';

export const AccessListPage = () => {
  const [patch] = adminApi.endpoints.accessElemPatch.useMutation();
  const toggleEnabled = (accessId: string, flag?: boolean) => patch({accessId, accessPartialDto: {enabled: !flag}});

  return (
    <ListPage
      columns={rolesColumns(({row}) => (
        <div onClick={e => e.stopPropagation()}>
          <IconButton size="small" onClick={() => toggleEnabled(`${row._id}`, row.enabled)}>
            <Icon icon={row.enabled ? trashOutline : restoreFill} />
          </IconButton>
        </div>
      ))}
      queryName="accessIndex"
      params={{limit: 10000}}
      heading="Настройки доступа"
      action={
        <Button
          variant="contained"
          component={Link}
          to={PATH_APP.settings.access.create}
          startIcon={<Icon icon={plusFill} />}
        >
          Новая роль
        </Button>
      }
    />
  );
};

export default AccessListPage;
