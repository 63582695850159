import {lazy} from 'react';
import {Navigate, RouteObject} from 'react-router';

import {PATH_APP} from 'router/routes';
import {Access, Blockers} from '@apiSchema/admin-api';

import {AccessGuard} from 'modules/auth/containers';

import {BreadcrumbsProvider} from 'components/Breadcrumbs';
import {BreadcrumbsSet} from 'components/Breadcrumbs/BreadcrumbsSet';
import IsExistsContainer from 'components/IsExistsContainer';

import {useGetBlocker} from './pages/BlockerItemPage/hook';
import {useGetAccessElem} from './pages/AccessItemPage/hook';
import {useGetSettings} from './pages/MainSettingsPage/hook';

import LoadingScreen from '__theme/components/LoadingScreen';

const PolicyPage = lazy(() => import('./pages/PolicyPage'));
const AccessList = lazy(() => import('./pages/AccessList'));
const AccessItemPage = lazy(() => import('./pages/AccessItemPage'));
const AgreementPage = lazy(() => import('./pages/AgreementPage'));
const BlockersListPage = lazy(() => import('./pages/BlockersList'));
const BlockerItemPage = lazy(() => import('./pages/BlockerItemPage'));
const MainSettingsPage = lazy(() => import('./pages/MainSettingsPage'));

const router: RouteObject = {
  path: 'settings',
  element: <BreadcrumbsProvider name="Настройки" href={PATH_APP.settings.root} />,
  children: [
    {index: true, element: <Navigate to="./main" replace />},
    {
      path: 'main',
      element: (
        <AccessGuard endpoint="settingsIndex">
          <BreadcrumbsSet name="Основные настройки" href={PATH_APP.settings.main}>
            <IsExistsContainer useFetch={useGetSettings} LoadingComponent={<LoadingScreen />}>
              <MainSettingsPage />
            </IsExistsContainer>
          </BreadcrumbsSet>
        </AccessGuard>
      ),
    },
    {
      path: 'blockers',
      element: (
        <AccessGuard endpoint="blockersIndex">
          <BreadcrumbsSet name="Блокировки" href={PATH_APP.settings.blockers.list} />
        </AccessGuard>
      ),
      children: [
        {index: true, element: <BlockersListPage />},
        {
          path: 'create',
          element: (
            <AccessGuard endpoint="blockersAdd">
              <BreadcrumbsSet name="Новая блокировка">
                <BlockerItemPage />
              </BreadcrumbsSet>
            </AccessGuard>
          ),
        },
        {
          path: ':blockerId',
          element: (
            <AccessGuard endpoint="blockerIndex">
              <IsExistsContainer<Blockers> useFetch={useGetBlocker}>
                {data => <BreadcrumbsSet name={`${data?.name}`} />}
              </IsExistsContainer>
            </AccessGuard>
          ),
          children: [{index: true, element: <BlockerItemPage />}],
        },
      ],
    },
    {
      path: 'access',
      element: (
        <AccessGuard endpoint="accessIndex">
          <BreadcrumbsSet name="Настройки доступа" href={PATH_APP.settings.access.root} />
        </AccessGuard>
      ),
      children: [
        {index: true, element: <AccessList />},
        {
          path: 'create',
          element: (
            <AccessGuard endpoint="accessAdd">
              <BreadcrumbsSet name="Новая роль">
                <AccessItemPage />
              </BreadcrumbsSet>
            </AccessGuard>
          ),
        },
        {
          path: ':accessId',
          element: (
            <AccessGuard endpoint="accessElemIndex">
              <IsExistsContainer<Access> useFetch={useGetAccessElem}>
                {data => <BreadcrumbsSet name={`${data.name}`} />}
              </IsExistsContainer>
            </AccessGuard>
          ),
          children: [{index: true, element: <AccessItemPage />}],
        },
      ],
    },
    {
      path: 'agreement',
      element: (
        <AccessGuard endpoint="settingsIndex">
          <BreadcrumbsSet name="Пользовательское соглашение" href={PATH_APP.settings.agreement}>
            <AgreementPage />
          </BreadcrumbsSet>
        </AccessGuard>
      ),
    },
    {
      path: 'policy',
      element: (
        <AccessGuard endpoint="settingsIndex">
          <BreadcrumbsSet name="Согласие на обработку персональных данных" href={PATH_APP.settings.policy}>
            <PolicyPage />
          </BreadcrumbsSet>
        </AccessGuard>
      ),
    },
  ],
};

export default router;
