import {QuestsPoints} from '@apiSchema/admin-api';
import {enchanceColumns, nameColumn} from 'utils';

export const questPointColumns = enchanceColumns<QuestsPoints>([
  nameColumn,
  {
    field: 'type',
    headerName: 'Тип действия',
    type: 'string',
    flex: 0.2,
  },
  {
    field: 'positionNumber',
    headerName: 'Позиция в структуре',
    type: 'number',
    flex: 0.2,
  },
]);
