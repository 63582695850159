import _ from 'lodash';
import {flatten} from 'flat';
import {createSelector} from '@reduxjs/toolkit';

import {usersApi} from 'api';
import {WidgetsInfoApiArg, WidgetsVisualsDesigns} from '@apiSchema/users-api';
import {WidgetVisualsDesignCustomOptions} from './types';

export const selectWidgetValues = createSelector(
  (state: any) => state,
  (_: any, query: WidgetsInfoApiArg) => query,
  (_: any, __: WidgetsInfoApiArg, widgetId?: string) => widgetId,
  (_: any, __: WidgetsInfoApiArg, widgetId?: string, widgetAttrId?: string) => widgetAttrId,
  (state: any, {_id, ...query}: WidgetsInfoApiArg, widgetId?: string, widgetAttrId?: string) => {
    if (!widgetId || !widgetAttrId) return null;
    const widget = usersApi.endpoints.widgetInfo.select({...query, widgetId})(state).data;
    if (!widget) return undefined;
    const attr = widget.attrs.find(item => item._id === widgetAttrId);

    return flatten(
      _.uniqBy(
        _.flatten(
          _.flatten(widget.values.map(item => item.values))
            .filter(item => item.templateOptionId === attr?.templateOptionId)
            .map(item =>
              item.values.map(value => ({
                type: item.type,
                widgetAttrId: item.widgetAttrId,
                ...value,
              }))
            )
        ),
        item => JSON.stringify(item)
      )
    );
  }
);

export const getWidgetVisualDesignOptions = (data: WidgetsVisualsDesigns): WidgetVisualsDesignCustomOptions =>
  data.customJSONString ? JSON.parse(data.customJSONString) : {};
