import Card from '@mui/material/Card';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import {adminApi} from 'api';

import {QuestPointIdProp} from 'modules/events/types';

import {useDialogSelector} from 'components/DialogSelector';
import {WidgetVisualForm} from 'modules/widgets/containers';
import {widgetsVisualsColumns} from 'modules/widgets/columns';
import {useNavigate} from 'react-router';
import {getVisualPath} from 'modules/widgets/utils';

export const QuestPointWidgetsVisuals = ({pointId}: QuestPointIdProp) => {
  const navigate = useNavigate();
  const [patch] = adminApi.endpoints.questPointPatch.useMutation();
  const {options, visualsId, widgetId} = adminApi.endpoints.questPointIndex.useQuery(
    {pointId},
    {
      selectFromResult: ({data}) => ({
        options: data?.options,
        widgetId: data?.options.widgets?.widgetId || undefined,
        visualsId: data?.options.widgets?.visualsId || [],
      }),
    }
  );

  const [WidgetVisualsSelector, openSelector] = useDialogSelector();
  const setVisuals = (visualsId?: string[]) => {
    if (options) patch({pointId, questPointPartialDto: {options: {...options, widgets: {visualsId, widgetId}}}});
  };

  return (
    <>
      <WidgetVisualsSelector
        queryName="widgetsVisualsIndex"
        title="Выбор визуалов"
        onConfirm={setVisuals}
        initialSelection={visualsId}
        columns={widgetsVisualsColumns()}
      />
      <Stack spacing={3}>
        <Alert severity="warning" action={<Button onClick={openSelector}>Добавить&nbsp;визуал</Button>}>
          Визуалы виджетов – это инструмент вывода кастомных (сложных) визуализаций данных из виджетов. На данный момент
          этот инструмент не используется в полной мере в приложении и административной панели. Этот раздел находится
          сдесь в целях обеспечения обратной совместимости при редактировании квестов, созданных до июня 2024-го года.
        </Alert>
        {visualsId.map(visualId => (
          <Card key={visualId}>
            <CardHeader
              action={
                <>
                  <Button onClick={() => setVisuals(visualsId.filter(item => item !== visualId))} color="error">
                    Удалить&nbsp;визуал
                  </Button>
                  <Button onClick={() => navigate(getVisualPath(visualId))}>Перейти&nbsp;к&nbsp;содержанию</Button>
                </>
              }
            />
            <CardContent>
              <WidgetVisualForm visualId={visualId} />
            </CardContent>
          </Card>
        ))}
      </Stack>
    </>
  );
};
