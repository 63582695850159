import {lazy} from 'react';
import {Navigate, RouteObject} from 'react-router';

import {PATH_APP} from 'router/routes';
import {Widgets, WidgetsVisuals} from '@apiSchema/admin-api';

import {AccessGuard} from 'modules/auth/containers';
import {BreadcrumbsSet} from 'components/Breadcrumbs';
import {IsExistsContianer} from 'components/IsExistsContainer';

import {useGetWidgetByParam, useGetWidgetOptionsByParam} from './pages/WidgetPage';
import {useGetWidgetVisual, useGetWidgetVisualProps} from './pages/WidgetVisualPage';

const WidgetLayout = lazy(() => import('./containers/WidgetLayout'));
const WidgetPage = lazy(() => import('./pages/WidgetPage'));
const WidgetsList = lazy(() => import('./pages/WidgetsList'));
const WidgetVisualPage = lazy(() => import('./pages/WidgetVisualPage'));
const WidgetsVisualsList = lazy(() => import('./pages/WidgetsVisualsList'));
const WidgetRulesPage = lazy(() => import('./pages/WidgetRulesPage'));

const WidgetFormulasPage = lazy(() => import('./pages/WidgetFormulasPage'));
const WidgetAttributesPage = lazy(() => import('./pages/WidgetAttributesPage'));
const WidgetNPSChartPage = lazy(() => import('./pages/WidgetStatsPage/WidgetNPSChart'));
const WidgetListAnswersPage = lazy(() => import('./pages/WidgetStatsPage/WidgetListAnswers'));
const WidgetCountAnswersPage = lazy(() => import('./pages/WidgetStatsPage/WidgetCountAnswers'));

export const routes: RouteObject[] = [
  {
    path: 'widgets',
    element: (
      <AccessGuard endpoint="widgetsIndex">
        <BreadcrumbsSet name="Виджеты" href={PATH_APP.tasks.widgets.list} />
      </AccessGuard>
    ),
    children: [
      {
        index: true,
        element: <Navigate to="./list" replace />,
      },
      {
        path: 'list',
        children: [
          {index: true, element: <WidgetsList />},
          {
            path: ':widgetId',
            element: (
              <AccessGuard endpoint="widgetIndex">
                <IsExistsContianer<Widgets> useFetch={useGetWidgetByParam} useExtraFetch={useGetWidgetOptionsByParam}>
                  {widget => (
                    <BreadcrumbsSet name={widget.name}>
                      <WidgetLayout />
                    </BreadcrumbsSet>
                  )}
                </IsExistsContianer>
              </AccessGuard>
            ),
            children: [
              {index: true, element: <WidgetPage />},
              {path: 'general', element: <WidgetPage />},
              {path: 'attributes', element: <WidgetAttributesPage />},
              {path: 'rules', element: <WidgetRulesPage />},
              {path: 'formulas', element: <WidgetFormulasPage />},
              {path: 'nps', element: <WidgetNPSChartPage />},
              {path: 'count', element: <WidgetCountAnswersPage />},
              {path: 'answers', element: <WidgetListAnswersPage />},
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'widgetsVisuals',
    element: (
      <AccessGuard endpoint="widgetsVisualsIndex">
        <BreadcrumbsSet name="Визуалы виджетов" href={PATH_APP.tasks.widgetsVisuals.list} />
      </AccessGuard>
    ),
    children: [
      {
        index: true,
        element: <Navigate to="./list" replace />,
      },
      {
        path: 'list',
        children: [
          {index: true, element: <WidgetsVisualsList />},
          {
            path: ':visualId',
            element: (
              <AccessGuard endpoint="widgetsVisualIndex">
                <IsExistsContianer<WidgetsVisuals>
                  useFetch={useGetWidgetVisual}
                  useExtraFetch={useGetWidgetVisualProps}
                >
                  {widgetVisual => <BreadcrumbsSet name={widgetVisual.name} />}
                </IsExistsContianer>
              </AccessGuard>
            ),
            children: [
              {
                index: true,
                element: <WidgetVisualPage />,
              },
            ],
          },
        ],
      },
    ],
  },
];

export default routes;
