import {ImageComponent} from './ImageComponent';
import {VideoComponent} from './VideoComponent';
import {VisualComponent} from './VisualComponent';
import {OptionComponent} from './OptionComponent';
import {ContainerComponent} from './ContainerComponent';
import {DelimeterComponent} from './DelimeterComponent';
import {TypographyComponent} from './TypographyComponent';

export const visualComponents = {
  video: VideoComponent,
  image: ImageComponent,
  visual: VisualComponent,
  option: OptionComponent,
  container: ContainerComponent,
  delimeter: DelimeterComponent,
  typography: TypographyComponent,
};
