import _ from 'lodash';
import numeral from 'numeral';
import {FC, memo} from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {VideoPlayer} from 'components/VideoPlayer';
import {TemplatesOptions} from '@apiSchema/admin-api';
import {WidgetInfoApiArg} from '@apiSchema/users-api';

import {croppedImagePath} from 'utils';
import {useSliderWidgetValues} from './hooks';

type WidgetComponentProps = {
  value: string;
};

const PhotoComponent: FC<WidgetComponentProps> = ({value}) => {
  return (
    <Box
      mt={0.25}
      flexShrink={1}
      component="img"
      sx={{borderRadius: 1, aspectRatio: 1, maxWidth: 300}}
      src={croppedImagePath(value, {ratio: 'default', maxWidth: 300})}
    />
  );
};

const TextComponent: FC<{value: string | number; type: TemplatesOptions['type']}> = ({type, value}) => {
  let formattedValue: string | number = value;

  if (typeof value === 'number') {
    if (type === 'number') formattedValue = numeral(value).format('0,0');
    else if (type === 'score') formattedValue = value + 1;
  }

  return <Typography variant="body2">{formattedValue}</Typography>;
};

const WidgetElement: FC<{value: string | number; type: TemplatesOptions['type']; templateOptionId: string}> = ({
  value,
  type,
}) => {
  switch (type) {
    case 'video':
      return <VideoPlayer fileId={value.toString()} />;
    case 'photo':
      return <PhotoComponent value={value.toString()} />;
    case 'date':
    case 'text':
    case 'number':
    case 'score':
    case 'string':
      return <TextComponent value={value} type={type} />;
    case 'checkbox':
    case 'multi_select':
    case 'radio':
    case 'select':
      return null;
    case 'file':
    case 'audio':
    case 'goal':
    case 'user':
    case 'stage':
    case 'reward':
      return null;
    default:
      return null;
  }
};

export const SliderWidget: FC<WidgetInfoApiArg> = memo(props => {
  const values = useSliderWidgetValues(props);
  if (!values || !values.length) return null;
  return (
    <Stack spacing={2}>
      {values.map(({label, _id, values: attrValues}) => (
        <Stack key={_id} spacing={1}>
          <Typography variant="body2" fontWeight={600}>
            {label}
          </Typography>
          {attrValues.map((valueEntry, index) => (
            <Stack key={`${index}.${_id}`} spacing={2}>
              {valueEntry.map(({type, value, templateOptionId}, index2) => (
                <Stack key={`${_id}.${index}.${templateOptionId}.${index2}`}>
                  <WidgetElement value={value || ''} type={type} templateOptionId={templateOptionId} />
                </Stack>
              ))}
            </Stack>
          ))}
        </Stack>
      ))}
    </Stack>
  );
}, _.isEqual);
