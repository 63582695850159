export type SelectOptionType = {name: string; value: string | number | undefined; type?: string; fileId?: string};
export type SelectFieldProps<T extends SelectOptionType = SelectOptionType> = {
  options: T[] | ((values?: any) => T[]);
  variant?: 'select' | 'autocomplete' | 'chips';
  multiple?: boolean;
  autoCompleteOptions?: {
    optionLabel?: string;
    groupBy?: (row: T) => string;
  };
};
export const EMPTY_VALUE = 'empty';
