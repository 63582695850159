import React, {createContext, useContext} from 'react';
import {ArrayParam, useQueryParam, withDefault} from 'use-query-params';

export const QuestStructureContext = createContext<{
  openPointMenu?: (event: React.MouseEvent<HTMLButtonElement>, questId: string) => void;
}>({});

export const useQuestStructureContext = () => {
  const {openPointMenu} = useContext(QuestStructureContext);
  if (!openPointMenu) throw new Error('no context found');
  return {openPointMenu};
};

export const useQuestPointHistory = () => {
  const [pointsId, setPointsId] = useQueryParam('opened', withDefault(ArrayParam, []));
  const togglePoint = (pointId: string) => {
    setPointsId(currentState => {
      const state = currentState || [];
      const pointIndex = state.indexOf(pointId);
      if (pointIndex === -1) return [...state, pointId];
      return state.slice(0, pointIndex);
    }, 'pushIn');
  };

  return {togglePoint, pointsId: ['', ...pointsId.filter(Boolean)] as string[]};
};
