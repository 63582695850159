import {startsWith} from 'lodash';
import {useDropzone, DropzoneOptions} from 'react-dropzone';
// material
import {alpha, styled} from '@mui/material/styles';
import {Box, Theme, Typography, Paper, Stack} from '@mui/material';
import {SxProps} from '@mui/system';
// utils
import {fData} from '../../utils/formatNumber';
//
// import {UploadIllustration} from '../../assets';
import {filesApi} from 'api';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {filePath} from 'utils';

// ----------------------------------------------------------------------

const isImage = (type: string) => startsWith(type, 'image/');

const DropZoneStyle = styled('div')(({theme}) => ({
  outline: 'none',
  display: 'flex',
  overflow: 'hidden',
  textAlign: 'center',
  position: 'relative',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(5, 0),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': {
    opacity: 0.72,
    cursor: 'pointer',
  },
  [theme.breakpoints.up('md')]: {textAlign: 'left', flexDirection: 'row'},
}));

// ----------------------------------------------------------------------

interface CustomFile extends File {
  path?: string;
  preview?: string;
}

interface UploadSingleFileProps extends DropzoneOptions {
  error?: boolean;
  file: CustomFile | string | null;
  sx?: SxProps<Theme>;
}

export default function UploadSingleFile({
  error = false,
  // file,
  sx,
  filePath: fileUrl,
  fileId,
  label = 'Перетащите или выберите файл',
  ...other
}: UploadSingleFileProps & {fileId?: string; filePath?: string; label?: string}) {
  const {getRootProps, getInputProps, isDragActive, isDragReject, fileRejections} = useDropzone({
    multiple: false,
    ...other,
  });
  const {data} = filesApi.endpoints.fileInfo.useQuery(fileId ? {fileId} : skipToken);

  const ShowRejectionItems = () => (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        borderColor: 'error.light',
        bgcolor: theme => alpha(theme.palette.error.main, 0.08),
      }}
    >
      {fileRejections.map(({file, errors}) => {
        const {path, size}: CustomFile = file;
        return (
          <Box key={path} sx={{my: 1}}>
            <Typography variant="subtitle2" noWrap>
              {path} - {fData(size)}
            </Typography>
            {errors.map(e => (
              <Typography key={e.code} variant="caption" component="p">
                - {e.message}
              </Typography>
            ))}
          </Box>
        );
      })}
    </Paper>
  );

  return (
    <Box sx={{width: '100%', ...sx}}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && {opacity: 0.72}),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter',
          }),
          ...{padding: 0, height: '100%'},
          // ...(file && {padding: '12% 0'}),
        }}
      >
        <input {...getInputProps()} />
        {/* <UploadIllustration sx={{width: 220}} /> */}

        <Box sx={{p: 3, ml: {md: 2}}}>
          <Typography gutterBottom variant="h5">
            {label}
          </Typography>

          <Typography variant="body2" sx={{color: 'text.secondary'}}>
            Перетащите файлы сюда или&nbsp;
            <Typography variant="body2" component="span" sx={{color: 'primary.main', textDecoration: 'underline'}}>
              выберите
            </Typography>
            &nbsp;на своем устройстве
          </Typography>
        </Box>

        {((data && isImage(data.type)) || fileUrl) && (
          <Box
            component="img"
            alt="file preview"
            src={fileUrl || filePath(data?._id!, 'download')}
            sx={{
              top: 8,
              borderRadius: 1,
              objectFit: 'cover',
              position: 'absolute',
              width: 'calc(100% - 16px)',
              height: 'calc(100% - 16px)',
            }}
          />
        )}
        {data && !isImage(data.type) && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            position="absolute"
            flexGrow={1}
            bgcolor="white"
            p={1}
            borderRadius={1}
          >
            <Stack spacing={0.5}>
              {data.name && <Typography variant="body2">{data.name}</Typography>}
              {data.size && (
                <Typography color="grey.20" variant="subtitle1">{`${(data.size / (1024 * 1024)).toFixed(
                  1
                )} Мб`}</Typography>
              )}
            </Stack>
          </Stack>
        )}
      </DropZoneStyle>

      {fileRejections.length > 0 && <ShowRejectionItems />}
    </Box>
  );
}
