import Label from '__theme/components/Label';

type InfoType = 'in_progress' | 'checking' | 'wait_payment';
type SuccessType = 'ready' | 'completed' | 'paid';
type WarningType = 'edit';
type DefaultType = 'new' | 'archived' | 'draft' | 'returned';
type ErrorType = 'error' | 'declined' | 'deadline' | 'failed' | 'rejected';
type StatusType = ErrorType | WarningType | InfoType | SuccessType | DefaultType | null | undefined;

export const StatusLabel = ({
  status,
  deletedAt,
  statusText,
}: {
  status: StatusType;
  deletedAt?: string;
  statusText?: string;
}) => {
  const text = deletedAt ? 'Удалено' : statusText || status;

  return (
    <Label
      color={
        deletedAt || ['error', 'declined', 'deadline', 'failed', 'rejected'].includes(status || '')
          ? 'error'
          : ['ready', 'completed', 'paid'].includes(status || '')
          ? 'success'
          : ['edit'].includes(status || '')
          ? 'warning'
          : ['checking', 'in_progress', 'wait_payment'].includes(status || '')
          ? 'info'
          : 'default'
      }
    >
      {text}
    </Label>
  );
};
