import {FC} from 'react';
import {Link} from 'react-router-dom';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {Icon} from '@iconify/react';
import openInNew from '@iconify/icons-material-symbols/open-in-new';

import {croppedImagePath} from 'utils';
import {EntityPreviewProps} from './types';
import {IconButton} from '@mui/material';

export const EntityPreview: FC<EntityPreviewProps> = ({fileId, disabled, title, subTitle, to, noBorder, p, target}) => {
  return (
    <Stack
      to={to}
      spacing={2}
      target={target}
      component={disabled ? 'div' : Link}
      direction="row"
      bgcolor="background.paper"
      sx={{
        p,
        alignItems: 'top',
        borderWidth: noBorder ? 0 : 1,
        borderRadius: 1,
        borderStyle: 'solid',
        borderColor: 'grey.300',
        textDecoration: 'none',
        '&:hover': {
          backgroundColor: disabled ? undefined : 'grey.200',
        },
      }}
    >
      {fileId && (
        <Box
          mt={0.25}
          flexShrink={1}
          component="img"
          width={44}
          height={44}
          sx={{borderRadius: 1}}
          src={croppedImagePath(fileId, {ratio: '1:1', maxWidth: 44})}
        />
      )}
      <Stack direction="row" width={1} alignItems="center" justifyContent="space-between" spacing={2}>
        <Stack spacing={0}>
          <Typography variant="body2" sx={{fontWeight: 600}} color="black">
            {title}
          </Typography>
          {subTitle && (
            <Typography variant="body2" color="text.secondary">
              {subTitle}
            </Typography>
          )}
        </Stack>
        {!disabled && (
          <Box height={44} display="flex" alignItems="center" alignSelf="flex-start">
            <IconButton size="small">
              <Icon icon={openInNew} />
            </IconButton>
          </Box>
        )}
      </Stack>
    </Stack>
  );
};
