import {FC} from 'react';
import {useMyId} from 'utils';
import {Box} from '@mui/material';

import {APIItemRender} from 'components/DataList/types';
import {Comments as TComments} from '@apiSchema/users-api';
import {APIListNavigation, UsersAPIList} from 'components/DataList';

import {CommentsContext} from './utils';
import {CommentReply} from './CommentReply';
import {CommentsBranch} from './CommentsBranch';
import {CommentsReferenceProp, ReferenceAuthorId} from './types';

const renderItem: APIItemRender = ({itemId}) => (itemId ? <CommentsBranch commentId={itemId} /> : null);

const labels: Record<TComments['referenceName'] | 'Comments', string> = {
  Goals: '',
  Jobs: 'Ваш комментарий к выполнению задания',
  Tasks: 'Ваш комментарий к заданию',
  Quests: 'Ваш комментарий к квесту',
  Comments: 'Ваш ответ на комментарий',
  Contents: 'Ваш комментарий к материалу',
  Users: 'Ваша заметка о пользователе',
  Publications: 'Ваш комментарий к посту',
};

export const Comments: FC<CommentsReferenceProp['reference'] & ReferenceAuthorId> = ({
  referenceAuthorId,
  ...reference
}) => {
  const myId = useMyId();
  return (
    <CommentsContext.Provider value={{reference, canScoreReference: myId !== referenceAuthorId}}>
      <Box mb={2}>
        <UsersAPIList
          spacing={2}
          api="adminApi"
          endpoint="commentsIndex"
          renderItem={renderItem}
          Footer={APIListNavigation}
          getTotal={res => res?.total}
          params={{...reference, sort: 'created_asc'}}
        />
      </Box>

      <CommentReply label={labels[reference.referenceName]} />
    </CommentsContext.Provider>
  );
};
