import {FC} from 'react';
import {Button, Card, CardContent, CardHeader, IconButton, Stack, Tooltip} from '@mui/material';
import RemoveIcon from '@mui/icons-material/RemoveOutlined';

import {adminApi} from 'api';

import {rolesColumns} from 'modules/settings/pages/AccessList';

import {DataList} from 'components/DataList';
import {UserIdProp} from 'modules/users/types';
import {WithGuard} from 'modules/auth/containers';
import {useDialogSelector} from 'components/DialogSelector';

export const UserPermissions: FC<UserIdProp> = WithGuard('userPermissionsUpdate', ({userId}) => {
  const {data: permissions} = adminApi.endpoints.userPermissionsIndex.useQuery({userId});
  const [updatePermission] = adminApi.endpoints.userPermissionsUpdate.useMutation();

  const setAccess = (ids: string[]) => updatePermission({userId, userPermissionDto: {...permissions, accessId: ids}});
  const [AccessSelector, openAccessSelector] = useDialogSelector();

  return (
    <Stack spacing={3} direction="row-reverse">
      <AccessSelector
        hideToolbar
        title="Выбор ролей"
        initialSelection={permissions?.accessId || []}
        onConfirm={ids => setAccess([...ids, ...(permissions?.accessId || [])])}
        queryName="accessIndex"
        params={{skipId: permissions?.accessId || []}}
        columns={rolesColumns()}
      />
      <Card sx={{width: '100%'}}>
        <CardHeader title="Роли" action={<Button onClick={openAccessSelector}>Назначить роль</Button>} />
        <CardContent sx={{px: 1}}>
          <DataList
            hideToolbar
            hideFooterPagination
            density="compact"
            queryName="accessIndex"
            params={{_id: permissions?.accessId || []}}
            columns={rolesColumns(({row}) => (
              <Tooltip title="Удалить роль">
                <div>
                  <IconButton
                    size="small"
                    onClick={() => setAccess((permissions?.accessId || []).filter(item => item !== row._id))}
                  >
                    <RemoveIcon />
                  </IconButton>
                </div>
              </Tooltip>
            )).filter(item => ['name', '__action'].includes(item.field))}
          />
        </CardContent>
      </Card>
    </Stack>
  );
});
