import _ from 'lodash/fp';
import {TextField, MenuItem} from '@mui/material';

import {Templates} from '@apiSchema/users-api';

import * as Styled from './styled';
import {AnswerProps} from '../types';
import {useMultiFieldProps} from './hooks';

export const SelectInput = ({
  scriptActionId,
  index,
  template,
}: Omit<AnswerProps, 'templateOptionId'> & {template: Templates}) => {
  const multiple = template.type === 'multi_select';
  const {options, value, field} = useMultiFieldProps(scriptActionId, `${template._id}`, index);

  return (
    <Styled.Select>
      <TextField
        size="small"
        variant="standard"
        select
        fullWidth
        inputRef={field.ref}
        label={template.name}
        SelectProps={{multiple}}
        value={!multiple ? value[0] || '' : value}
        {..._.omit(['value'], field)}
      >
        {options.map(item => (
          <MenuItem key={item._id} value={item._id} dense>
            {item.name}
          </MenuItem>
        ))}
      </TextField>
    </Styled.Select>
  );
};
