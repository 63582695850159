import {FC} from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import {Icon} from '@iconify/react';
import openInNew from '@iconify/icons-material-symbols/open-in-new';

import {croppedImagePath} from 'utils';
import {Users} from '@apiSchema/users-api';
import {UserIdProp, getUserPath, getUserInitials, getUserName} from 'modules/users';
import {usersApi} from 'api';

const DefaultAction: FC<UserIdProp> = ({userId}) => (
  <IconButton size="small" href={getUserPath(userId)} target="_blank">
    <Icon icon={openInNew} />
  </IconButton>
);

export const UserItem = ({user, Action}: {user: Users; Action?: JSX.Element}) => (
  <Stack direction="row" alignItems="flex-start" justifyContent="space-between" spacing={2}>
    <Stack direction="row" alignItems="flex-start" spacing={2}>
      <Avatar
        sx={{width: 44, height: 44}}
        src={user.fileId && croppedImagePath(user.fileId, {ratio: '1:1', maxWidth: 44})}
      >
        {getUserInitials(user)}
      </Avatar>
      <Stack alignSelf="center">
        <Typography variant="subtitle2">{getUserName(user)}</Typography>
        {user.disclaimer && (
          <Typography variant="body2" color="grey.600">
            {user.disclaimer}
          </Typography>
        )}
      </Stack>
    </Stack>
    <Box height={44} display="flex" alignItems="center">
      {Action ? Action : <DefaultAction userId={user._id!} />}
    </Box>
  </Stack>
);

export const UserItemHook = ({userId, Action}: UserIdProp & {Action?: JSX.Element}) => {
  const {data: user} = usersApi.endpoints.userIndex.useQuery({userId});
  if (!user) return null;
  return <UserItem user={user.account} Action={Action} />;
};
