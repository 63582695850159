import {FC, useState} from 'react';
import {skipToken} from '@reduxjs/toolkit/query';

import {ChevronRight} from '@mui/icons-material';
import {Box, Collapse, Stack, Typography} from '@mui/material';

import {usersApi} from 'api';
import {Jobs, Scripts} from '@apiSchema/users-api';

import {JobStep} from 'components/__general/tasks/JobStep';

const Answers = ({job, script, defaultCollapse}: {job: Jobs; script: Scripts; defaultCollapse?: boolean}) => {
  const jobId = `${job._id}`;
  const [collapse, setCollapse] = useState(defaultCollapse ?? false);

  return (
    <Stack>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        onClick={() => setCollapse(i => !i)}
        sx={{cursor: 'pointer'}}
      >
        <Typography variant="subtitle1">{script.name}</Typography>
        <ChevronRight sx={{transition: 'transform .2s', transform: collapse ? 'rotate(-90deg)' : 'rotate(90deg)'}} />
      </Stack>

      <Collapse in={collapse} mountOnEnter unmountOnExit>
        <Box pt={2}>
          <JobStep
            key={script._id}
            jobId={jobId}
            scriptId={`${script._id}`}
            jobCompleted={true}
            contentId={script.contentId || undefined}
          />
        </Box>
      </Collapse>
    </Stack>
  );
};

export const UserCardJob: FC<{jobId: string}> = ({jobId}) => {
  const {data: job} = usersApi.endpoints.jobIndex.useQuery(jobId ? {jobId} : skipToken);
  if (!job) return null;

  const scripts = job.structure?.scripts || [];
  return (
    <>
      {scripts.map((script, i) => (
        <Answers key={script._id} script={script} job={job} defaultCollapse={i === 0} />
      ))}
    </>
  );
};
