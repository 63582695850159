import {createContext} from 'react';
import {StringParam, useQueryParam} from 'use-query-params';

import {CommentsReferenceProp} from './types';

export const useEditCommentId = () => useQueryParam('editCommentId', StringParam);
export const CommentsContext = createContext<Partial<CommentsReferenceProp> & {canScoreReference?: boolean}>({
  reference: undefined,
});

export const scoreOptions = [
  {value: 1, name: '😟'},
  {value: 7, name: '👍'},
  {value: 9, name: '🔥'},
];
