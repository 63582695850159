import {useNavigate} from 'react-router';
import {OpenInNew} from '@mui/icons-material';

import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import DeleteIcon from '@mui/icons-material/DeleteOutline';

import {adminApi} from 'api';

import {DataList} from 'components/DataList';
import {getGroupPath} from 'modules/groups/utils';
import {useConfirmationDialog} from 'components/Dialog';
import {useDialogSelector} from 'components/DialogSelector';
import {subGroupsColumns} from 'modules/groups/pages/GroupsList/columns';

export const GroupSubGroups = ({groupId}: {groupId: string}) => {
  const navigate = useNavigate();
  const [GroupsSelector, openGroupsSelector] = useDialogSelector();
  const {subGroupsId} = adminApi.endpoints.groupIndex.useQuery(
    {groupId},
    {selectFromResult: ({data}) => ({subGroupsId: data?.subGroupsId || []})}
  );

  const [patchGroup] = adminApi.endpoints.groupPatch.useMutation();

  const removeSubgroup = (subGroupId: string) => {
    patchGroup({groupId, groupPartialDto: {subGroupsId: subGroupsId.filter(item => item !== subGroupId)} as any});
  };
  const onGroupsAdd = (newSubGroupsId: string[]) => {
    patchGroup({groupId, groupPartialDto: {subGroupsId: subGroupsId.concat(newSubGroupsId)} as any});
  };
  const removeWithConfirmation = useConfirmationDialog(removeSubgroup);

  return (
    <>
      <GroupsSelector
        title="Выбор групп"
        initialSelection={[]}
        onConfirm={onGroupsAdd}
        columns={subGroupsColumns()}
        queryName="groupsIndex"
        params={{skipId: subGroupsId.concat([groupId])}}
      />
      <Card>
        <CardHeader title="Вложенные группы" action={<Button onClick={openGroupsSelector}>Добавить</Button>} />
        <CardContent sx={{px: 1}}>
          <DataList
            hideToolbar
            customPagination
            density="compact"
            queryName="groupsIndex"
            columns={subGroupsColumns(undefined, undefined, ({row}) => [
              {
                icon: <DeleteIcon fontSize="small" />,
                onClick: () =>
                  removeWithConfirmation(row._id!, {
                    btnType: 'error',
                    title: `Удалить группу ${row.name} из списка дочерних групп?`,
                  }),
                label: 'Удалить из группы',
                title: 'Удалить из группы',
              },
              {
                icon: <OpenInNew fontSize="small" />,
                onClick: () => navigate(getGroupPath(row._id!)),
                label: 'Перейти к группе',
                title: 'Перейти к группе',
              },
            ])}
            params={{subGroupsOf: groupId}}
          />
        </CardContent>
      </Card>
    </>
  );
};

export default GroupSubGroups;
