import _ from 'lodash/fp';
import {Provider} from 'jotai';
import {useAtomCallback} from 'jotai/utils';

import {useAppDispatch} from 'store';
import {useRef, useCallback, useState} from 'react';
import {useForm, FormProvider} from 'react-hook-form';

import Box from '@mui/system/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';

import {usersApi as api} from 'api';
import {AnswerDto, ScriptsActions, ScriptsGroups} from '@apiSchema/users-api';
import {ScriptAnswersError} from '../types';

import {JobAction} from './JobAction';
import {flatAnswersAtom, optionsAton, patchAnswersAtom, scriptAnswersAtom} from './atoms';
import {debounce} from 'lodash';

const getActions = (groupsIds: string[]) =>
  _.compose(
    _.map(_.pick(['_id', 'scriptGroupId', 'positionNumber'])),
    _.filter(_.compose(_.includes(groupsIds), _.get('scriptGroupId')))
  );

const JobScriptForm = ({
  jobId,
  scriptId,
  actions,
  groups,
  onAfterSave,
}: {
  scriptId: string;
  jobId: string;
  groups: ScriptsGroups[];
  actions: Record<string, Required<Pick<ScriptsActions, '_id' | 'scriptGroupId'>>[]>;
  onAfterSave?: () => void;
}) => {
  const methods = useForm({});
  const {setError} = methods;
  const [submit, {isLoading}] = api.endpoints.jobAnswersSaveAnswers.useMutation();
  const lastMutation = useRef<ReturnType<typeof submit>>();
  const [globalMessage, setGlobalMessage] = useState<string | null>(null);

  const onSubmit = useAtomCallback(
    useCallback(
      async get => {
        setGlobalMessage(null);
        lastMutation.current = submit({scriptId, jobId, answersBodyDto: {answers: get(flatAnswersAtom)}});
        const result = await lastMutation.current;
        if ('error' in result) {
          const err = result.error as ScriptAnswersError;
          if (err.status === 409) {
            if ('data' in err) {
              if (err.data?.message) setGlobalMessage(err.data?.message);
              if (err.data?.data) {
                err.data.data.errors.forEach(item => {
                  setError(item.scriptActionId, {type: 'manual', message: item.error});
                });
              }
            }
          }
        } else if (onAfterSave) onAfterSave();
      },
      [scriptId, jobId, submit, setError, onAfterSave]
    )
  );

  return (
    <FormProvider {...methods}>
      <form autoComplete="off">
        {groups.map(group => (
          <Card key={group._id}>
            <CardContent>
              {(actions[`${group._id}`] || []).map(item => (
                <JobAction key={item._id} actionId={item._id} scriptGroupId={item.scriptGroupId} jobId={jobId} />
              ))}
            </CardContent>
          </Card>
        ))}
        <Card sx={{p: 2}}>
          <LoadingButton variant="contained" fullWidth size="medium" onClick={() => onSubmit()} loading={isLoading}>
            Сохранить
          </LoadingButton>
          {globalMessage && (
            <Typography variant="subtitle1" sx={{color: 'grey.200', mt: 1}}>
              {globalMessage}
            </Typography>
          )}
        </Card>
      </form>
    </FormProvider>
  );
};

export const JobScript = ({
  scriptId,
  jobId,
  onAfterSave,
}: {
  jobId: string;
  scriptId: string;
  onAfterSave?: () => void;
}) => {
  const {
    isLoading,
    answersByScriptId,
    isSuccess: isAnswersFetched,
  } = api.endpoints.jobAnswersGetAnswers.useQuery(
    {jobId, scriptId},
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({data, ...rest}) => ({
        ...rest,
        answersByScriptId: _.compose(
          _.fromPairs,
          _.map(item => [item[0], _.map(_.pick(['templateOptionId', 'index', 'value']), item[1])]),
          _.toPairs,
          _.groupBy('scriptActionId')
        )(data || []) as any,
      }),
    }
  );

  const {data} = api.endpoints.jobStructure.useQueryState(
    {jobId},
    {
      selectFromResult: ({data, ...rest}) => {
        if (!data || !data.structure) return {data: null, ...rest};
        const {scriptsGroups, scriptsActions, templatesOptions} = data.structure;
        const groups = _.filter(_.compose(_.equals(scriptId), _.get('scriptId')))(scriptsGroups) as ScriptsGroups[];
        const actions = _.compose(
          _.groupBy('scriptGroupId'),
          _.sortBy('positionNumber'),
          getActions(_.map(_.get('_id'), groups))
        )(scriptsActions);

        return {
          data: {
            groups,
            templatesOptions,
            actions: actions as unknown as Record<string, Required<Pick<ScriptsActions, '_id' | 'scriptGroupId'>>[]>,
          },
          ...rest,
        };
      },
    }
  );

  const dispatch = useAppDispatch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const patchAnswers = useCallback(
    debounce((dto: AnswerDto) => {
      dispatch(
        api.endpoints.jobAnswersSavePartialAnswers.initiate({jobId, scriptId, answersBodyDto: {answers: [dto]}})
      );
    }, 300),
    [dispatch, jobId, scriptId]
  );

  if (!data || isLoading || !isAnswersFetched)
    return (
      <Box px={2} py={2} justifyContent="center">
        <CircularProgress />
      </Box>
    );

  return (
    <Provider
      initialValues={[
        [patchAnswersAtom, patchAnswers],
        [optionsAton, data.templatesOptions],
        [scriptAnswersAtom, answersByScriptId],
      ]}
    >
      <JobScriptForm
        jobId={jobId}
        scriptId={scriptId}
        groups={data.groups}
        actions={data.actions}
        onAfterSave={onAfterSave}
      />
    </Provider>
  );
};
