import {useCallback} from 'react';
import {BooleanParam, StringParam, useQueryParams} from 'use-query-params';
import {skipToken} from '@reduxjs/toolkit/dist/query';

import {Card, CardContent, CardHeader, LinearProgress, Dialog, DialogTitle, DialogContent} from '@mui/material';

import {adminApi} from 'api';
import {Users} from '@apiSchema/admin-api';
import {Form} from 'components/Form';

import {fields, validation} from './fields';
import {useCreateUser} from './hook';

const queryShape = {createUser: BooleanParam, editUserId: StringParam};

export type UsersFormProps = {
  userId?: string;
  onReset?: () => void;
  onCreate?: (data: Users) => void;
  withoutWrapper?: boolean;
};
type UsersFormDialogProps = Pick<UsersFormProps, 'onCreate'>;

export const UsersForm = ({userId, onReset, withoutWrapper}: UsersFormProps) => {
  const {data, isFetching} = adminApi.useUserIndexQuery(userId ? {userId} : skipToken);
  const {onSubmit, initials} = useCreateUser(data);

  if (isFetching && !data) return <LinearProgress />;

  const form = (
    <Form fields={fields} initials={initials} validation={validation} onSubmit={onSubmit} onReset={onReset} />
  );

  if (withoutWrapper) return form;
  return (
    <Card sx={{width: '100%'}}>
      <CardHeader title="Информация" />
      <CardContent>{form}</CardContent>
    </Card>
  );
};

export const useUsersForm = (): [
  (props: UsersFormDialogProps) => JSX.Element,
  () => void,
  (userId: string) => void
] => {
  const [{editUserId, createUser}, setQuery] = useQueryParams(queryShape);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const open = useCallback(() => setQuery({createUser: true}, 'replace'), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const edit = useCallback((userId: string) => setQuery({editUserId: userId}, 'replace'), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const close = useCallback(() => setQuery({}, 'replace'), []);

  const FormContainer = useCallback(
    (props: UsersFormDialogProps) => (
      <Dialog open={Boolean(createUser) || Boolean(editUserId)} maxWidth="sm" fullWidth onBackdropClick={close}>
        <DialogTitle>{editUserId ? 'Редактирование' : 'Добавить пользователя'}</DialogTitle>
        <DialogContent dividers>
          <UsersForm userId={editUserId as string | undefined} onReset={close} withoutWrapper {...props} />
        </DialogContent>
      </Dialog>
    ),
    [createUser, editUserId, close]
  );

  return [FormContainer, open, edit];
};

export default UsersForm;
