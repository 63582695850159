import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Cancel';

import {filesApi} from 'api';
import {filePath} from 'utils';

export const FileAnswer = ({fileId, onRemove}: {fileId: string; onRemove?: () => void}) => {
  const {data: file} = filesApi.endpoints.fileInfo.useQuery({fileId});
  const fileName = file?.name.substring(0, file?.name.lastIndexOf('.'));
  const fileType = file?.name.substring(file?.name.lastIndexOf('.'));

  if (!file) return null;
  return (
    <Box
      width={64}
      height={64}
      borderRadius={1}
      alignItems="center"
      position="relative"
      justifyContent="center"
      sx={{
        border: '1px solid',
        borderColor: 'rgba(145, 158, 171, 0.24)',
        bgcolor: 'background.neutral',
      }}
    >
      <Stack
        p={0.75}
        width="100%"
        height="100%"
        component="a"
        target="_blank"
        justifyContent="space-between"
        href={filePath(fileId, 'download')}
        sx={{
          textDecoration: 'none',
        }}
      >
        <Typography component="div" fontSize={12} lineHeight="12px" color="text.secondary">
          {fileType}
        </Typography>
        <Stack spacing={0.25}>
          <Typography
            fontSize={10}
            lineHeight="10px"
            fontWeight={500}
            color="text.primary"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {fileName}
          </Typography>

          <Typography
            fontSize={10}
            lineHeight="10px"
            color="text.secondary"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {Math.round(file.size / 1000)} KB
          </Typography>
        </Stack>
      </Stack>
      {onRemove && (
        <Box position="absolute" top={-10} right={-6}>
          <IconButton
            onClick={() => onRemove()}
            sx={{
              p: 0,
              width: 16,
              height: 16,
              bgcolor: 'primary.500',
              '&:hover': {bgcolor: 'primary.400'},
            }}
          >
            <Box component={CloseIcon} color="white" width={16} height={16} />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};
