import styled from '@mui/system/styled';
import Typography from '@mui/material/Typography';
import ReactMarkdown from 'react-markdown';
import {Box} from '@mui/system';

const Ul = styled('ul')`
  margin-top: 8px;
  margin-bottom: 8px;
  list-style: inside;
`;

export const MarkdownRender = ({
  content = '',
  bodyVariant = 'body1',
}: {
  content?: string;
  bodyVariant?: 'body1' | 'body2';
}) => {
  return (
    <ReactMarkdown
      skipHtml
      components={{
        h1: ({children}) => <Typography variant="h1">{children}</Typography>,
        h2: ({children}) => <Typography variant="h2">{children}</Typography>,
        h3: ({children}) => <Typography variant="h3">{children}</Typography>,
        h4: ({children}) => <Typography variant="h4">{children}</Typography>,
        ul: ({children}) => <Ul>{children}</Ul>,
        ol: ({children}) => <Ul>{children}</Ul>,
        strong: ({children}) => (
          <Box component="span" sx={{fontWeight: 500}}>
            {children}
          </Box>
        ),
        li: ({children}) => (
          <Typography variant={bodyVariant} component="li">
            {children}
          </Typography>
        ),
        pre: ({children}) => (
          <Typography variant={bodyVariant} sx={{overflowX: 'scroll'}}>
            <pre>{children}</pre>
          </Typography>
        ),
        p: ({children}) => <Typography variant={bodyVariant}>{children}</Typography>,
      }}
    >
      {content}
    </ReactMarkdown>
  );
};
