import {useState} from 'react';

import {Button, Stack} from '@mui/material';

import {adminApi} from 'api';
import {UsersList} from 'modules/users/containers';
import {useDestroyStages} from './hook';
import {useSnackbarEmit} from 'utils/snackbar';

export const QuestTimelineUsers = ({
  questId,
  timelineId,
  onClose,
}: {
  questId: string;
  timelineId: string;
  onClose: () => void;
}) => {
  const {emitError, emitSuccess} = useSnackbarEmit();
  const [usersId, onSelect] = useState<string[]>([]);
  const {
    deleting,
    destructing,
    onDeleteStages,
    onDestructStages,
    DeleteStagesConfirmation,
    DestructStagesConfirmation,
  } = useDestroyStages(questId, timelineId);
  const [emit, {isLoading: emitting}] = adminApi.endpoints.questTimelineElemEmitSpecific.useMutation();

  const loading = emitting || deleting || destructing;
  const actionsDisabled = loading || !usersId.length;

  const onEmit = async () => {
    const body = {usersId: usersId.map(i => i.toString())};
    const result = await emit({questId, timelineId, body});
    if ('data' in result) emitSuccess('Квест выдан');
    else if ('error' in result) emitError(result.error);
  };

  return (
    <>
      <DeleteStagesConfirmation />
      <DestructStagesConfirmation />
      <UsersList
        loading={loading}
        onSelect={onSelect as any}
        sx={{height: '80vh', overflowY: 'auto'}}
        params={{populates: ['logins'], timelineId}}
      />
      <Stack direction="row" spacing={2}>
        <Button variant="contained" onClick={onEmit} disabled={actionsDisabled}>
          Раздать квест
        </Button>
        <Button variant="contained" color="error" onClick={() => onDeleteStages({usersId})} disabled={actionsDisabled}>
          Удалить квест
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => onDestructStages({usersId})}
          disabled={actionsDisabled}
        >
          Удалить квест с сохранением прогресса
        </Button>
        <Button variant="outlined" color="error" onClick={loading ? undefined : onClose} disabled={loading}>
          Закрыть
        </Button>
      </Stack>
    </>
  );
};
