import {FC} from 'react';
import {QuestsPoints} from '@apiSchema/admin-api';

export type QuestIdProp = {questId: string};
export type QuestPointIdProp = {pointId: string};
export type QuestPointProp = {point: QuestsPoints};

export type QuestPointTabComponent = FC<QuestIdProp & QuestPointIdProp>;

const getOptions = <T extends string | number>(object: {[key: string]: T}, names: Record<T, string>) =>
  Object.values(object).map(value => ({
    value,
    name: names[value],
  }));

//

export enum QuestPointContainers {
  DEFAULT = 'default',
  LESSON_CONTENT = 'lessonContent',
  BOTTOM_BUTTON = 'bottomButtonsContainer',
}

const questPointContainerNames: Record<QuestPointContainers, string> = {
  default: 'Урок модуля',
  lessonContent: 'Задание урока',
  bottomButtonsContainer: 'Кнопка внизу экрана',
};

export const questPointContainerOptions = getOptions(QuestPointContainers, questPointContainerNames);

//

export enum QuestsStatuses {
  DRAFT = 'draft',
  ACTIVE = 'active',
  ARCHIVED = 'archived',
}

const questStatusesNames: Record<QuestsStatuses, string> = {
  draft: 'Черновик',
  active: 'Активный',
  archived: 'Архивный',
};

export const questStatusesOptions = Object.values(QuestsStatuses).map(value => ({
  value,
  name: questStatusesNames[value],
}));

//

export enum QuestsTypes {
  LESSON = 'lesson', // урок
  DEFAULT = 'default', // обычное
  SEASON = 'season', // сезон
  ADVENTURE = 'adventure', // приключение
}

export const questTypesNames: Record<QuestsTypes, string> = {
  lesson: 'Урок',
  season: 'Модуль',
  default: 'Квест',
  adventure: 'Программа',
};

export const questTypesOptions = Object.values(QuestsTypes).map(value => ({
  value,
  name: questTypesNames[value],
}));
