import {TagDescription} from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export const getTag = <T extends string>(tagName: T, value?: string) =>
  ({type: tagName, id: value || '@@LIST'} as TagDescription<T>);

export const getListTags =
  <Item>(tagName: string, getId: (item: Item) => string) =>
  (data?: {total: number; data?: Array<Item>}) => {
    const common = getTag(tagName);
    if (!data) return [common];
    return (data.data || []).map(item => getTag(tagName, getId(item))).concat([common]);
  };
