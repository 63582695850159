import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {Fields} from './Fields';
import {FieldBlockProps} from '../types';

export const FieldsBlock: React.VFC<FieldBlockProps> = ({label, fields, direction = 'row'}) => {
  return (
    <Stack spacing={1} flexGrow={1} flexShrink={0}>
      {label && (
        <Typography variant="body1" mb={1} fontWeight={600}>
          {label}
        </Typography>
      )}
      <Stack direction={direction} spacing={2}>
        <Fields fields={fields} />
      </Stack>
    </Stack>
  );
};
