import {TagDto} from '@apiSchema/admin-api';
import {yupBooleanRequired, yupObject, yupStringOfOptionsRequired, yupStringRequired} from 'utils/validation';
import {FieldStructure} from 'components/Form/types';

const tagTypeOptions: {name: string; value: TagDto['type']}[] = [
  {name: 'Обычный', value: 'default'},
  {name: 'Квест', value: 'quest'},
  {name: 'Качество развития', value: 'personal_quality'},
  {name: 'Хэштег', value: 'hashtag'},
];

export const fields: FieldStructure[] = [
  {
    type: 'text',
    label: 'Название',
    name: 'name',
  },
  {
    type: 'select',
    label: 'Тип',
    name: 'type',
    options: tagTypeOptions,
  },
  {
    type: 'checkbox',
    label: 'Активный',
    name: 'enabled',
  },
];

export const validation = yupObject({
  name: yupStringRequired,
  type: yupStringOfOptionsRequired(tagTypeOptions),
  enabled: yupBooleanRequired,
});

export const initialValues: TagDto = {name: '', enabled: true, type: 'default'};
