/* eslint-disable react-hooks/exhaustive-deps */
import pick from 'lodash/pick';
import pathOr from 'lodash/fp/pathOr';
import {skipToken} from '@reduxjs/toolkit/dist/query';

import {adminApi} from 'api';
import {QuestDesign} from '@apiSchema/admin-api';

import {Form} from 'components/Form';

import {QuestDesignFormProps} from './types';
import {designFields, designInitials, designFormValidation} from './questDesignField';

export const QuestDesignForm = ({questId, onReset}: QuestDesignFormProps) => {
  const {data} = adminApi.endpoints.questIndex.useQuery(questId ? {questId} : skipToken);
  const [saveQuest] = adminApi.endpoints.questPatch.useMutation();
  const onSubmit = (design: QuestDesign) =>
    saveQuest({
      questId,
      questPartialDto: {design: {...design, backgroundFileId: design.fileId, successImageFileId: design.fileId}},
    });
  const initials = {
    ...designInitials,
    ...pick(pathOr({}, ['design'], data || {}), Object.keys(designInitials)),
    description: data?.description || '',
  };
  return (
    <Form
      onReset={onReset}
      onSubmit={onSubmit}
      fields={designFields(questId)}
      validation={designFormValidation}
      initials={initials}
    />
  );
};
