import {useSnackbar} from 'notistack';

export const SNACKBAR_TIMEOUT = 2000;

export const useSnackbarEmit = () => {
  const {enqueueSnackbar} = useSnackbar();
  return {
    emitError: (error: any, defaultMessage = 'Что-то пошло не так') =>
      enqueueSnackbar(error?.data?.message || defaultMessage, {
        variant: 'error',
        autoHideDuration: SNACKBAR_TIMEOUT,
      }),
    emitSuccess: (message = 'Все получилось') =>
      enqueueSnackbar(message, {
        variant: 'success',
        autoHideDuration: SNACKBAR_TIMEOUT,
      }),
  };
};
