import {FC} from 'react';
import {useParams} from 'react-router';
import {skipToken} from '@reduxjs/toolkit/query';
import {Outlet, useNavigate} from 'react-router-dom';

import {Grid, Stack, useTheme, useMediaQuery, Button, Card, CardContent} from '@mui/material';

import {adminApi} from 'api';
import {CommonPage} from 'components/ListPage';
import {GroupIdProp} from 'modules/groups/types';
import {GroupMembers, GroupLayoutInfo, GroupSubGroups} from 'modules/groups/containers';

export const GroupLayout: FC<GroupIdProp> = ({groupId}) => {
  const navigate = useNavigate();
  const {data} = adminApi.useGroupIndexQuery(groupId ? {groupId} : skipToken);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const {userId, postId} = useParams<'userId' | 'postId'>();
  const isShown = !isMobile || (isMobile && !(Boolean(userId) || Boolean(postId)));

  if (!data) return null;
  return (
    <CommonPage title="Группы" heading={data.name} maxWidth="xl">
      <Grid container columnSpacing={2} rowSpacing={isMobile ? 2 : 0}>
        <Grid item md={4} lg={3} xs={12}>
          <Stack spacing={2}>
            <GroupLayoutInfo groupId={groupId} />
            {isShown && (
              <>
                <GroupMembers groupId={groupId} onRowClick={({row}) => navigate(`./user/${row.userId}`)} />
                <GroupSubGroups groupId={groupId} />
              </>
            )}
            {!isShown && (
              <Card>
                <CardContent>
                  <Button onClick={() => navigate('.')} fullWidth>
                    Состав группы
                  </Button>
                </CardContent>
              </Card>
            )}
          </Stack>
        </Grid>
        <Outlet />
      </Grid>
    </CommonPage>
  );
};

export default GroupLayout;
