import {styled} from '@mui/system';

export const TextField = styled('div')(() => ({
  position: 'relative',
  '& .MuiFormControl-root': {
    height: 'auto',
  },
  '& .MuiInputBase-root': {
    padding: 0,
    minHeight: '32px',
    paddingBottom: '8px',
    height: 'auto',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#344054',
    '&:hover:not(.Mui-disabled)::before, &.Mui-disabled::before': {
      borderBottom: 'none',
    },
    '&::before, &::after': {
      borderBottom: 'none',

      boxShadow: '0px 1px 0px #F2F4F7',
      bottom: '0',
    },
  },
  '& .MuiInput-underline:after': {
    borderBottom: '2px solid',
    borderBottomColor: '#BAA0F8',
  },
  '& .MuiInput-input': {
    padding: 0,
  },
  '& .MuiInputLabel-root': {
    color: '#8D9DB9',
    fontSize: '16px',
    lineHeight: '20px',
    transform: 'translate(0, 15px) scale(1)',
    '&.Mui-focused': {
      color: '#8D9DB9',
    },
  },
  '& .MuiInputLabel-shrink': {
    fontSize: '12px',
    lineHeight: '12px',
    color: '#8D9DB9',
    transform: 'translate(0, 2px) scale(1)',
  },
  '& .MuiInputBase-multiline': {
    alignItems: 'flex-start',
  },
  '& .MuiInput-input::placeholder': {
    color: '#8D9DB9',
    fontSize: '16px',
    lineHeight: '20px',
  },
  '&[counter]': {
    marginBottom: '4px',
  },
  '&[counter] .MuiFormHelperText-root.Mui-error': {
    position: 'absolute',
    bottom: '-3px',
    transform: 'translateY(100%)',
  },
}));

export const Select = styled('div')(() => ({
  '& .MuiInputLabel-root': {
    color: '#8D9DB9',
    transform: 'translate(0, 13px)',
  },
  '& .MuiFormLabel-filled': {
    fontSize: '12px',
    lineHeight: '12px',
    color: '#8D9DB9',
    transform: 'translate(0, -1px)',
  },
  '& .MuiFormHelperText-root': {
    marginLeft: 0,
    marginRight: 0,
  },
  '& .MuiInputBase-root': {
    height: '50px',
    padding: '16px 0',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#344054',
    boxShadow: '0px 1px 0px #F2F4F7',
    '& .MuiInput-input': {
      padding: 0,
      '&:focus': {
        backgroundColor: '#fff',
      },
    },
    '&:hover:not(.Mui-disabled)::before': {
      borderBottom: 'none',
    },
    '&::before, &::after': {
      borderBottom: 'none',
    },
  },
  '& .MuiInput-underline:after': {
    borderBottom: '2px solid',
    borderBottomColor: '#BAA0F8',
  },
  '& .MuiSelect-icon': {
    top: '50%',
    right: '8px',
    transform: 'translateY(-50%)',
  },
}));
