import {useNavigate} from 'react-router-dom';
import {FC, memo, useCallback, useState} from 'react';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import {adminApi} from 'api';
import {Contents, QuestPointDto, Quests, QuestsPointOptions, Tasks} from '@apiSchema/admin-api';

import {useDialog} from 'components/Dialog';
import {DataList} from 'components/DataList';

import {QuestIdProp} from 'modules/events/types';
import {contentColumns} from 'modules/contents/containers';
import tasksColumns from 'modules/tasks/pages/TasksList/columns';
import {questColumns} from 'modules/events/pages/EventsList/columns';

import {QuestStructureContext} from './context';
import {QuestStructureBranch} from './QuestStructureBranch';

export const QuestStructure: FC<QuestIdProp> = memo(({questId: rootQuestId}) => {
  const navigate = useNavigate();
  const [addPoint] = adminApi.endpoints.questPointsAdd.useMutation();

  const [menuSettings, setMenuSettings] = useState<{anchor: HTMLElement; questId: string} | null>(null);
  const closeMenu = () => setMenuSettings(null);
  const openPointMenu = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, questId: string) => {
      setMenuSettings({anchor: event.currentTarget, questId});
    },
    [setMenuSettings]
  );

  const pointAddHandler = async (
    type: QuestPointDto['type'],
    {name, ...options}: Partial<QuestsPointOptions & {name?: string}>
  ) => {
    if (!menuSettings?.questId) return null;
    const result = await addPoint({
      questId: menuSettings.questId,
      questPointDto: {
        type,
        name,
        enabled: true,
        options: {
          ...options,
          design: {
            title: '',
            containerName: 'default',
          },
          isRequired: false,
          autoAccept: false,
        },
      },
    });
    closeMenu();
    if ('data' in result) navigate(`./${result.data._id!}`);
  };

  const addWidgetPoint = () => pointAddHandler('widget', {widgets: {}});
  const addButtonPoint = () =>
    pointAddHandler('button', {
      buttons: {
        title: '',
        confirmText: null,
        fileId: null,
        bgFileId: null,
        color: 'primary',
        variant: 'text',
        alwaysOnTop: false,
      },
    });
  const addPublicationPoint = () =>
    pointAddHandler('publication', {publications: {videoRequired: false, contentRequired: false}});

  const [QuestSelector, openQuestSelector, closeQuestSelector] = useDialog();
  const [TaskSelector, openTaskSelector, closeTaskSelector] = useDialog();
  const [ContentSelector, openContentSelector, closeContentSelector] = useDialog();

  return (
    <>
      <ContentSelector title="Добавление материала">
        <DataList
          queryName="contentsIndex"
          params={{}}
          columns={contentColumns()}
          onRowClick={({row}: {row: Contents}) => {
            pointAddHandler('content', {contents: {contentId: row._id}, name: row.name});
            closeContentSelector();
          }}
        />
      </ContentSelector>
      <QuestSelector title="Добавление квеста">
        <DataList
          queryName="questsIndex"
          params={{limit: 10000}}
          columns={questColumns()}
          onRowClick={async ({row}: {row: Quests}) => {
            pointAddHandler('quest', {quests: {questId: row._id}, name: row.design?.header || row.name});
            closeQuestSelector();
          }}
        />
      </QuestSelector>
      <TaskSelector title="Добавление задания">
        <DataList
          queryName="tasksIndex"
          params={{limit: 10000}}
          columns={tasksColumns()}
          onRowClick={async ({row}: {row: Tasks}) => {
            pointAddHandler('task', {name: row.name, tasks: {taskId: row._id}});
            closeTaskSelector();
          }}
        />
      </TaskSelector>

      <Menu
        keepMounted
        id="simple-menu"
        onClose={closeMenu}
        open={Boolean(menuSettings)}
        anchorEl={menuSettings?.anchor}
      >
        <MenuItem onClick={openQuestSelector}>Квест</MenuItem>
        <MenuItem onClick={openTaskSelector}>Задание</MenuItem>
        <MenuItem onClick={openContentSelector}>Материал</MenuItem>
        <MenuItem onClick={addPublicationPoint}>Публикация</MenuItem>
        <MenuItem onClick={addButtonPoint}>Кнопка</MenuItem>
        <MenuItem onClick={addWidgetPoint}>Виджет</MenuItem>
      </Menu>

      <QuestStructureContext.Provider value={{openPointMenu}}>
        <QuestStructureBranch questId={rootQuestId} />
      </QuestStructureContext.Provider>
    </>
  );
});
