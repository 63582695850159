import {AccessBase} from '@apiSchema/admin-api';
import {yupArrayStrings, yupBoolean, yupBooleanRequired, yupObject} from 'utils/validation';

import {usersColumns} from 'modules/users/containers/UsersList/columns';
import {streamsColumns} from 'modules/streams/containers/StreamsList/columns';
import {FieldStructure} from 'components/Form/types';
import {groupsColumns} from 'modules/groups/pages/GroupsList/columns';

export const accessFieldsValidation = yupObject({
  isActive: yupBooleanRequired,
  usersId: yupArrayStrings,
  usersNotIn: yupBoolean,
  groupsId: yupArrayStrings,
  groupsNotIn: yupBoolean,
  streamsId: yupArrayStrings,
  streamsNotIn: yupBoolean,
  rewardsId: yupArrayStrings,
  rewardsNotIn: yupBoolean,
}).required();

export const accessInitials: Record<'access', AccessBase> = {
  access: {isActive: false},
};

export const accessBaseFields: FieldStructure[] = [
  {
    name: 'access.isActive',
    type: 'checkbox',
    initial: false,
    label: 'Активировать настройки управления доступом',
  },
  {
    showIf: 'access.isActive',
    direction: 'column',
    fields: [
      {
        fields: [
          {
            name: 'access.usersId',
            type: 'dialogSelect',
            getName: item => item.name,
            label: 'Пользователи',
            queryName: 'usersIndex',
            columns: usersColumns(),
            params: {populates: ['logins']},
            multiple: true,
          },
          {
            type: 'checkbox',
            name: 'access.usersNotIn',
            label: 'Все, кроме',
          },
        ],
      },
      {
        fields: [
          {
            name: 'access.groupsId',
            type: 'dialogSelect',
            label: 'Группы',
            getName: item => item.name,
            queryName: 'groupsIndex',
            columns: groupsColumns(),
            params: {populates: ['stream']},
            multiple: true,
          },
          {
            type: 'checkbox',
            name: 'access.groupsNotIn',
            label: 'Все, кроме',
          },
        ],
      },
      {
        fields: [
          {
            name: 'access.streamsId',
            type: 'dialogSelect',
            label: 'Потоки',
            getName: item => item.name,
            queryName: 'streamsIndex',
            columns: streamsColumns(),
            multiple: true,
          },
          {
            type: 'checkbox',
            name: 'access.streamsNotIn',
            label: 'Все, кроме',
          },
        ],
      },
    ],
  },
];
