import {useCallback, useState} from 'react';

import {Dialog, DialogTitle, DialogContent} from '@mui/material';

import {adminApi} from 'api';
import {UsersLogins} from '@apiSchema/admin-api';

import {Form} from 'components/Form';
import {passFields, passInitials, passValidation} from './fields';

export const useUserLoginPasswordForm = (): [() => JSX.Element, (login: UsersLogins) => void] => {
  const [login, setLogin] = useState<UsersLogins | null>(null);
  const [setPassword] = adminApi.endpoints.userLoginPassword.useMutation();

  const LoginFormDialog = useCallback(() => {
    return (
      <Dialog open={Boolean(login?.userId && login?._id)} onClose={() => setLogin(null)}>
        <DialogTitle>Назначение пароля</DialogTitle>
        <DialogContent>
          <Form
            fields={passFields}
            validation={passValidation}
            initials={passInitials}
            onReset={() => setLogin(null)}
            onSubmit={async body => {
              if (!login?.userId || !login._id) return null;
              const result = await setPassword({userId: login.userId, loginId: login._id, body});
              setLogin(null);
              return result;
            }}
          />
        </DialogContent>
      </Dialog>
    );
  }, [login?.userId, login?._id, setLogin, setPassword]);

  return [LoginFormDialog, setLogin];
};
