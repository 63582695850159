import {FC, useContext} from 'react';
import {skipToken} from '@reduxjs/toolkit/query';
import {Box, CircularProgress} from '@mui/material';

import {adminApi} from 'api';
import {UsersAPIList} from 'components/DataList';
import {APIItemRender} from 'components/DataList/types';

import {CommentIdProp} from './types';
import {CommentsContext, useEditCommentId} from './utils';

import {Comment} from './Comment';
import {CommentReply} from './CommentReply';

const renderItem: APIItemRender = ({itemId}) => (itemId ? <CommentsBranch commentId={itemId} /> : null);

const Subscribe: FC<CommentIdProp> = ({commentId}) => {
  adminApi.endpoints.commentIndex.useQuerySubscription({commentId});
  return null;
};

export const CommentsBranch: FC<CommentIdProp> = ({commentId}) => {
  const [editCommentId] = useEditCommentId();
  const {reference} = useContext(CommentsContext);
  const {data, isLoading} = adminApi.endpoints.commentIndex.useQueryState(commentId ? {commentId} : skipToken);

  if (!commentId) return null;
  if (!isLoading && !data) return <CircularProgress />;
  return (
    <Box>
      <Subscribe commentId={commentId} />
      <Comment commentId={commentId} />
      {((reference && Boolean(data?.comment.branchCommentsCount)) || editCommentId === commentId) && (
        <Box pl={3} mt={2}>
          {editCommentId === commentId && (
            <Box mb={2}>
              <CommentReply commentId={commentId} />
            </Box>
          )}
          {reference && Boolean(data?.comment.branchCommentsCount) && (
            <UsersAPIList
              spacing={2}
              api="adminApi"
              endpoint="commentsIndex"
              params={{...reference, sort: 'created_asc', branchCommentId: commentId}}
              renderItem={renderItem}
            />
          )}
        </Box>
      )}
    </Box>
  );
};
