import {pick} from 'lodash';
import {useNavigate} from 'react-router';
import {useCallback, useEffect} from 'react';

import {adminApi} from 'api';
import {QuestDto, Quests} from '@apiSchema/admin-api';

import {initialValues} from './fields';
import {QuestFormProps} from './types';

export const useCreateQuest = (data?: Quests, parentQuestId?: string, onSuccess?: QuestFormProps['onSuccess']) => {
  const navigate = useNavigate();
  const [update] = adminApi.endpoints.questUpdate.useMutation();
  const [create, {data: result}] = adminApi.endpoints.questsAdd.useMutation();

  const onSubmit = useCallback(
    (inputData: QuestDto) => {
      const dto = inputData;
      if (parentQuestId) dto.parentQuestId = parentQuestId;
      if (data && data._id) return update({questId: `${data._id}`, questDto: {...dto, design: data.design}});
      else return create({questDto: dto});
    },
    [data, update, create, parentQuestId]
  );

  useEffect(() => {
    if (!data?._id && result) {
      if (onSuccess) onSuccess(result);
      else navigate(`../${result._id}`);
    }
  }, [result, data?._id, navigate, onSuccess]);

  const initials = data ? (pick(data, Object.keys(initialValues)) as QuestDto) : initialValues;

  return {initials, onSubmit};
};
