import {FC} from 'react';
import {isArray} from 'lodash';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import {adminApi, usersApi} from 'api';
import {Contracts} from '@apiSchema/users-api';

import {UserIdProp} from 'modules/users';
import {useDialog} from 'components/Dialog';

const contractFields: Record<
  keyof Omit<Contracts, 'user' | 'updatedAt' | 'createdAt' | 'userId' | 'deletedAt' | '_id'>,
  {title: string; description?: string}
> = {
  fullname: {title: 'ФИО'},
  positiveTraits: {title: 'В прошлом проявлял(-а) себя как,', description: '*3 положительных качества'},
  positiveEffects: {title: 'И это создало в моей жизни следующие последствия'},
  negativeTraits: {title: 'Также я проявлял(-а) себя как', description: '*3 отрицательных качества'},
  negativeEffects: {title: 'И это создало в моей жизни следующие последствия'},
  futureTraits: {title: 'И это создало в моей жизни следующие последствия'},
  futureEffects: {title: 'И это приведет меня к следующим положительным последствиям'},
};

export const UserContract: FC<UserIdProp> = ({userId}) => {
  const [Dialog, openDialog] = useDialog();
  const {data} = usersApi.endpoints.contractsIndex.useQuery({userId});
  const [remove] = adminApi.endpoints.contractDelete.useMutation();
  const [restore] = adminApi.endpoints.contractRestore.useMutation();
  if (!data) return null;
  return (
    <>
      <Dialog
        title="Контракт"
        action={
          <Button
            onClick={data.deletedAt ? () => restore({contractId: data._id!}) : () => remove({contractId: data._id!})}
          >
            {data.deletedAt ? 'Восстановить' : 'Удалить'}
          </Button>
        }
      >
        <Stack spacing={3}>
          {Object.keys(contractFields).map(field => (
            <ContractBlock field={field as keyof typeof contractFields} userId={userId} />
          ))}
        </Stack>
      </Dialog>
      <Button fullWidth onClick={openDialog} variant="outlined">
        Контракт пользователя
      </Button>
    </>
  );
};

const ContractBlock: FC<{field: keyof typeof contractFields} & UserIdProp> = ({field, userId}) => {
  const {data} = usersApi.endpoints.contractsIndex.useQuery(
    {userId},
    {
      selectFromResult: ({data, isLoading}) => {
        const value = data?.[field];
        return {
          isLoading,
          data: value ? (isArray(value) ? value : [value]) : undefined,
        };
      },
    }
  );

  if (!data) return null;

  return (
    <Stack spacing={1}>
      <Box>
        <Typography variant="body1">{contractFields[field].title}</Typography>
        {!!contractFields[field].description && (
          <Typography variant="body2" color="grey.600">
            {contractFields[field].description}
          </Typography>
        )}
      </Box>
      {data.length === 1 ? (
        <Typography variant="body2">{data[0]}</Typography>
      ) : (
        <Box component="ul" pl={2}>
          {data.map((item, index) => (
            <li key={index}>
              <Typography variant="body2">{item}</Typography>
            </li>
          ))}
        </Box>
      )}
    </Stack>
  );
};
