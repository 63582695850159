import {lazy} from 'react';
import {Navigate, RouteObject} from 'react-router';

import {PATH_APP} from 'router/routes';

import {AccessGuard} from 'modules/auth/containers';
import {BreadcrumbsSet} from 'components/Breadcrumbs/BreadcrumbsSet';
import IsExistsContainer from 'components/IsExistsContainer';

import {useGetQuest} from './pages/QuestLayout/hook';
import {useGetQuestPoint} from './pages/QuestPointPage/hook';
import {Quests} from '@apiSchema/admin-api';

const QuestLayout = lazy(() => import('./pages/QuestLayout'));
const QuestFormPage = lazy(() => import('./pages/QuestFormPage'));
const QuestDesignPage = lazy(() => import('./pages/QuestDesignPage'));
const QuestPointPage = lazy(() => import('./pages/QuestPointPage'));
const QuestsTimelinePage = lazy(() => import('./pages/QuestsTimelinePage'));
const QuestStructurePage = lazy(() => import('./pages/QuestStructurePage'));
const QuestListPage = lazy(() => import('./pages/EventsList'));
const QuestStatsPage = lazy(() => import('modules/events/pages/QuestStatsPage'));

export const questsRouter: RouteObject = {
  path: 'quests',
  element: (
    <AccessGuard endpoint="questsIndex">
      <BreadcrumbsSet name="Квесты" href={PATH_APP.tasks.quests.root} />
    </AccessGuard>
  ),
  children: [
    {index: true, element: <Navigate to="./list" replace />},
    {
      path: 'list',
      children: [
        {
          index: true,
          element: <QuestListPage />,
        },
        {
          element: <QuestLayout />,
          children: [
            {
              path: 'create',
              element: (
                <BreadcrumbsSet name="Новый квест">
                  <QuestFormPage />
                </BreadcrumbsSet>
              ),
            },
            {
              path: ':questId',
              element: (
                <AccessGuard endpoint="questIndex">
                  <IsExistsContainer<Quests> useFetch={useGetQuest}>
                    {quest => <BreadcrumbsSet name={quest.name} />}
                  </IsExistsContainer>
                </AccessGuard>
              ),
              children: [
                {
                  index: true,
                  element: <Navigate to="./general" replace />,
                },
                {
                  path: 'general',
                  element: <QuestFormPage />,
                },
                {
                  path: 'design',
                  element: <QuestDesignPage />,
                },
                {
                  path: 'timeline',
                  element: <AccessGuard endpoint="questTimelinesIndex" />,
                  children: [{index: true, element: <QuestsTimelinePage />}],
                },
                {
                  path: 'structure',
                  element: <AccessGuard endpoint="questPointsIndex" />,
                  children: [
                    {
                      element: <QuestStructurePage />,
                      children: [
                        {
                          index: true,
                          element: <div />,
                        },
                        {
                          path: ':pointId',
                          element: (
                            <IsExistsContainer useFetch={useGetQuestPoint}>
                              <QuestPointPage />
                            </IsExistsContainer>
                          ),
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'stats',
                  element: <AccessGuard endpoint="questsStatsDynamic" />,
                  children: [{index: true, element: <QuestStatsPage />}],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export default questsRouter;
