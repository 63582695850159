import {FC} from 'react';

import {Icon} from '@iconify/react';
import openInNew from '@iconify/icons-material-symbols/open-in-new';

import {
  CardContent,
  Card,
  CardHeader,
  Table,
  IconButton,
  TableBody,
  TableRow,
  TableCell,
  Alert,
  Button,
} from '@mui/material';

import {adminApi, usersApi} from 'api';
import {getGroupPath} from 'modules/groups/utils';

import {UserIdProp} from 'modules/users/types';
import {useDialogSelector} from 'components/DialogSelector';
import {groupsColumns} from 'modules/groups/pages/GroupsList/columns';

export const UserGroups: FC<UserIdProp> = ({userId}) => {
  const {groups} = usersApi.endpoints.userIndex.useQuery(
    {userId},
    {selectFromResult: ({data}) => ({groups: data?.account.stats?.groups || []})}
  );
  const [GroupSelector, openGroupSelector] = useDialogSelector();
  const [addToGroup] = adminApi.endpoints.groupAddUsers.useMutation();
  const onConfirm = ([groupId]: string[]) => {
    if (!groupId) return;
    addToGroup({groupId, body: {usersId: [userId]}});
  };

  return (
    <>
      <GroupSelector
        single
        hideToolbar
        onConfirm={onConfirm}
        queryName="groupsIndex"
        params={{limit: 10000}}
        title="Добавить в группу"
        columns={groupsColumns()}
      />
      <Card>
        <CardHeader title="Группы" action={<Button onClick={openGroupSelector}>Добавить</Button>} />
        <CardContent sx={{px: 0}}>
          {!groups.length ? (
            <Alert severity="info" sx={{mx: 2}}>
              Пользователь не состоит ни в одной группе
            </Alert>
          ) : (
            <Table size="small">
              <TableBody>
                {groups.map(group => (
                  <TableRow key={group._id!}>
                    <TableCell>{group.name}</TableCell>
                    <TableCell align="right">
                      <IconButton size="small" href={getGroupPath(group._id!)} target="_blank">
                        <Icon icon={openInNew} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </CardContent>
      </Card>
    </>
  );
};
